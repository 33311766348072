import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navigation from "./Nav/Navigation";
import Home from "./Home/Home";
import About from "./About/About";
import CreateC from "./CreateC/CreateC";
import Record from "./Record/Record";
import PrivacyNotice from "./FooterLegal/PrivacyNotice/PrivacyNotice";
import Footer from "./Footer/Footer";

import "./App.css";
import Constitution from "./FooterLegal/Constitution/Constitution";
import TermsOfService from "./FooterLegal/TermsofService/TermsofService";
import ScrollToTop from "./Router/ScrollToTop";
import Dispatch from "./About/Dispatch/Dispatch";
import Profile from "./About/Profile/Profile";
import Calculator from "./Calculator/Calculator";
import DailyNews from "./DailyNews/DailyNews";
import Love from "./Love/Love";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
// import client from './apollo-client'

const client = new ApolloClient({
  uri: "https://api.staging.joincolumn.com/graphql",
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <ScrollToTop />

        <div className="App">
          {/* <Navigation /> */}

          <Switch>
            <Route exact path="/">
              <Home />
            </Route>

            <Route path="/about">
              <About />
            </Route>

            <Route path="/createC">
              <CreateC />
            </Route>

            <Route path="/calculator">
              <Calculator />
            </Route>

            <Route path="/news">
              <DailyNews />
            </Route>

            <Route path="/record">
              <Record />
            </Route>

            <Route path="/love">
              <Love />
            </Route>

            <Route path="/privacy-notice">
              <PrivacyNotice />
            </Route>

            <Route path="/constitution">
              <Constitution />
            </Route>

            <Route path="/terms-of-service">
              <TermsOfService />
            </Route>

            <Route path="/dispatch/:id">
              <Dispatch />
            </Route>

            <Route path="/profile/:id">
              <Profile />
            </Route>
          </Switch>
        </div>
        <Footer />
      </Router>
    </ApolloProvider>
  );
}

export default App;
