import "./Join.css";
import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

import ios_mockup from "../../images/phones_home/ios.svg";
import android_mockup from "../../images/phones_home/android.svg";
import joined_phone from "../../images/phones_home/joined_phone.png";
function Join() {
  useEffect(() => {
    Aos.init({ duration: 700, once: "true" });
    checkDevice();
  }, []);

  const [isIos, setIsIos] = useState(true);

  function checkDevice() {
    //var is_OSX = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    var is_iOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);

    var is_Mac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    var is_iPhone = navigator.platform === "iPhone";
    var is_iPod = navigator.platform === "iPod";
    var is_iPad = navigator.platform === "iPad";

    if (is_Mac || is_iOS || is_iPhone || is_iPod || is_iPad) {
      setIsIos(true);
    } else {
      setIsIos(false);
    }
  }

  return (
    <div className="join join__div__arched" id="join">
      <div className="join__wrapper__arched">
        <div className="join__content">
          <div className="join__content_phone">
            <div
              data-aos="fade-up"
              data-aos-delay="200"
              className="join__content_phone__holder"
            >
              <img
                className="join__ios__mockup"
                src={isIos ? ios_mockup : android_mockup}
                alt=""
              />
              <img className="joined_phone" src={joined_phone} alt="" />
            </div>
          </div>

          <div className="join__content_text">
            <h2 data-aos="fade-up" data-aos-delay="200">
              This is a place to dive deep.
            </h2>

            <p data-aos="fade-up" data-aos-delay="200">
              On Column, you can follow the <strong>Topics</strong> you care
              about. Your feed won't be full of clickbait and surface level
              content. <strong>You control your feed.</strong> It's truly yours
              — infinitely customizable.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Join;
