import './TermsofService.css';
import React from 'react';
import { Link } from 'react-router-dom';
import GoBack from '../GoBack/GoBack';

function TermsOfService() {
    return(
        <div className="footerLegal-container__terms">

            {
            window.innerWidth > 1200 && <GoBack />
            }

            <div className="wrapper__terms">

                <div className="footerLegal__headline__terms">
                    <p className="footerLegal__title__terms">Terms of Service</p>
                    <p className="footerLegal__effective-date__terms">Effective date: May 25, 2021</p>
                </div>

                <div className="footerLegal__content__terms">

                    <p className="footerLegal__paragraph__terms">
                        Welcome to joincolumn.com, the website, online and mobile service of Column Inc. (“Column,” “we,” or “us”). 
                        This page explains the terms by which you may use our online and mobile services, together with the content, 
                        software, products and functionality offered on or in connection with the service (collectively, the “Services”). 
                        By accessing or using the Services, or by clicking a button or checking a box marked “I Agree” (or something 
                        similar), you signify that you have read, understood, and agree to be bound by these Terms of Service 
                        (this “Agreement”), our Column Community Guidelines available at 
                       <Link to="/constitution">(“Community Guidelines”)</Link>, and to the collection and use of your information as set forth in our 
                        Privacy Notice available at (“Privacy Notice”), whether or not you are a registered user of the Services. 
                        Column reserves the right to modify these terms and will provide notice of these changes as described below. 
                        This Agreement applies to all visitors, users, and others who access the Services (“Users”).
                    </p>

                    <p className="footerLegal__paragraph__terms">
                         Please read this Agreement carefully to ensure that you understand each provision.
                         This agreement contains a mandatory individual arbitration and class action/jury trial 
                         waiver provision that requires the use of arbitration on an individual basis to resolve disputes, 
                         rather than jury trials or class actions.
                    </p>

                    <p className="sections__title__terms">1. Our Services</p>

                    <p className="footerLegal__subtitle_terms">1.1 Eligibility</p>

                    <p className="footerLegal__paragraph__terms">
                         This is a contract between you and Column. You must read and agree to these terms before using 
                         the Column Services. If you do not agree, you may not use the Services. You may use the Services 
                         only if you can form a binding contract with Column, and only in compliance with this Agreement 
                         and all applicable local, state, national, and international laws, rules and regulations. 
                         Any use of or access to the Services by anyone under 13 is strictly prohibited and in violation 
                         of this Agreement. If you are under 18 years of age, you may use the Services only with involvement a 
                         parent or legal guardian and their consent and agreement to this Agreement. If you are under 18, 
                         you represent that you have your parent or guardian’s permission to use the Services and that your 
                         parent or guardian is agreeing to this Agreement. If you are a parent or legal guardian of a User under 
                         the age of 18, by allowing your child to use the Services, you are subject to the terms of this Agreement 
                         and responsible for your child’s activity on the Services. The Services are not available to any Users 
                         previously removed from the Services by Column.
                    </p>

                    <p className="footerLegal__subtitle_terms">1.2 Limited License</p>

                    <p className="footerLegal__paragraph__terms">
                        Subject to the terms and conditions of this Agreement, you are hereby granted a non-exclusive, 
                        limited, non-transferable, freely revocable license to use the Services for your personal, 
                        noncommercial use only and as permitted by the features of the Services. Column reserves all 
                        rights not expressly granted herein in the Services and the Column Content (as defined below). 
                        Column may terminate this license at any time for any reason or no reason.
                        Please review the Column Community Guidelines, which are part of this Agreement. 
                        You may use the Services only in compliance with this Agreement and all applicable laws, 
                        rules and regulations.
                    </p>

                    <p className="footerLegal__subtitle_terms">1.3 Restrictions</p>

                    <p className="footerLegal__paragraph__terms">
                        You will not, and you will not assist, permit or enable others to, do any of the following:
                    </p>


                    <ol className="footerLegal__orderedList">

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                use the Services for any purpose other than as expressly set forth in Section 1.2 above;
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                disassemble, reverse engineer, decode or decompile any part of the Services;
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                use any robot, spider, scraper, data mining tool, data gathering or extraction tool, 
                                or any other automated means, to access, collect, copy or record the Services or any 
                                other User’s User Content;
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                copy, rent, lease, sell, transfer, assign, sublicense, modify, alter, or create derivative works 
                                of any part of the Services or any of our intellectual property;
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                remove any copyright notices or proprietary legends from the Services; 
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                use the Services in a manner that impacts: (i) the stability of our servers; (ii) the operation or 
                                performance of the Services or any other User’s use of the Services; or (iii) the behavior of other 
                                applications using the Services; 
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                use the Services in any manner or for any purpose that violates any applicable law, regulation, 
                                legal requirement or obligation, contractual obligation, or any right of any person including, 
                                but not limited to, intellectual property rights, rights of privacy and/or rights of personality, 
                                or which otherwise may be harmful (in our sole discretion) to us, our providers, our suppliers or
                                 Users;
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                use the Services in competition with us, to develop competing products or services, 
                                or otherwise to our detriment or commercial disadvantage;
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                use the Services for benchmarking or competitive analysis of the Services; 
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                attempt to interfere with, compromise the system integrity or security of, or decipher any 
                                transmissions to or from, the servers running the Services; 
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                transmit viruses, worms, or other software agents through the Services; 
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                 impersonate another person or misrepresent your affiliation with a person or entity, 
                                 hide or attempt to hide your identity, or otherwise use our Service for any invasive or 
                                 fraudulent purpose;  
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                 share passwords or authentication credentials for the Services; 
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                bypass the measures we may use to prevent or restrict access to the Services or enforce 
                                limitations on use of the Services or the content therein, including without limitation 
                                features that prevent or restrict use or copying of any content;
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                identify us or display any portion of the Services on any site or service that 
                                disparages us or our products or services, or infringes any of our intellectual 
                                property or other rights; 
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                 dentify or refer to us or the Services in a manner that could reasonably imply an endorsement, 
                                 relationship or affiliation with or sponsorship between you or a third party and us, other than your 
                                 permitted use of the Services under this Agreement, without our express written consent; or
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                take screen shots of the Services or any other User’s User Content except solely for your personal, 
                                noncommercial use, or post any screenshot of the Services or any other User’s User Content on the 
                                Services, on the Services or in any third-party products, services or applications, or otherwise 
                                in public.
                            </p>
                        </li>

                    </ol>    

                    <p className="footerLegal__subtitle_terms">1.4 User Accounts</p>

                    <p className="footerLegal__paragraph__terms">
                        Your account on the Services (your “User Account”) gives you access to the services and 
                        functionality that we may establish and maintain from time to time and in our sole discretion. 
                        We may maintain different types of User Accounts for different types of Users. If you open a User 
                        Account on behalf of a company, organization, or other entity, then (i) “you” includes you and that
                            entity, and (ii) you represent and warrant that you are an authorized representative of the entity 
                            with the authority to bind the entity to this Agreement, and that you agree to this Agreement on the 
                            entity’s behalf. By connecting to Column with a third-party service, you give us permission to access 
                            and use your information from that service as permitted by that service, and to store your log-in 
                            credentials for that service.
                    </p>

                    <p className="footerLegal__paragraph__terms">
                        You may never use another User’s User Account without permission. When creating your User Account, 
                        you must provide accurate and complete information, and you must keep this information up to date. 
                        You may use a pseudonym on the Services; however you must provide Column with your true name as part 
                        of your onboarding for your User Account. You are solely responsible for the activity that occurs on 
                        your User Account, and you must keep your User Account password secure. We encourage you to use “strong” 
                        passwords (passwords that use a combination of upper and lower case letters, numbers and symbols) with 
                        your User Account. You must notify Column immediately of any breach of security or unauthorized use of 
                        your User Account. Column will not be liable for any losses caused by any unauthorized use of your User 
                        Account.
                    </p>

                    <p className="footerLegal__paragraph__terms">
                        You may control your User profile and how you interact with the Services by changing the 
                        settings in your <Link>settings page</Link>. By providing Column your email address you consent to our 
                        using the email address to send you Services-related notices, including any notices required 
                        by law, in lieu of communication by postal mail. We may also use your email address to send 
                        you other messages, such as changes to features of the Services and special offers. If you do 
                        not want to receive such email messages, you may opt out or change your preferences in your 
                        <Link>settings page</Link>. Opting out may prevent you from receiving email messages regarding updates, 
                        improvements, or offers.
                    </p>

                    <p className="footerLegal__subtitle_terms">1.5 Changes to the Service</p>

                    <p className="footerLegal__paragraph__terms">
                            We may, without prior notice, change the Services; stop providing the Services or 
                            features of the Services, to you or to Users generally; or create usage limits for 
                            the Services. We may permanently or temporarily terminate or suspend your access to t
                            he Services without notice and liability for any reason, including if in our sole determination 
                            you violate any provision of this Agreement, or for no reason. Upon termination for any reason or 
                            no reason, you continue to be bound by this Agreement.
                    </p>

                    <p className="footerLegal__subtitle_terms">1.6 Disputes with Other Users</p>

                    <p className="footerLegal__paragraph__terms">
                        You are solely responsible for your interactions with other Users. 
                        We reserve the right, but have no obligation, to monitor disputes between you and other Users. 
                        Column shall have no liability for your interactions with other Users, or for any User’s action or 
                        inaction.
                    </p>

                    <p className="footerLegal__subtitle_terms">1.7 Service Location</p>

                    <p className="footerLegal__paragraph__terms">
                        The Services are controlled and operated from facilities in the United States.
                            Column makes no representations that the Services are appropriate or available 
                            for use in other locations. Those who access or use the Services from other 
                            jurisdictions do so at their own volition and are entirely responsible for compliance with all 
                            applicable United States and local laws and regulations, including but not limited to export and 
                            import regulations. You may not use the Services if you are a resident of a country embargoed by 
                            the United States, or are a foreign person or entity blocked or denied by the United States government. 
                            Unless otherwise explicitly stated, all materials found on the Services are solely directed to individuals, 
                            companies, or other entities located in the United States.
                    </p>

                    <p className="sections__title__terms">2. User Account</p>

                    <p className="footerLegal__paragraph__terms">Some areas of the Services allow Users to submit, post, display, provide, or 
                        otherwise make available content such as profile information, videos, images, music, comments, 
                        questions, and other content or information (any such materials a User submits, posts, displays, 
                        provides, or otherwise makes available on the Services is referred to as “User Content”).
                    </p>

                    <p className="footerLegal__paragraph__terms">As between you and Column, you retain any and all rights, 
                        title and interest in and to any and all User Content you create, post or otherwise make available 
                        on or through the Services. We claim no ownership rights over your User Content. The User Content 
                        you create remains yours. However, you understand that certain portions of the Services may allow other 
                        Users to view, edit, share, and/or otherwise interact with your User Content. By providing or sharing User 
                        Content through the Services, you agree to allow others to view, edit, share, and/or interact with your User 
                        Content in accordance with your settings and this Agreement. Column has the right (but not the obligation) 
                        in its sole discretion to remove any User Content that is shared via the Services. You may choose to make 
                        certain of your User Content publicly available on the Services to all Users. You may also choose to make 
                        certain of your User Content available to only certain Users. You hereby grant to each User a non-exclusive 
                        license to access and use your User Content on the Services that you make available to such User through 
                        the Services. 
                    </p>

                    <p className="footerLegal__paragraph__terms">In accordance with your choice of the privacy settings offered by t
                        he Services, by posting or otherwise making available any User Content on or through the Services, 
                        you hereby grant, and you represent and warrant that you have all rights necessary to grant, to 
                        Column a limited, royalty-free, sublicensable, transferable, perpetual, irrevocable, non-exclusive, 
                        worldwide license to use, reproduce (for example, by, creating copies of your content for other Users 
                        you choose to provide access to your User Content), modify, publish, list information regarding, translate, 
                        distribute, syndicate, publicly perform and publicly display (for example, by making publicly available your 
                        User Content you choose to provide to the public areas of the Services), make derivative works of (for example, 
                        by creating previews of the User Content you provide within the Services), or otherwise use your User Content, 
                        including (without limitation) your name, voice, and/or likeness as it is contained within your User Content, 
                        in whole or in part, and in any form, media or technology, whether now known or hereafter developed. This license 
                        is being used by Column solely as necessary to provide the Services, and for such other limited uses identified in 
                        our Privacy Notice. This license is not being used by Column to sell your User Content to third parties or otherwise 
                        profit off of your User Content in any way unrelated to Column providing and promoting the Services, and it does not 
                        give Column any rights to own your User Content or limit your ability to use your User Content however you wish 
                        outside of the Services. 
                    </p>

                    <p className="footerLegal__paragraph__terms">For the purposes of this Agreement, “Intellectual Property Rights” means all 
                        patent rights, copyright rights, mask work rights, moral rights, rights of publicity, trademark, 
                        trade dress and service mark rights, goodwill, trade secret rights and other intellectual property 
                        rights as may now exist or hereafter come into existence, and all applications therefore and registrations, 
                        renewals and extensions thereof, under the laws of any state, country, territory or other jurisdiction.
                    </p>

                    <p className="footerLegal__paragraph__terms">In connection with your User Content, you affirm, 
                        represent and warrant the following:
                    </p>

                    <ul className="footerLegal__list">

                        <li>
                            <p className="footerLegal__paragraph__terms">You have the written consent of each and every identifiable 
                                natural person in the User Content, if any, to use such person’s name or likeness in the manner 
                                contemplated by the Services and this Agreement, and each such person has released you from any l
                                iability that may arise in relation to such use.
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">You have obtained and are solely responsible for 
                                obtaining all consents as may be required by law to post any User Content relating to 
                                third parties.
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">Your User Content and Column’s use thereof as contemplated by 
                                this Agreement and the Services will not violate any law or infringe any rights of any third party, 
                                including but not limited to any Intellectual Property Rights and privacy rights.
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">Column may exercise the rights to your User Content granted 
                                under this Agreement without liability for payment of any guild fees, residuals, payments, fees, 
                                or royalties payable under any collective bargaining agreement or otherwise.
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">To the best of your knowledge, 
                                all your User Content and other information that you provide to us is 
                                truthful and accurate.
                            </p>
                        </li>

                    </ul>

                    <p className="footerLegal__paragraph__terms">Column takes no responsibility and assumes no liability for 
                        any User Content that you or any other User or third-party posts, sends, or otherwise makes available 
                        over the Services. You shall be solely responsible for your User Content and the consequences of posting, 
                        publishing it, sharing it, or otherwise making it available on the Services, and you agree that we are only 
                        acting as a passive conduit for your online distribution and publication of your User Content. 
                        You understand and agree that you may be exposed to User Content that is inaccurate, objectionable, 
                        inappropriate for minors, or otherwise unsuited to your purpose, and you agree that Column shall not be 
                        liable for any damages you allege to incur as a result of or relating to any User Content.
                    </p>    


                    <p className="sections__title__terms">3. Our Proprietary Rights</p>

                    <p className="footerLegal__paragraph__terms">Except for your User Content, the Services and all materials 
                        therein or transferred thereby, including, without limitation, software, images, text, graphics, illustrations, 
                        logos, patents, trademarks, service marks, copyrights, photographs, audio, videos, music, and User Content belonging 
                        to other Users (the “Column Content”), and all Intellectual Property Rights related thereto, are the exclusive property 
                        of Column and its licensors (including other Users who post User Content to the Services). Except as explicitly provided 
                        herein, nothing in this Agreement shall be deemed to create a license in or under any such Intellectual Property Rights, 
                        and you agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly display, publicly 
                        perform, publish, adapt, edit or create derivative works from any Column Content. Use of the Column Content for any 
                        purpose not expressly permitted by this Agreement is strictly prohibited.
                        You may choose to or we may invite you to submit comments or ideas about the Services, including without 
                        limitation about how to improve the Services or our products (“Ideas”). By submitting any Idea, you agree 
                        that your disclosure is gratuitous, unsolicited and without restriction and will not place Column under any 
                        fiduciary or other obligation, and that we are free to use the Idea without any additional compensation to you, 
                        and/or to disclose the Idea on a non-confidential basis or otherwise to anyone. You further acknowledge that, by 
                        acceptance of your submission, Column does not waive any rights to use similar or related ideas previously known to 
                        Column, or developed by its employees, or obtained from sources other than you.
                    </p>

                    <p className="sections__title__terms">4. Subscription Plans and Payment Terms</p>

                    <p className="footerLegal__subtitle_terms">4.1 Subscription Plans</p>

                    <p className="footerLegal__paragraph__terms">We may offer plans that you can sign up for that allow 
                        you to use certain aspects of the Services, either for free or for a fee (a “Subscription Plan”). 
                        We may change Subscription Plans by offering new services for additional fees and charges and adding or 
                        amending fees and charges for existing Subscription Plans in our sole discretion. Any change to a 
                        Subscription Plan’s pricing or payment terms will become effective in the billing cycle following 
                        notice of such change to you as provided in this Agreement. Subscription Plans may set allotments 
                        for use of designated Service aspects. Use of Service aspects in excess of a Subscription Plan’s 
                        designated allotment may result in (additional) fees, as specified in the plan, and such fees will be 
                        included in a true-up invoice or charged automatically via the payment method associated with your User 
                        Account (“Payment Method”).
                    </p>

                    <p className="footerLegal__subtitle_terms">4.2 Billing and Payment</p>

                    <p className="footerLegal__paragraph__terms">For any paid Subscription Plan, you agree to make payments, 
                        and we may automatically charge your Payment Method, as described below, for so long as your User 
                        Account remains active. Subscription Plans may be offered for a set subscription period (each such period, a 
                        “Subscription Term”) or for set Services (e.g., access to one or more particular User groups on the Services 
                        (“columns”)). If you elect to use a paid Subscription Plan, you agree to the pricing and payment terms specified 
                        at checkout, or as otherwise posted or communicated to you, as we may update them from time to time. You must 
                        provide the Services with a current, valid, accepted Payment Method. When you initiate a purchase transaction, 
                        you authorize us to provide your payment information to third parties so we can complete your transaction and to 
                        charge your Payment Method, in United States dollars, for the type of transaction you have selected (plus any 
                        applicable taxes and other charges) and any applicable recurring charges as described below. You will pay 
                        applicable taxes, if any, relating to any such transaction and are also responsible for any payment-related 
                        fees such as wire transfer fees, credit card processing fees, and foreign transaction fees. We currently 
                        use Stripe as our third-party service provider for payment services, and by using the Services you agree 
                        to be bound by Stripe’s Services Agreement, available at https://stripe.com/us/legal. If we change our 
                        third-party payment processing service provider, we will notify you in writing, and at such time you 
                        agree to be bound by such third-party’s terms and conditions notified to you in writing. If your 
                        payment is not successfully settled for any reason, you remain responsible for any amounts not remitted to us. 
                        All payments for transactions are non-refundable and non-transferable except as expressly provided in this 
                        Agreement.
                    </p>

                    <p className="footerLegal__subtitle_terms">4.3 Subscription Renewals</p>

                    <p className="footerLegal__paragraph__terms">Your subscription continues until cancelled by 
                        you or we terminate your access to or use of the Services in accordance with this 
                        Agreement. All Subscription Plans will automatically renew until cancelled by you 
                        for renewal terms equal in length to the original Subscription Term. If you do not 
                        want a Subscription Plan to renew, you must cancel it at least thirty (30) days 
                        before the end of the Subscription Term by providing written notice to us of such 
                        cancellation (including by email to <Link>hello@joincolumn.com</Link> or via your account settings 
                        page on your User Account). If you purchase a Subscription Plan, we (or our third-party p
                        ayment processor) will automatically charge you at the beginning of each Subscription 
                        Term and each renewal thereafter, using the payment information you have provided, until 
                        you cancel your subscription. By agreeing to this Agreement and electing to purchase a 
                        Subscription Plan, you acknowledge that your Subscription Plan has recurring payment 
                        features and you accept responsibility for all recurring payment obligations prior to 
                        cancellation of your subscription by you or us.
                    </p>

                    <p className="footerLegal__subtitle_terms">4.4 Cancellations</p>

                    <p className="footerLegal__paragraph__terms">If we terminate your Subscription Plan, except in the event of 
                        your breach or failure to pay fees when due, we will grant you a prorated refund for the remaining 
                        unused portion of your Subscription Term. You are not entitled to a refund for any Subscription Plan 
                        that you cancel. If you cancel or terminate your Subscription Plan, your right to use the Services will 
                        continue until the end of your then-current Subscription Term and then terminate without further charges.
                    </p>

                    <p className="footerLegal__subtitle_terms">4.5 Late Payments</p>

                    <p className="footerLegal__paragraph__terms">Any late payments shall be subject to a service charge equal 
                        to 1.5% per month of the amount due or the maximum amount allowed by law, whichever is less (plus 
                        the costs of collection).  
                    </p>

                    <p className="sections__title__terms">5. Virtual Points</p>

                    <p className="footerLegal__paragraph__terms">The Services include virtual points that you may 
                        purchase from Column as set forth on the [Virtual Points Page] available at [URL] (“Virtual Points”). 
                        Virtual Points may be required to use certain aspects of the Services. Virtual Points may never be 
                        redeemed for actual monetary instruments, other goods or other items of monetary value from Column 
                        or any third party. Other than a limited, personal, revocable, non-transferable, non-sublicensable 
                        license to use the Virtual Points in the Services only, you have no right or title in or to any 
                        Virtual Points appearing or originating in the Services, or any other attributes associated with use of 
                        the Services or stored within the Services. Column may at any time regulate, control, modify and/or 
                        eliminate the Virtual Points as it sees fit in its sole discretion.
                    </p>

                    <p className="sections__title__terms">6. Heads of Columns</p>

                    <p className="footerLegal__paragraph__terms">Column may permit certain Users of the Services to moderate, 
                        maintain and oversee the activity of a specific column on the Services (each, a “Head”). Heads must 
                        comply at all times with the [Heads of Column Terms and Conditions] available at [insert link] 
                        (“Heads Terms”). Heads may report or suspend Users, delete User Content on the Services, or 
                        restrict Users from accessing the Head’s column, or remove Users from the Head’s column, for 
                        those Users who violate the Column Community Guidelines. Column is not responsible for any 
                        actions taken by a Head. Column reserves the right, but has no obligation, to overturn any 
                        action or decision of a Head if Column, in its sole discretion, believes that such action or 
                        decision is not in the interest of Column or the Column community.
                    </p>

                    <p className="sections__title__terms">7. Privacy</p>

                    <p className="footerLegal__paragraph__terms">We care about the privacy of our Users. 
                        You understand that by using the Services you consent to the collection, use and disclosure of your 
                        personally identifiable information and aggregate and/or anonymized data as set forth in our Privacy 
                        Notice, and to have your personally identifiable information collected, used, transferred to and processed 
                        in the United States.
                    </p>

                    <p className="sections__title__terms">8. Security</p>

                    <p className="footerLegal__paragraph__terms">Column cares about the integrity and security of your 
                        personal information. However, we cannot guarantee that unauthorized third parties will never 
                        be able to defeat our security measures or use your personal information for improper purposes. 
                        You acknowledge that you provide your personal information at your own risk.
                    </p>

                    <p className="sections__title__terms">9. DMCA Notice</p>

                    <p className="footerLegal__paragraph__terms">Since we respect artist and content owner rights, 
                        it is Column’s policy to respond to alleged infringement notices that comply with the Digital Millennium 
                        Copyright Act of 1998 (“DMCA”).
                        If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement 
                        and is accessible via the Services, please notify Column’s copyright agent as set forth in the DMCA. 
                        For your complaint to be valid under the DMCA, you must provide the following information in writing:
                    </p>

                    <ol className="footerLegal__orderedList__numbers">

                        <li>
                            <p className="footerLegal__paragraph__terms">An electronic or physical signature of a person authorized to 
                                act on behalf of the copyright owner;
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">Identification of the copyrighted work that you claim has been 
                                infringed;
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">Identification of the material that is claimed to 
                                be infringing and where it is located on the Services;
                            </p>
                        </li>


                        <li>
                            <p className="footerLegal__paragraph__terms">Information reasonably sufficient to permit Column to contact you, 
                                such as your address, telephone number, and, e-mail address;
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">A statement that you have a good faith belief that use of 
                                the material in the manner complained of is not authorized by the copyright owner, its agent, 
                                or law; and
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">A statement, made under penalty of perjury, 
                                that the above information is accurate, and that you are the copyright owner or are 
                                authorized to act on behalf of the owner.
                            </p>
                        </li>

                    </ol>

                    <p className="footerLegal__paragraph__terms">The above information must be  
                         submitted to the following DMCA Agent:
                    </p>

                    <ul className="footerLegal__list_nonstyled">
                        <li>
                            <p className="footerLegal__paragraph__terms">
                                Attn: 	DMCA Notice Column Inc.
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                 Address: 	[insert]
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                Tel.: 	[insert]
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                Fax: 	[insert]
                            </p>
                        </li>

                        <li>
                            <p className="footerLegal__paragraph__terms">
                                 Email: 	[copyright@Column.com]
                            </p>
                        </li>

                    </ul>

                    <p className="footerLegal__paragraph__terms">
                        Under federal law, if you knowingly misrepresent that online material is infringing, 
                        you may be subject to criminal prosecution for perjury and civil penalties, including 
                        monetary damages, court costs, and attorneys’ fees.
                        Please note that this procedure is exclusively for notifying Column and its affiliates that your 
                        copyrighted material has been infringed. The preceding requirements are intended to comply with Column’s 
                        rights and obligations under the DMCA, including 17 U.S.C. §512(c), but do not constitute legal advice. 
                        It may be advisable to contact an attorney regarding your rights and obligations under the DMCA and other 
                        applicable laws.
                    </p>

                    <p className="footerLegal__paragraph__terms">In accordance with the DMCA and other applicable law, 
                        Column has adopted a policy of terminating, in appropriate circumstances, Users who are deemed 
                        to be repeat infringers. Column may also at its sole discretion limit access to the Services 
                        and/or terminate the User Accounts of any Users who infringe any intellectual property rights 
                        of others, whether or not there is any repeat infringement.
                    </p>

                    <p className="sections__title__terms">10. Third-Party Links and Information</p>

                    <p className="footerLegal__paragraph__terms">The Services may contain links to third-party materials 
                        that are not owned or controlled by Column. Column does not endorse or assume any responsibility 
                        for any such third-party sites, information, materials, products, or services. If you access a 
                        third-party website or service from the Services or share your User Content on or through any 
                        third-party website or service, you do so at your own risk, and you understand that this 
                        Agreement and Column’s Privacy Notice do not apply to your use of such sites. You expressly 
                        relieve Column from any and all liability arising from your use of any third-party website, 
                        service, or content, including without limitation User Content submitted by other Users. 
                        Additionally, your dealings with or participation in promotions of advertisers found on the Services, 
                        including payment and delivery of goods, and any other terms (such as warranties) are solely between 
                        you and such advertisers. You agree that Column shall not be responsible for any loss or damage of any sort 
                        relating to your dealings with such advertisers.
                    </p>

                    <p className="sections__title__terms">11. Indemnity</p>

                    <p className="footerLegal__paragraph__terms">You agree to defend, indemnify and hold harmless Column and its subsidiaries, 
                        agents, licensors, managers, and other affiliated companies, and their employees, contractors, agents, officers and 
                        directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, 
                        and expenses (including but not limited to attorney’s fees) arising from: (i) your use of and access to the 
                        Services, including any data or content transmitted or received by you; (ii) your violation of any term of 
                        this Agreement, including without limitation your breach of any of the representations and warranties above; 
                        (iii) your violation of any third-party right, including without limitation any right of privacy or Intellectual 
                        Property Rights; (iv) your violation of any applicable law, rule or regulation; (v) User Content or any content 
                        that is submitted via your User Account including without limitation misleading, false, or inaccurate information; 
                        (vi) your willful misconduct; or (vii) any other party’s access and use of the Services with your unique username, 
                        password or other appropriate security code.
                    </p> 

                    <p className="sections__title__terms">12. Indemnity</p>

                    <p className="footerLegal__paragraph__terms">The Services are provided on an “as is” and “as available” 
                        basis. Use of the Services is at your own risk. To the maximum extent permitted by applicable law, 
                        the Services are provided without warranties of any kind, whether express or implied, including, 
                        but not limited to, implied warranties of merchantability, fitness for a particular purpose, or 
                        non-infringement. No advice or information, whether oral or written, obtained by you from Column or 
                        through the Services will create any warranty not expressly stated herein. Without limiting the foregoing, 
                        Column, its subsidiaries, its affiliates, and its licensors do not warrant that the content is accurate, 
                        reliable or correct; that the Services will meet your requirements; that the Services will be available at any 
                        particular time or location, uninterrupted or secure; that any defects or errors will be corrected; or that the 
                        Services are free of viruses or other harmful components. Any content downloaded or otherwise obtained through 
                        the use of the Services is downloaded at your own risk and you will be solely responsible for any damage to your 
                        computer system or mobile device or loss of data that results from such download or your use of the Service.
                    </p>

                    <p className="footerLegal__paragraph__terms">
                         Further, Column does not warrant, endorse, guarantee, or assume responsibility 
                         for any product or service advertised or offered by a third party through 
                         the Services or any hyperlinked website or service, and Column will not be a
                        party to or in any way monitor any transaction between you and third-party
                        providers of products or services.
                    </p>

                    <p className="footerLegal__paragraph__terms">Federal law, some states, provinces and other jurisdictions 
                        do not allow the exclusion and limitations of certain implied warranties, so the above exclusions 
                        may not apply to you. This Agreement gives you specific legal rights, and you may also have other 
                        rights which vary from state to state. The disclaimers and exclusions under this Agreement will not 
                        apply to the extent prohibited by applicable law.
                    </p>

                    <p className="sections__title__terms">13. Limitation of Liability</p>

                    <p className="footerLegal__paragraph__terms">To the maximum extent permitted by applicable law, 
                        in no event shall Column, its affiliates, agents, directors, employees, suppliers or 
                        licensors be liable for any indirect, punitive, incidental, special, consequential or 
                        exemplary damages, including without limitation damages for loss of profits, goodwill, 
                        use, data or other intangible losses, arising out of or relating to the use of, or inability to use, 
                        the Services. Under no circumstances will Column be responsible for any damage, loss or injury resulting 
                        from hacking, tampering or other unauthorized access or use of the Services or your account or the 
                        information contained therein.
                    </p>

                    <p className="footerLegal__paragraph__terms">To the maximum extent permitted by applicable law, 
                        Column assumes no liability or responsibility for any (i) errors, mistakes, or inaccuracies of 
                        content; (ii) personal injury or property damage, of any nature whatsoever, resulting from your 
                        access to or use of our service; (iii) any unauthorized access to or use of our secure servers 
                        and/or any and all personal information stored therein; (iv) any interruption or cessation of 
                        transmission to or from the Services; (v) any bugs, viruses, trojan horses, or the like that may be 
                        transmitted to or through the services by any third party; (vi) any errors or omissions in any content or 
                        for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or 
                        otherwise made available through the Services; and/or (vii) User Content or the defamatory, offensive, or 
                        illegal conduct of any third party. In no event shall Column, its affiliates, agents, directors, employees, 
                        suppliers, or licensors be liable to you for any claims, proceedings, liabilities, obligations, damages, 
                        losses or costs in an amount exceeding the amount you paid to Column hereunder in the twelve (12) months 
                        preceding the claim or $100.00, whichever is greater.
                    </p>

                    <p className="footerLegal__paragraph__terms">This limitation of liability section applies whether the alleged 
                        liability is based on contract, tort, negligence, strict liability, or any other basis, even if Column 
                        has been advised of the possibility of such damage.
                    </p>

                    <p className="footerLegal__paragraph__terms">Some states do not allow the exclusion or limitation of incidental or 
                        consequential damages, so the above limitations or exclusions may not apply to you. This Agreement gives you 
                        specific legal rights, and you may also have other rights which vary from state to state. The disclaimers, 
                        exclusions, and limitations of liability under this Agreement will not apply to the extent prohibited by 
                        applicable law.
                    </p>

                    <p className="sections__title__terms">14. Governing Law, Arbitration, 
                        and Class Action/Jury Trial Waiver
                    </p>

                    <p className="footerLegal__subtitle_terms">14.1 Governing Law</p>

                    <p className="footerLegal__paragraph__terms">You agree that: (i) the Services shall be deemed solely 
                        based in New York; and (ii) the Services shall be deemed a passive one that does not give rise 
                        to personal jurisdiction over us, either specific or general, in jurisdictions other than New York. 
                        This Agreement shall be governed by the internal substantive laws of the State of New York, without 
                        respect to its conflict of laws principles. The parties acknowledge that this Agreement evidences a 
                        transaction involving interstate commerce. Notwithstanding the preceding sentences with respect to the 
                        substantive law, any arbitration conducted pursuant to the terms of this Agreement shall be governed by 
                        the Federal Arbitration Act (9 U.S.C. §§ 1-16). The application of the United Nations Convention on 
                        Contracts for the International Sale of Goods is expressly excluded. You agree to submit to the personal 
                        jurisdiction of the federal and state courts located in New York, New York for any actions for which we retain 
                        the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the 
                        actual or threatened infringement, misappropriation or violation of a our copyrights, trademarks, trade 
                        secrets, patents, or other intellectual property or proprietary rights, as set forth in the Arbitration 
                        provision below, including any provisional relief required to prevent irreparable harm. You agree that 
                        New York, New York is the proper forum for any appeals of an arbitration award or for trial court proceedings 
                        in the event that the arbitration provision below is found to be unenforceable.
                    </p>

                    <p className="footerLegal__subtitle_terms">14.2 Arbitration</p>

                    <p className="footerLegal__paragraph__terms">Read this section carefully because it requires the parties to arbitrate 
                        their disputes and limits the manner in which you can seek relief from Column. For any dispute with Column, 
                        you agree to first contact us at hello@joincolumn.com and attempt to resolve the dispute with us informally. 
                        In the unlikely event that Column has not been able to resolve a dispute it has with you after sixty (60) days, 
                        we each agree to resolve any claim, dispute, or controversy (excluding any claims for injunctive or other equitable 
                        relief as provided below) arising out of or in connection with or relating to this Agreement, or the breach or 
                        alleged breach thereof (collectively, “Claims”), by binding arbitration by JAMS, under the Optional Expedited 
                        Arbitration Procedures then in effect for JAMS, except as provided herein. JAMS may be contacted at www.jamsadr.com. 
                        The arbitration will be conducted in New York, New York, unless you and Column agree otherwise. If you are using 
                        the Services for commercial purposes, each party will be responsible for paying any JAMS filing, administrative 
                        and arbitrator fees in accordance with JAMS rules, and the award rendered by the arbitrator shall include costs 
                        of arbitration, reasonable attorneys’ fees and reasonable costs for expert and other witnesses. If you are an 
                        individual using the Services for non-commercial purposes: (i) JAMS may require you to pay a fee for the 
                        initiation of your case, unless you apply for and successfully obtain a fee waiver from JAMS; (ii) the award 
                        rendered by the arbitrator may include your costs of arbitration, your reasonable attorney’s fees, and your 
                        reasonable costs for expert and other witnesses; and (iii) you may sue in a small claims court of competent 
                        jurisdiction without first engaging in arbitration, but this does not absolve you of your commitment to engage 
                        in the informal dispute resolution process. Any judgment on the award rendered by the arbitrator may be entered 
                        in any court of competent jurisdiction. Nothing in this Section shall be deemed as preventing Column from seeking 
                        injunctive or other equitable relief from the courts as necessary to prevent the actual or threatened infringement, 
                        misappropriation, or violation of our data security, Intellectual Property Rights or other proprietary rights.
                    </p>

                <p className="footerLegal__subtitle_terms">14.3 Class Action/Jury Trial Waiver</p>

                <p className="footerLegal__paragraph__terms">With respect to all persons and entities, 
                    regardless of whether they have obtained or used the Services for personal, 
                    commercial or other purposes, all Claims must be brought in the parties’ 
                    individual capacity, and not as a plaintiff or class member in any purported class action, 
                    collective action, private attorney general action or other representative proceeding. 
                    This waiver applies to class arbitration, and, unless we agree otherwise, the arbitrator 
                    may not consolidate more than one person’s Claims. You agree that, by entering into this 
                    Agreement, you and Column are each waiving the right to a trial by jury or to participate 
                    in a class action, collective action, private attorney general action, or other representative 
                    proceeding of any kind.
                </p>

                <p className="sections__title__terms">15. Additional Terms for Mobile Applications</p>

                <p className="footerLegal__subtitle_terms">15.1 Mobile Applications</p>

                <p className="footerLegal__paragraph__terms">We may make available software to access the Services via a mobile device 
                    (“Mobile Applications”). To use any Mobile Applications you must have a mobile device that is compatible with 
                    the Mobile Applications. Column does not warrant that the Mobile Applications will be compatible with your 
                    mobile device. You may use mobile data in connection with the Mobile Applications and may incur additional 
                    charges from your wireless provider for these services. You agree that you are solely responsible for any 
                    such charges. Column hereby grants you a non-exclusive, non-transferable, revocable license to use a compiled 
                    code copy of the Mobile Applications for one Column User Account on a device owned or leased solely by you, for 
                    your personal use. You may not: (i) modify, disassemble, decompile or reverse engineer the Mobile Applications, 
                    except to the extent that such restriction is expressly prohibited by law; (ii) rent, lease, loan, resell, sublicense, 
                    distribute or otherwise transfer the Mobile Applications to any third party or use the Mobile Applications to provide 
                    time sharing or similar services for any third party; (iii) make any copies of the Mobile Applications; (iv) remove, 
                    circumvent, disable, damage or otherwise interfere with security-related features of the Mobile Applications, features 
                    that prevent or restrict use or copying of any content accessible through the Mobile Applications, or features that 
                    enforce limitations on use of the Mobile Applications; or (v) delete the copyright and other proprietary rights notices 
                    on the Mobile Applications. You acknowledge that Column may from time to time issue upgraded versions of the Mobile 
                    Applications, and may automatically electronically upgrade the version of the Mobile Applications that you are using on 
                    your mobile device. You consent to such automatic upgrading on your mobile device, and agree that the terms and conditions 
                    of this Agreement will apply to all such upgrades. Any third-party code that may be incorporated in the Mobile Applications 
                    is covered by the applicable open source or third-party license EULA, if any, authorizing use of such code. The foregoing 
                    license grant is not a sale of the Mobile Applications or any copy thereof, and Column or its third-party partners or 
                    suppliers retain all right, title, and interest in the Mobile Applications (and any copy thereof). Any attempt by you to 
                    transfer any of the rights, duties or obligations hereunder, except as expressly provided for in this Agreement, is void. 
                    Column reserves all rights not expressly granted under this Agreement. If the Mobile Applications is being acquired on 
                    behalf of the United States Government, then the following provision applies. The Mobile Applications will be deemed to be 
                    “commercial computer software” and “commercial computer software documentation,” respectively, pursuant to DFAR Section 
                    227.7202 and FAR Section 12.212, as applicable. Any use, reproduction, release, performance, display or disclosure of t
                    he Services and any accompanying documentation by the U.S. Government will be governed solely by these Terms of Service 
                    and is prohibited except to the extent expressly permitted by these Terms of Service. The Mobile Applications originates 
                    in the United States, and is subject to United States export laws and regulations. The Mobile Applications may not be 
                    exported or re-exported to certain countries or those persons or entities prohibited from receiving exports from the 
                    United States. In addition, the Mobile Applications may be subject to the import and export laws of other countries. 
                    You agree to comply with all United States and foreign laws related to use of the Mobile Applications and the Services.
                </p>

                <p className="footerLegal__subtitle_terms">15.2 Mobile Applications from Apple App Store</p>

                <p className="footerLegal__paragraph__terms">The following applies to any Mobile Applications you 
                    acquire from the Apple App Store (“Apple-Sourced Software”): You acknowledge and agree that this 
                    Agreement is solely between you and Column, not Apple, Inc. (“Apple”) and that Apple has no responsibility 
                    for the Apple-Sourced Software or content thereof. Your use of the Apple-Sourced Software must comply with 
                    the App Store Terms of Service. You acknowledge that Apple has no obligation whatsoever to furnish any 
                    maintenance and support services with respect to the Apple-Sourced Software. In the event of any failure of 
                    the Apple-Sourced Software to conform to any applicable warranty, you may notify Apple, and Apple will refund 
                    the purchase price for the Apple-Sourced Software to you; to the maximum extent permitted by applicable law, 
                    Apple will have no other warranty obligation whatsoever with respect to the Apple-Sourced Software, and any 
                    other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any 
                    warranty will be solely governed by this Agreement and any law applicable to Column as provider of the software. 
                    You acknowledge that Apple is not responsible for addressing any claims of you or any third party relating to the 
                    Apple-Sourced Software or your possession and/or use of the Apple-Sourced Software, including, but not limited to: 
                    (i) product liability claims; (ii) any claim that the Apple-Sourced Software fails to conform to any applicable legal 
                    or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation; and all such 
                    claims are governed solely by this Agreement and any law applicable to Column as provider of the software. You 
                    acknowledge that, in the event of any third-party claim that the Apple-Sourced Software or your possession and 
                    use of that Apple-Sourced Software infringes that third party’s intellectual property rights, Column, not Apple, 
                    will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual 
                    property infringement claim to the extent required by this Agreement. You and Column acknowledge and agree that 
                    Apple, and Apple’s subsidiaries, are third-party beneficiaries of this Agreement as relates to your license of the 
                    Apple-Sourced Software, and that, upon your acceptance of the terms and conditions of this Agreement, Apple will 
                    have the right (and will be deemed to have accepted the right) to enforce this Agreement as relates to your license 
                    of the Apple-Sourced Software against you as a third-party beneficiary thereof.
                </p>

                <p className="footerLegal__subtitle_terms">15.3 Mobile Applications from Google Play Store</p>

                <p className="footerLegal__paragraph__terms">The following applies to any Mobile Applications you acquire 
                    from the Google Play Store (“Google-Sourced Software”): (i) you acknowledge that the Agreement is 
                    between you and Column only, and not with Google, Inc. (“Google”); (ii) your use of Google-Sourced 
                    Software must comply with Google’s then-current Google Play Store Terms of Service; (iii) Google is 
                    only a provider of the Google Play Store where you obtained the Google-Sourced Software; (iv) Column, 
                    and not Google, is solely responsible for its Google-Sourced Software; (v) Google has no obligation or 
                    liability to you with respect to Google-Sourced Software or the Agreement; and (vi) you acknowledge and 
                    agree that Google is a third-party beneficiary to the Agreement as it relates to Column’s Google-Sourced 
                    Software.
                </p>

                <p className="sections__title__terms">16. General</p>

                <p className="footerLegal__subtitle_terms">16.1 Assignment</p>

                <p className="footerLegal__paragraph__terms">This Agreement, and any rights and licenses granted hereunder, 
                    may not be transferred or assigned by you, but may be assigned by Column without restriction. 
                    Any attempted transfer or assignment in violation hereof shall be null and void.
                </p>

                <p className="footerLegal__subtitle_terms">16.2 Notification Procedures and Changes to the Agreement</p>

                <p className="footerLegal__paragraph__terms">Column may provide notifications, whether such notifications are 
                    required by law or are for marketing or other business related purposes, to you via email notice, 
                    written or hard copy notice, or through posting of such notice on our website, as determined by 
                    Column in our sole discretion. Column reserves the right to determine the form and means of providing 
                    notifications to our Users, provided that you may opt out of certain means of notification as described 
                    in this Agreement. Column is not responsible for any automatic filtering you or your network provider may 
                    apply to email notifications we send to the email address you provide us. Column may, in its sole discretion, 
                    modify or update this Agreement from time to time, and so you should review this page periodically. 
                    When we change the Agreement in a material manner, we will update the ‘last modified’ date at the top 
                    of this page and notify you that material changes have been made to the Agreement. Your continued use of the 
                    Services after any such change constitutes your acceptance of the new Terms of Service. If you do not agree to any of 
                    these terms or any future Terms of Services, do not use or access (or continue to access) the Services.
                </p>

                <p className="footerLegal__subtitle_terms">16.3 Entire Agreement/Severability</p>

                <p className="footerLegal__paragraph__terms">This Agreement, together with any amendments and any 
                    additional agreements you may enter into with Column in connection with the Services, shall 
                    constitute the entire agreement between you and Column concerning the Services. 
                    If any provision of this Agreement is deemed invalid by a court of competent jurisdiction, 
                    the invalidity of such provision shall not affect the validity of the remaining provisions of 
                    this Agreement, which shall remain in full force and effect, except that in the event of 
                    unenforceability of the universal Class Action/Jury Trial Waiver, the entire arbitration 
                    agreement shall be unenforceable.
                </p>

                <p className="footerLegal__subtitle_terms">16.4 No Waiver</p>

                <p className="footerLegal__paragraph__terms">No waiver of any term of this Agreement shall be deemed a further or 
                    continuing waiver of such term or any other term, and Column’s failure to assert any right or provision under 
                    this Agreement shall not constitute a waiver of such right or provision.
                </p>

                <p className="footerLegal__subtitle_terms">16.5 California Residents</p>

                <p className="footerLegal__paragraph__terms">The provider of services is set forth herein. If you are a 
                    California resident, in accordance with Cal. Civ. Code §1789.3, you may report complaints to the 
                    Complaint Assistance Unit of the Division of Consumer Services of the California Department of 
                    Consumer Affairs by contacting them in writing at 1625 North Market Blvd., Suite N 112 Sacramento, 
                    CA 95834, or by telephone at (800) 952-5210 or (916) 445-1254.
                </p>

                <p className="footerLegal__subtitle_terms">16.6 Contact</p>

                <p className="footerLegal__paragraph__terms">Please contact us at <Link>hello@joincolumn.com</Link>  with any questions 
                    regarding this Agreement.
                </p>

                </div>

            </div>

        </div>
    );
}

export default TermsOfService;