import './Profile.css';
import React from 'react';
import {useParams} from 'react-router-dom';
import useFetch from '../../useFetch/useFetch';
import GoBack from '../../FooterLegal/GoBack/GoBack';

import column from '../../images/social/C.svg';
import twitter from '../../images/social/Twitter.svg';
import linked_in from '../../images/social/Linked_In.svg';


import { Link } from 'react-router-dom';
import arrow from '../../images//arrow_blog.png';
import DispatchSearch from '../Dispatch/DispatchSearch/DispatchSearch';




function Profile() {
    const {id} = useParams();

    const{data: dispatch , error} = useFetch('http://localhost:8000/dispatches/' + id);
    
    return(
        <div className="profile"> 

        {error &&  <p>error</p> }

        {
            window.innerWidth > 1200 && <GoBack />
           }
           
                {dispatch &&  (

                <div className="profile__wrapper">

                    <div className="profile__headline">

                        <div className="profile__headline__avatar">
                            <img src={dispatch.avatar} alt="" />
                        </div>

                        <div className="profile__headline__title">
                            <h2>{dispatch.author}  &gt;  Dispatches</h2>  
                        </div>

                        <div className="profile__headline__empty">
                        </div>
                       
                    </div>

                    <div className="profile__content">

                        <div className="profile__content__bio__wrapper">
                            <p className="profile__content__bio__title">Bio</p>

                            <p className="profile__content__bio"> {dispatch.bio}</p>
                           

                            <div className="profile__content__bio__socials">

                                <img src={column} alt="" />
                                <img src={twitter} alt="" />
                                <img src={linked_in} alt="" />
                            </div>
                        </div>

                        <div className="profile__content__dispatches">

                            <DispatchSearch />

                        <div className="dispatches">

                            <div className="dispatches__preview" key={dispatch.id}>
                            <div className="blog__preview__img">
                                <img src={dispatch.img} alt="" />
                            </div>

                            <div className="dispatches__preview__content">
                                <h2 className="dispatches__preview__title">{dispatch.title}</h2>

                                <div className="dispatches__preview__info">
                                    <div className="info__container">
                                        <img className="dispatch__img" src={dispatch.avatar} alt="" />
                                        <p className="dispatch__author">{dispatch.author} · Dec 12, 2020</p>
                                    </div>

                                            
                            <div className="img-container">
                            { dispatch.dispatch ? <p className="dispatch__tag">Dispatch</p> : <p className="dispatch__tag">News</p>}
                            
                                <Link to={
                                    { 
                                    pathname:    `/dispatch/${dispatch.id}`,
                                    state: "dispatch"
                                    }
                                    }>
                                    <img className="view-arrow" src={arrow} alt="" />
                                </Link>  
                            </div>
                                        
                                </div>
                            </div>

                            </div>

                            <div className="load__more__profile">
                            <p>Load More</p>
                        </div>
                           

                        </div>


                          
                        </div>

                        <div className="profile__content__emopty">

                        </div>

                    </div>

                    
                </div>
                    
                )}

        </div>
    );
}

export default Profile;