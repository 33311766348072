import { gql } from '@apollo/client';

export const REQUEST_INVITE = gql`
  mutation Invitation(
    $firstName: String!
    $lastName: String!
    $email: String!
    $expertise: String!
    $note: String
    $verificationLink: String
    $special: Boolean
  ) {
    requestInvitation(
      firstName: $firstName
      lastName: $lastName
      email: $email
      expertise: $expertise
      note: $note
      verificationLink: $verificationLink
      special: $special
    ) {
      firstName
    }
  }
`;
