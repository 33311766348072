import "./EarnReputation.css";
import React, { useEffect, useState } from "react";

import ios_mockup from "../../images/phones_home/ios.svg";
import android_mockup from "../../images/phones_home/android.svg";
import profile_phone from "../../images/phones_home/profile_phone.png";
import Aos from "aos";
import "aos/dist/aos.css";

function EarnReputation() {
  const [animationLeft, setAnimationLeft] = useState("fade-left");
  const [animationRight, setAnimationRight] = useState("fade-right");

  useEffect(() => {
    Aos.init({ duration: 700, once: "true" });

    if (window.innerWidth < 650) {
      setAnimationLeft("fade-up");
      setAnimationRight("fade-up");
    }

    checkDevice();
  }, []);

  const [isIos, setIsIos] = useState(true);

  function checkDevice() {
    //var is_OSX = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    var is_iOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);

    var is_Mac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    var is_iPhone = navigator.platform === "iPhone";
    var is_iPod = navigator.platform === "iPod";
    var is_iPad = navigator.platform === "iPad";

    if (is_Mac || is_iOS || is_iPhone || is_iPod || is_iPad) {
      setIsIos(true);
    } else {
      setIsIos(false);
    }
  }

  return (
    <div className="earnReputation">
      <div className="earn__wrapper">
        <div className="earnReputation__content">
          <div className="earnReputation__image">
            <div className="phones">
              <div
                className="phone__container"
                data-aos={animationRight}
                data-aos-delay="200"
              >
                <img
                  className="earnReputation__ios__mockup"
                  src={isIos ? ios_mockup : android_mockup}
                  alt=""
                />
                <img className="profile__phone" src={profile_phone} alt="" />
              </div>
            </div>

            <p
              data-aos={animationRight}
              data-aos-delay="200"
              className="earnReputation__subtitle"
            >
              You can accumulate reputation in every topic you engage with. This
              reputation is visible to others.
            </p>
          </div>

          <div className="earnReputation__text">
            <p
              data-aos={animationLeft}
              data-aos-delay="200"
              className="earnReputation__title"
            >
              Earn Reputation
            </p>

            <p
              data-aos={animationLeft}
              data-aos-delay="200"
              className="earnReputation__paragraph"
            >
              On legacy social media, it's hard to know who knows what. Follower
              counts are a poor way to measure reputability. What if when you
              posted and interacted with specific topics, you could grow your
              reputation in each topic?
            </p>

            <p
              data-aos={animationLeft}
              data-aos-delay="200"
              className="earnReputation__paragraph"
            >
              You can think of Column/ as the first multiplayer game for
              high-quality information, or, a real-world social event like a
              dinner party where this type of feedback is live. As you
              contribute to the conversation and other members respond to your
              posts, you gain reputation.
            </p>
          </div>
        </div>
      </div>

      <div className="earnReputation__statistics">
        <div className="statistics" data-aos="fade-up" data-aos-delay="200">
          <div className="statistics__up">
            <div className="statistics__icon">
              <svg
                width="10"
                height="23"
                viewBox="0 0 10 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.50769 1.15445C8.81026 1.19547 8.30769 1.27752 8 1.4006C7.69231 1.52368 7.47692 1.75957 7.35384 2.10829C7.25128 2.45701 7.2 3.02111 7.2 3.8006V19.0621C7.2 19.8416 7.25128 20.4057 7.35384 20.7544C7.47692 21.1032 7.69231 21.3391 8 21.4621C8.30769 21.5852 8.81026 21.6673 9.50769 21.7083V22.3237C8.4 22.2621 6.85128 22.2314 4.86154 22.2314C2.66667 22.2314 1.04615 22.2621 0 22.3237V21.7083C0.676923 21.6673 1.16923 21.5852 1.47692 21.4621C1.80513 21.3391 2.02051 21.1032 2.12308 20.7544C2.24615 20.4057 2.30769 19.8416 2.30769 19.0621V3.8006C2.30769 3.02111 2.24615 2.45701 2.12308 2.10829C2.02051 1.75957 1.80513 1.52368 1.47692 1.4006C1.16923 1.27752 0.676923 1.19547 0 1.15445V0.539062C1.04615 0.6006 2.66667 0.631369 4.86154 0.631369C6.85128 0.631369 8.4 0.6006 9.50769 0.539062V1.15445Z"
                  fill="#3D8F44"
                />
              </svg>

              <svg
                className="level__indicator"
                width="49"
                height="91"
                viewBox="0 0 49 91"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M48.3346 89.8334C22.5613 89.8334 1.66797 68.94 1.66797 43.1667C1.66797 24.7152 12.3766 8.76479 27.918 1.19141"
                  stroke="#3D8F44"
                  strokeWidth="1.75"
                />
              </svg>
            </div>

            <svg
              className="statistics__dash"
              preserveAspectRatio="none"
              viewBox="0 0 384 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M384 0.5H263.5C254.663 0.5 247.732 7.81176 245.223 16.2846C238.424 39.2473 217.168 56 192 56C166.832 56 145.576 39.2473 138.777 16.2847C136.268 7.81177 129.337 0.5 120.5 0.5H0"
                stroke="#3D8F44"
                strokeDasharray="4 4"
              />
            </svg>
          </div>

          <div className="statistics__level">
            <p>
              {" "}
              Writing <span>Level 1 </span>{" "}
            </p>
          </div>
        </div>

        <div className="statistics" data-aos="fade-up" data-aos-delay="300">
          <div className="statistics__up">
            <div className="statistics__icon">
              <svg
                width="22"
                height="23"
                viewBox="0 0 22 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.50769 1.15445C8.81026 1.19547 8.30769 1.27752 8 1.4006C7.69231 1.52368 7.47692 1.75957 7.35385 2.10829C7.25128 2.45701 7.2 3.02111 7.2 3.8006V19.0621C7.2 19.8416 7.25128 20.4057 7.35385 20.7544C7.47692 21.1032 7.69231 21.3391 8 21.4621C8.30769 21.5852 8.81026 21.6673 9.50769 21.7083V22.3237C8.4 22.2621 6.85128 22.2314 4.86154 22.2314C2.66667 22.2314 1.04615 22.2621 0 22.3237V21.7083C0.676923 21.6673 1.16923 21.5852 1.47692 21.4621C1.80513 21.3391 2.02051 21.1032 2.12308 20.7544C2.24615 20.4057 2.30769 19.8416 2.30769 19.0621V3.8006C2.30769 3.02111 2.24615 2.45701 2.12308 2.10829C2.02051 1.75957 1.80513 1.52368 1.47692 1.4006C1.16923 1.27752 0.676923 1.19547 0 1.15445V0.539062C1.04615 0.6006 2.66667 0.631369 4.86154 0.631369C6.85128 0.631369 8.4 0.6006 9.50769 0.539062V1.15445Z"
                  fill="#3D8F44"
                />
                <path
                  d="M21.8454 1.15445C21.148 1.19547 20.6454 1.27752 20.3377 1.4006C20.03 1.52368 19.8147 1.75957 19.6916 2.10829C19.589 2.45701 19.5377 3.02111 19.5377 3.8006V19.0621C19.5377 19.8416 19.589 20.4057 19.6916 20.7544C19.8147 21.1032 20.03 21.3391 20.3377 21.4621C20.6454 21.5852 21.148 21.6673 21.8454 21.7083V22.3237C20.7377 22.2621 19.189 22.2314 17.1993 22.2314C15.0044 22.2314 13.3839 22.2621 12.3377 22.3237V21.7083C13.0147 21.6673 13.507 21.5852 13.8147 21.4621C14.1429 21.3391 14.3583 21.1032 14.4608 20.7544C14.5839 20.4057 14.6454 19.8416 14.6454 19.0621V3.8006C14.6454 3.02111 14.5839 2.45701 14.4608 2.10829C14.3583 1.75957 14.1429 1.52368 13.8147 1.4006C13.507 1.27752 13.0147 1.19547 12.3377 1.15445V0.539062C13.3839 0.6006 15.0044 0.631369 17.1993 0.631369C19.189 0.631369 20.7377 0.6006 21.8454 0.539062V1.15445Z"
                  fill="#3D8F44"
                />
              </svg>

              <svg
                className="level__indicator__2"
                width="38"
                height="19"
                viewBox="0 0 38 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M37.3349 17.8327C22.868 17.8327 9.93869 11.2498 1.37891 0.916016"
                  stroke="#3D8F44"
                  strokeWidth="1.75"
                />
              </svg>
            </div>

            <svg
              className="statistics__dash"
              preserveAspectRatio="none"
              viewBox="0 0 384 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M384 0.5H263.5C254.663 0.5 247.732 7.81176 245.223 16.2846C238.424 39.2473 217.168 56 192 56C166.832 56 145.576 39.2473 138.777 16.2847C136.268 7.81177 129.337 0.5 120.5 0.5H0"
                stroke="#3D8F44"
                strokeDasharray="4 4"
              />
            </svg>
          </div>

          <div className="statistics__level">
            <p>
              {" "}
              Political Science <span>Level 2 </span>{" "}
            </p>
          </div>
        </div>

        <div className="statistics" data-aos="fade-up" data-aos-delay="400">
          <div className="statistics__up">
            <div className="statistics__icon">
              <svg
                width="34"
                height="23"
                viewBox="0 0 34 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.50769 1.15445C8.81026 1.19547 8.30769 1.27752 8 1.4006C7.69231 1.52368 7.47692 1.75957 7.35385 2.10829C7.25128 2.45701 7.2 3.02111 7.2 3.8006V19.0621C7.2 19.8416 7.25128 20.4057 7.35385 20.7544C7.47692 21.1032 7.69231 21.3391 8 21.4621C8.30769 21.5852 8.81026 21.6673 9.50769 21.7083V22.3237C8.4 22.2621 6.85128 22.2314 4.86154 22.2314C2.66667 22.2314 1.04615 22.2621 0 22.3237V21.7083C0.676923 21.6673 1.16923 21.5852 1.47692 21.4621C1.80513 21.3391 2.02051 21.1032 2.12308 20.7544C2.24615 20.4057 2.30769 19.8416 2.30769 19.0621V3.8006C2.30769 3.02111 2.24615 2.45701 2.12308 2.10829C2.02051 1.75957 1.80513 1.52368 1.47692 1.4006C1.16923 1.27752 0.676923 1.19547 0 1.15445V0.539062C1.04615 0.6006 2.66667 0.631369 4.86154 0.631369C6.85128 0.631369 8.4 0.6006 9.50769 0.539062V1.15445Z"
                  fill="#3D8F44"
                />
                <path
                  d="M21.8454 1.15445C21.148 1.19547 20.6454 1.27752 20.3377 1.4006C20.03 1.52368 19.8147 1.75957 19.6916 2.10829C19.589 2.45701 19.5377 3.02111 19.5377 3.8006V19.0621C19.5377 19.8416 19.589 20.4057 19.6916 20.7544C19.8147 21.1032 20.03 21.3391 20.3377 21.4621C20.6454 21.5852 21.148 21.6673 21.8454 21.7083V22.3237C20.7377 22.2621 19.189 22.2314 17.1993 22.2314C15.0044 22.2314 13.3839 22.2621 12.3377 22.3237V21.7083C13.0147 21.6673 13.507 21.5852 13.8147 21.4621C14.1429 21.3391 14.3583 21.1032 14.4608 20.7544C14.5839 20.4057 14.6454 19.8416 14.6454 19.0621V3.8006C14.6454 3.02111 14.5839 2.45701 14.4608 2.10829C14.3583 1.75957 14.1429 1.52368 13.8147 1.4006C13.507 1.27752 13.0147 1.19547 12.3377 1.15445V0.539062C13.3839 0.6006 15.0044 0.631369 17.1993 0.631369C19.189 0.631369 20.7377 0.6006 21.8454 0.539062V1.15445Z"
                  fill="#3D8F44"
                />
                <path
                  d="M33.7693 1.15445C33.0718 1.19547 32.5693 1.27752 32.2616 1.4006C31.9539 1.52368 31.7385 1.75957 31.6154 2.10829C31.5129 2.45701 31.4616 3.02111 31.4616 3.8006V19.0621C31.4616 19.8416 31.5129 20.4057 31.6154 20.7544C31.7385 21.1032 31.9539 21.3391 32.2616 21.4621C32.5693 21.5852 33.0718 21.6673 33.7693 21.7083V22.3237C32.6616 22.2621 31.1129 22.2314 29.1231 22.2314C26.9283 22.2314 25.3077 22.2621 24.2616 22.3237V21.7083C24.9385 21.6673 25.4308 21.5852 25.7385 21.4621C26.0667 21.3391 26.2821 21.1032 26.3847 20.7544C26.5077 20.4057 26.5693 19.8416 26.5693 19.0621V3.8006C26.5693 3.02111 26.5077 2.45701 26.3847 2.10829C26.2821 1.75957 26.0667 1.52368 25.7385 1.4006C25.4308 1.27752 24.9385 1.19547 24.2616 1.15445V0.539062C25.3077 0.6006 26.9283 0.631369 29.1231 0.631369C31.1129 0.631369 32.6616 0.6006 33.7693 0.539062V1.15445Z"
                  fill="#3D8F44"
                />
              </svg>

              <svg
                className="level__indicator__3"
                width="41"
                height="82"
                viewBox="0 0 41 82"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M41 81C18.9086 81 1 63.0914 1 41C1 18.9086 18.9086 1 41 1"
                  stroke="#3D8F44"
                  strokeWidth="1.5"
                />
              </svg>
            </div>

            <svg
              className="statistics__dash"
              preserveAspectRatio="none"
              viewBox="0 0 384 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M384 0.5H263.5C254.663 0.5 247.732 7.81176 245.223 16.2846C238.424 39.2473 217.168 56 192 56C166.832 56 145.576 39.2473 138.777 16.2847C136.268 7.81177 129.337 0.5 120.5 0.5H0"
                stroke="#3D8F44"
                strokeDasharray="4 4"
              />
            </svg>
          </div>

          <div className="statistics__level">
            <p>
              {" "}
              Musicology <span>Level 3 </span>{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="earnReputation__bgimage"></div>
    </div>
  );
}

export default EarnReputation;
