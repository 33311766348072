import './DisptachPreview.css';
import { Link } from 'react-router-dom';
import arrow from '../../../images//arrow_blog.png';
import React, {useState, useRef} from 'react';
import search from '../../../images/search.png';

//import useFetch from '../../../useFetch/useFetch';


function DispatchPreview({data, error}) {

    //const{data: dispatches , error} = useFetch('http://localhost:8000/dispatches/' );

    const[isSearching, setIsSearching] = useState(false);
    const[searchValue, setSearchValue] = useState("");


    const[filter, setFilter] = useState("all");

    
    function setValue(e) {
        setSearchValue(e.target.value);


        if (e.target.value === "") {
            setIsSearching(false);
        }
        else{
            setIsSearching(true);
        }
    }

    function clearInput(e){
        setIsSearching(false);
        setSearchValue("")
        searchInput.current.focus();
    }

    function showSearch(){
        setSearchVisible(true)

        setTimeout(function(){
            searchInput.current.focus();
       }, 20);
    }

    const [searchVisible, setSearchVisible] = useState(false);
    const searchInput = useRef(null);

    return(
        <div className="dispatches">

                    <div className="disptches__search">

                    <div className="search__icon" onClick={showSearch}>
                        <img src={search} alt="" />
                    </div>

                    {searchVisible ?

                    <div className="search__input">
                        <input
                            placeholder="Search..." 
                            type="text" 
                            value={searchValue}
                            onChange={setValue}
                            ref={searchInput}
                        />

                        {isSearching ? 
                        <p onClick={clearInput} className="search__input__clear">Clear</p> 
                        :
                        <p onClick={()=> setSearchVisible(false)} className="search__input__clear">Cancel</p>} 

                    </div> 

                    :  

                    <div className="search__filters">
                        <p className={ filter==="all"? "active" : ""} onClick={()=> setFilter("all")}>All</p>
                        <p className={ filter==="dispatches"? "active" : ""} onClick={()=> setFilter("dispatches")}>Dispatches</p>
                        <p className={ filter==="news"? "active" : ""} onClick={()=> setFilter("news")}>News</p>
                    </div> 

                    }

                    </div>


            {error && <p>error</p>} 

        {data && data.filter((dispatch) => {

            if(dispatch.title.toLowerCase().includes(searchValue.toLowerCase())) 
               
            return dispatch
        }).map((dispatch)=> (

        <div className={filter !== "news" ? "dispatches__preview" : "none"} key={dispatch.id}>
            <div className="blog__preview__img">
            <Link to={
                            { 
                               pathname:    `/dispatch/${dispatch.id}`,
                               state: "dispatch"
                            }
                            }>
           
                <img src={dispatch.img} alt="" />
                </Link>
            </div>

            <div className="dispatches__preview__content">

                 <Link to={
                    { 
                        pathname:    `/dispatch/${dispatch.id}`,
                        state: "dispatch"
                    }
                    }>
                
                    <h2 className="dispatches__preview__title">{dispatch.title}</h2>

                </Link>

                <div className="dispatches__preview__info">
                    <Link to={
                            {
                                pathname: `/profile/${dispatch.id}`,
                                state:  "profile"
                                    
                                
                            }
                            
                            }>  
                            <div className="info__container">
                                <img className="dispatch__img" src={dispatch.avatar} alt="" />
                                <p className="dispatch__author">{dispatch.author} · Dec 12, 2020</p>
                            
                            </div>
                    </Link>

                    <div className="img-container">
                    { dispatch.dispatch ? <p className="dispatch__tag">Dispatch</p> : <p className="dispatch__tag">News</p>}
                    
                        <Link to={
                            { 
                               pathname:    `/dispatch/${dispatch.id}`,
                               state: "dispatch"
                            }
                            }>
                            <img className="view-arrow" src={arrow} alt="" />
                        </Link>  
                    </div>
                        
                </div>
            </div>

            </div>
            )) } 

        </div>
    );
}

export default DispatchPreview;