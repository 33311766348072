import './Dispatch.css';
import {useParams} from 'react-router-dom';
import useFetch from '../../useFetch/useFetch';
import { Link } from 'react-router-dom';
import React from 'react';
import twitter from '../../images/social/Twitter.svg';
import linked_in from '../../images/social/Linked_In.svg';
import copu_link from '../../images/social/Copy_Link.svg';
import GoBack from '../../FooterLegal/GoBack/GoBack';

function Dispatch() {
    
    const {id} = useParams();

    const{data: dispatch , error} = useFetch('http://localhost:8000/dispatches/' + id);

    return(
        <div className="dispatch-holder">
 
            {
            window.innerWidth > 1200 && <GoBack />
           }

            <div className="dispatch__wrapper">
                {error && <p>{error}</p>} 

                {dispatch && (
                    <div className="dispatch">   

                        <div className="dispatch__headline">
                            <h2>{dispatch.title}</h2>

                            <Link to={
                                    {
                                        pathname: `/profile/${dispatch.id}`,
                                        state:  "profile"
                                            
                                        
                                    }
                                    
                                    }>  
                                <div className="info__container">
                                        <img className="dispatch__img" src={dispatch.avatar} alt="" />
                                        <p className="dispatch__author">{dispatch.author} · Dec 12, 2020</p>
                                </div>
                            </Link>

                        </div>

                        <div className="dispatch__content">
                        <p>{dispatch.body}</p>

                               <img src={dispatch.img} alt="" />

                            <p>{dispatch.body}</p>

                        </div>

                        <div className="dispatch__footer">

                            <p>Share:</p>

                            <img src={twitter} alt="" />
                            <img src={linked_in} alt="" />
                            <img src={copu_link} alt="" />

                        </div>
                
                    </div>

                )}

            </div>
       </div>
    );
}

export default Dispatch;