import React, { useEffect, useState, useRef } from "react";
//import { useHistory } from 'react-router-dom';
import svgStroke1 from "../../images/icons/Brush_Stroke_Lime.svg";
import svgStroke2 from "../../images/icons/Brush_Stroke_Blue.svg";
import svgStroke3 from "../../images/icons/Brush_Stroke_Brown.svg";
import svgStroke4 from "../../images/icons/Brush_Stroke_Green.svg";
import svgStroke5 from "../../images/icons/Brush_Stroke_Pink.svg";
import svgStroke6 from "../../images/icons/Brush_Stroke_Purple.svg";
import svgStroke7 from "../../images/icons/Brush_Stroke_Red.svg";

import columnData from "./landing-columns.json";
import postData from "./landing-posts.json";
import "./TrendingCarousel.css";

import Aos from "aos";
import "aos/dist/aos.css";

const map_strokes = {
  0: svgStroke1,
  1: svgStroke2,
  2: svgStroke3,
  3: svgStroke4,
  4: svgStroke5,
  5: svgStroke6,
  6: svgStroke7,
};

function TrendingCarousel() {
  const slider = useRef();

  const [columnNameWidths, setColumnNameWidths] = useState([]);

  useEffect(() => {
    Aos.init({ duration: 700, once: "true" });

    const slider_carousel = slider.current;

    //console.log(slider);

    let isDown = false;
    let startX;
    let scrollLeft;

    slider_carousel.addEventListener("mousedown", (e) => {
      isDown = true;

      startX = e.pageX - slider_carousel.offsetLeft;
      scrollLeft = slider_carousel.scrollLeft;
    });
    slider_carousel.addEventListener("mouseleave", () => {
      isDown = false;
    });
    slider_carousel.addEventListener("mouseup", () => {
      isDown = false;
    });
    slider_carousel.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider_carousel.offsetLeft;
      const walk = x - startX;
      slider_carousel.scrollLeft = scrollLeft - walk;
    });

    setTimeout(() => {
      for (let i = 0; i < 6; i += 1) {
        const elem = document.getElementById(`columntext${i}`);

        if (elem) {
          const originalText = elem.innerHTML;

          elem.innerHTML = `${originalText}<span id='cursor${i}'></span>`;
          const arr_widths = columnNameWidths;
          arr_widths.push({
            index: i,
            width: document.getElementById(`cursor${i}`).offsetLeft + 25,
          });
          setColumnNameWidths([...arr_widths]);
          elem.innerHTML = originalText;
        }
      }

      for (let i = 0; i < 6; i += 1) {
        const desc = document.getElementById(`columndesc${i}`);
        if (desc.innerHTML.length > 150) {
          // return desc.innerHTML.substring(0, 150) + '...';
          //console.log(desc.innerHTML);

          desc.innerHTML = desc.innerHTML.substring(0, 140) + "...";
        }
      }
    }, 1);

    let trending__carousel__holder = slider.current;

    let left_arrow = document.querySelector(".left-arrow");
    let right_arrow = document.querySelector(".right-arrow");

    trending__carousel__holder.onmouseover = function (e) {
      var x = e.pageX - this.offsetLeft;
      var y = e.pageY - this.offsetTop;

      var edge = closestEdge(x, y, this.clientWidth, this.clientHeight);
    };

    function closestEdge(x, y, w, h) {
      var leftEdgeDist = distMetric(x, y, 0, h);
      var rightEdgeDist = distMetric(x, y, w, h);
      var min = Math.min(leftEdgeDist, rightEdgeDist);

      switch (min) {
        case leftEdgeDist:
          left_arrow.classList.add("show");
          right_arrow.classList.remove("show");
          return "left";

        case rightEdgeDist:
          right_arrow.classList.add("show");
          left_arrow.classList.remove("show");
          return "right";
      }
    }

    function distMetric(x, y, x2, y2) {
      var xDiff = x - x2;
      var yDiff = y - y2;
      return xDiff * xDiff + yDiff * yDiff;
    }

    right_arrow.addEventListener("click", function () {
      if (window.innerWidth >= 1550) {
        slider_carousel.scrollLeft += 370;
      } else {
        slider_carousel.scrollLeft += 324;
      }
    });

    left_arrow.addEventListener("click", function () {
      if (window.innerWidth >= 1550) {
        slider_carousel.scrollLeft -= 370;
      } else {
        slider_carousel.scrollLeft -= 324;
      }
    });
  }, []);

  return (
    <div className="trending-wrapper">
      <div className="trending">
        <div
          className="trending__wrapper"
          data-aos="fade-up"
          data-aos-delay="0"
        >
          <div className="trending__wrapper__inner">
            <p className="trending__title">What our users are saying</p>
            <p className="trending__subtitle">
              The votes are in. People love Column.
            </p>
          </div>
        </div>

        <div
          className="trending__wrapper arrow-holder"
          data-aos-anchor="#arrow_animation"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          <div className="left-arrow">
            <svg
              width="27"
              height="18"
              viewBox="0 0 27 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.41391 8.00347L10.7046 1.70839L9.2899 0.294664L0.585831 9.00481L9.29597 17.7089L10.7097 16.2942L4.41461 10.0035L27.0004 9.9956L26.9997 7.9956L4.41391 8.00347Z"
                fill="#3D8F44"
              />
            </svg>
          </div>

          <div className="right-arrow show">
            <svg
              width="28"
              height="18"
              viewBox="0 0 28 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.1617 10.0931L16.8068 16.3234L18.207 17.7515L26.9998 9.131L18.3793 0.338187L16.9511 1.73834L23.1815 8.09318L0.596779 7.86976L0.576996 9.86966L23.1617 10.0931Z"
                fill="#3D8F44"
              />
            </svg>
          </div>
        </div>

        <div className="trending__carousel__holder" ref={slider}>
          <div className="trending__carousel" id="arrow_animation">
            {postData.map((_content, index) => (
              <div
                className="trending__animation"
                data-aos="fade-up"
                data-aos-delay={_content.id * 100}
                key={_content.id}
              >
                <div className="carousel__item">
                  <div key={_content.id}>
                    {/* <div style={{ position: "relative" }}>
                      <div style={{ zIndex: 10, position: "relative" }}>
                        <p className="postHeader">
                          <span
                            id={`columntext${index}`}
                            style={{ zIndex: 10 }}
                          >
                            {_content.name}
                          </span>
                        </p>
                      </div>
                      <div
                        className="underline"
                        style={{
                          width: columnNameWidths.find((u) => u.index === index)
                            ? columnNameWidths.find((u) => u.index === index)
                                .width
                            : 0,
                          zIndex: 1,
                          position: "relative",
                        }}
                      >
                        <img
                          src={map_strokes[index.toString()]}
                          alt="Brush Stroke"
                          style={{
                            height: 18,
                            width: columnNameWidths.find(
                              (u) => u.index === index
                            )
                              ? columnNameWidths.find((u) => u.index === index)
                                  .width
                              : 0,
                            zIndex: 1,
                            transition: "0.5s ease 0." + index + "s",
                            opacity: 0.9,
                          }}
                        />
                      </div>
                    </div> */}
                    <div className="postContainer">
                      {/* <div className="postNumbers">
                        <p>
                          {_content.members_count} members &nbsp; · &nbsp;{" "}
                          {_content.posts_count} posts{" "}
                        </p>
                      </div> */}
                      <div className="avatarRowContainer">
                        <div className="avatarContainer" key={_content.avatar}>
                          <img
                            className="avatarImage"
                            src={_content.avatar}
                            alt="profile_image"
                          />
                          <div className="authorName">
                            {_content.author_name}
                          </div>
                          <div className="authorUsername">
                            · {_content.author_username}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="marginGutter">
                      <p
                        className="carousel__description"
                        id={`columndesc${index}`}
                      >
                        {_content.description}
                      </p>
                    </div>
                    <div className="postActions">
                      <div className="votes">
                        <img
                          className="avatarImage"
                          src={"/images/Upvote.svg"}
                          alt="Upvote"
                        />

                        <p className="carousel__description">
                          {_content.upvote_count}
                        </p>

                        <img
                          className="avatarImage"
                          src={"/images/Downvote.svg"}
                          alt="Downvote"
                        />
                      </div>

                      <img
                        className="avatarImage"
                        src={"/images/Reply.svg"}
                        alt="Reply"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrendingCarousel;
