import React, { useState, useEffect } from "react";
import Typical from "react-typical";

import RequestBtn from "../../RequestBtn/RequestBtn";

import "./Hero.css";
import { TextField } from "@material-ui/core";
import { useForm, ValidationError } from "@formspree/react";
import ReactGA from "react-ga";

import landingContent from "../../Utils/landingContent.json";

function Hero() {
  const goDown = (e) => {
    const anchor = document.querySelector("#join");
    anchor.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const [state, handleSubmit] = useForm("xknypara");

  const [formIsValid, setFormIsValid] = useState(false);

  const [pageContent, setPageContent] = useState();

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    const { v } = params;

    setPageContent(landingContent[`${v ? v : "a"}`]);

    ReactGA.initialize("UA-183544805-1");

    ReactGA.pageview(`Column Signup Landing - Variant ${v}`);
  }, []);

  useEffect(() => {
    if (state.succeeded) {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      const { v } = params;
      ReactGA.event({
        category: "User",
        action: `Sign Up From Landing Page - Variant ${v}`,
        source: "Twitter",
        label: "Sign up Waitlist Button",
      });
    }
  }, [state]);

  return (
    <div className="hero-wrapper">
      <div className="hero__wrapper">
        <div className="hero">
          <div className="hero__text">
            <p className="hero__subtitle t0">{pageContent?.title1}</p>
            <p className="hero__title t1">{pageContent?.title2} </p>
            <p className="hero__title t2">{pageContent?.title3}</p>
            {/* <p className="hero__title t3">
              for{" "}
              <Typical
                loop={Infinity}
                wrapper="span"
                steps={[
                  "Science",
                  2000,
                  "Ethics",
                  2000,
                  "Tech",
                  2000,
                  "Art",
                  2000,
                  "Design",
                  2000,
                  "Politics",
                  2000,
                  "News",
                  2000,
                ]}
              />
            </p> */}
          </div>

          <div className="call-to-action">
            <form className="call-to-action__holder" onSubmit={handleSubmit}>
              {state.succeeded ? (
                <p className="hero__subtitle">
                  You're all set. Look out for an email from Column.
                </p>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 40,
                  }}
                >
                  <p className="hero__subtitle t4">{pageContent?.CTA1}</p>
                  <TextField
                    className="animation__btn t5"
                    label="Your Email"
                    id="signup"
                    type="email"
                    name="email"
                    variant="outlined"
                    style={{
                      background: "white",
                      marginRight: 20,
                    }}
                    color="#3D8F44"
                  />
                  <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                  />

                  <div className="animation__btn t6" style={{ marginTop: 16 }}>
                    <RequestBtn />
                  </div>
                </div>
              )}
            </form>

            <div className="adjust__btn">
              <div className="scroll-down-btn t7"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
