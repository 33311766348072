import React, {useEffect, useState} from 'react';
import statue from "../images/Statue.png";

import eric from "../images/Hear_from_columnist/eric.png";
import jackie from "../images/Hear_from_columnist/jackie.png";
import mark from "../images/Hear_from_columnist/mark.png";
import luke from "../images/Hear_from_columnist/luke.png";
import ayla from "../images/Hear_from_columnist/ayla.png";
import william from "../images/Hear_from_columnist/william.png";
import kevin from "../images/Hear_from_columnist/kevin.png";
import jun from "../images/Hear_from_columnist/jun.png";

import TrendingCarousel from '../Home/TrendingCarousel/TrendingCarousel';
import './DailyNews.css'

function DailyNews(){


    let date = new Date(); 
    let longMonth = date.toLocaleString('en-us', { month: 'long' }); /* June */
    var month = date.getUTCMonth() + 1;
    //var day = date.getUTCDate();
    var year = date.getUTCFullYear();

    var weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";
    var day = weekday[date.getDay()];

    var currentDate = day + ", " + longMonth + month +", " + year;

    const [columnist] = useState([
        {name: "Eric Weinstein", avatar: eric, position:"Managing director at Thiel Cap...", id: 1, },
        {name: "Jackie Jamison", avatar: jackie, position:"CEO at Wall Street Journal", id: 2, },
        {name: "Mark Benning", avatar: mark, position:"Journalist at Chicago Tribune", id: 3, },
        {name: "Luke Burgis", avatar: luke, position:"Medical Engineer at MIT", id: 4, },
        {name: "Ayla Sayaad", avatar: ayla, position:"CIO at Boston Dynamics", id: 5, },
        {name: "William Nate", avatar: william, position:"Designer at Kodak", id: 6, },
        {name: "Kevin Rodgers", avatar: kevin, position:"Managing partner at Apple", id: 7, },
        {name: "Jun Sang", avatar: jun, position:"Director at Paramount", id: 8, }
    ]);

    useEffect(() => {
     

       
      }, []);

  

    console.log(currentDate);
    return(
        <div className="news">
            <div className="wrapper__news">
                <div className="news__headline">
                    <p className="news__title"><span>Daily&nbsp; </span> Briefing</p>

                    <p className="news__subtitle">Today’s best news and information from Column/ all wrapped up in a daily briefing format for you.</p>
                </div>        
            </div>


            <div className="news__info">
                <div className="wrapper__news">

                    <div className="news__info__date">
                        <p>Date:&nbsp;<span>{currentDate}</span></p>

                    </div>

                    <div className="news__info__update">
                        <p>Updates at:&nbsp;<span> 6 am</span></p>
                        
                    </div>

                    <div className="news__info__weather">
                         <p>Weather:&nbsp;<span>Mostly cloudy</span></p>    
                    </div>

                </div>
            </div>

            <div className="news__content">

                <div className="news__content__container">

                    <div className="news__content__posts">

                    </div>

                    <div className="news__content__fedback">

                        <img className="feedback__statue" src={statue} alt="" />

                        <div className="news__content__fedback__container">
 
                            <p className="news__content__fedback__titile">Is this the best news & information you’ve seen on these topics?</p>

                            <p className="news__content__fedback__subtitile">At Column/ that is always our goal. </p>

                            <div className="feedback__btns">

                                <button className="feedback__btn__yes">    
                                    <span>Yes</span>
                                </button>

                                <button className="feedback__btn__no">    
                                    <span>No</span>
                                </button>

                            </div>

                        </div>

                    </div>
                    

                    <div className="news__hear__from__columnist__container">
                        <p className="news__hear__title">Hear from our Columnists</p>


                   <div className="news__scroll">

                    <div className="news__hear__from__columnist">
                        {columnist.map((columnist)=> (
                            <div className="columnist__holder" key={columnist.id}>

                                <div className={ "columnist c" + columnist.id} >
                                    <div className="columnist__avatar">
                                        <img src={columnist.avatar} alt="" />
                                    </div>
                                    <div className="columnist__info">
                                        <p className="columnist__name">{columnist.name}</p>
                                        <p className="columnist__position">{columnist.position}</p>
                                    </div>
                                </div>

                            </div>
                        ))}
                       
                       
                    </div>

                    </div>

                    <button className="news__hear__btn">
                        <span>Request Invite</span>
                    </button>

                    </div>

                </div>

            </div>

           
                <TrendingCarousel/>
        </div>
    );
}

export default DailyNews;