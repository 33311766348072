import './CreateC.css';
import React, { useState, useEffect } from 'react';
import {makeStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';


import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { createTheme, ThemeProvider } from '@material-ui/core/';

import Aos from 'aos';
import "aos/dist/aos.css";



const theme = createTheme({
  palette: {
      primary: {
          main: "#fefefe"
      }
  },
  overrides: {
      MuiInputBase: {
          root: {
             backgroundColor: "#FDFBF7"
           }
      },

      MuiInputLabel:{
         root: {
             color: "#999",
             fontSize: `16px`
         }
     },
     MuiAutocomplete: {
      listbox:{
          backgroundColor: "#F5F4F0",
          border: "1px solid #999",
          borderRadius: "4px",
          color: "#999",
          fontFamily: "Avenir"
      },
      option: {
        height: 52,
   
        '&:hover': {
          backgroundColor: "#3D8F4408",
        },
      },
     }
  }  
});



const useStyle = makeStyles( theme => ({
    c_field: {
        width: 312,
        height: 52,
        fontFamily: "Avenir",
        [theme.breakpoints.down('xs')]: {
           width: "100%"
        },
        [theme.breakpoints.up(theme.breakpoints.values.lg + 250)]: {
          width: 362 
        },
    },
    c_field_c: {
        width: 312,
        height: 58,
        fontFamily: "Avenir",
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        },
        [theme.breakpoints.up(theme.breakpoints.values.lg + 250)]: {
          width: 362 
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderWidth: 1,
                borderBottomColor: '#3D8F44',
                color: 'black',
                borderTopColor: '#BCBCB8',
                borderLeftColor: '#BCBCB8',
                borderRightColor: '#BCBCB8',
                transition: "0.3s ease"
            },
            "& .MuiOutlinedInput-root fieldset": {
              borderBottomColor: '#3D8F44',
            }
    },

     "& .MuiOutlinedInput-root fieldset": {
              borderBottomColor: 'black',
            },
    "& .MuiFormLabel-root": {
      fontSize: 16,
      fontFamily: "Avenir",
        "&.Mui-focused": {
           color: "#999999",
           fontSize: 16
        }  
    }
    },
    underline: {
      '&:after': {
        borderBottom: `2px solid black`,
      },
    },
    outlinedInputClass: {
      borderBottomColor: 'black !important',
    },
    focusedInputClass: {
      borderBottomColor: '#3D8F44 !important',
    },
    outlinedLabel: {
      color: '#70706E',

      '&.Mui-focused': {
        color: '#70706E',
      },
    },
    outlinedLabelFocused: {
      color: '#70706E',
    },
    labelShrink: {
      fontSize: 16,
    },
    rootLabel: {
      fontSize: 16,
      fontFamily: 'Avenir',
    },
    root: {
      fontSize: 16,
      fontFamily: 'Avenir',
      input: {
        padding: '16px 14px',
        height: 56,
        boxSizing: 'border-box',
        fontSize: 16
      },
      '& $notchedOutline': {
        borderWidth: 1,
        borderBottomColor: 'black',
      },
      '&:hover $notchedOutline': {
        borderWidth: 1,
        borderColor: 'black',
         transition: "0.3s ease"
      },
      '&$focused $notchedOutline': {
        borderWidth: 1,
        borderBottomColor: '#3D8F44',
        color: 'black',
        borderTopColor: '#BCBCB8',
        borderLeftColor: '#BCBCB8',
        borderRightColor: '#BCBCB8',
        transition: "0.3s ease"
      },
    },
    focused: {},
    notchedOutline: {},
    MuiOutlinedInput: {
        Mui: {
            focused: {
                border: "red"
            }
        }
    }
}));


const source_options = [
  { title: 'Twitter Followers'},
  { title: 'Linked In Followers'},
  { title: 'Instagram Followers'},
  { title: 'Email List'},
  { title: 'Newsletter Subscribers'},
  { title: "Facebook Group"},
  { title: 'Offline Group'}
   ];


   const expertise_options = [
    { title: 'accounting'},
    { title: 'actuary'},
    { title: 'aerospace engineering'},
    { title: 'AI-first products EIR'},
    { title: 'AI/ML'},
    { title: "alternative energy"},
    { title: 'ancient philosophy'},
    { title: 'animal sciences'},
    { title: 'anthropology'},
    { title: 'API Marketing'},
    { title: 'applied mathematics'},
    { title: 'Apprentice'},
    { title: 'architecture'},
    { title: 'art'},
    { title: 'art collecting'},
    { title: "AR/VR"},
    { title: 'astronomy'},
    { title: 'astrophysics'},
    { title: 'atmospheric science'},
    { title: 'atoms'},
    { title: 'attention'},
    { title: 'biochemistry'},
    { title: 'bioengineering'},
    { title: "biology"},
    { title: 'biophysics'},
    { title: 'biotech'},
    { title: 'Bitcoin'},
    { title: 'blockchain'},
    { title: 'Brain-computer interfaces, software'},
    { title: 'Branding'},
    { title: 'building community software'},
    { title: 'business'},
    { title: 'business development'},
    { title: 'cancer'},
    { title: 'chemical engineering'},
    { title: 'chemistry'},
    { title: 'civil engineering'},
    { title: 'CLIMATE TECHNOLOGY'},
    { title: 'code'},
    { title: 'comedy'},
    { title: 'commercial real estate'},
    { title: 'communications'},
    { title: 'community building'},
    { title: 'conscious experience'},
    { title: 'consciousness'},
    { title: 'consumer marketplace'},
    { title: 'content marketing'},
    { title: 'copywriting'},
    { title: 'COViD-19'},
    { title: 'creative direction'},
    { title: 'trend forecasting'},
    { title: 'Creative technology'},
    { title: 'creator economy'},
    { title: 'cricket'},
    { title: 'cryptocurrencies'},
    { title: 'customer service'},
    { title: 'CX'},
    { title: 'cybersecurity'},
    { title: 'data engineering'},
    { title: 'data science'},
    { title: 'machine learning'},
    { title: 'de'},
    { title: 'decentralized networks'},
    { title: 'defi'},
    { title: 'design'},
    { title: 'due diligence best practices'},
    { title: 'early childhood education'},
    { title: 'early-stage investing'},
    { title: 'earth science'},
    { title: 'ecology'},
    { title: 'e-commerce'},
    { title: 'economics'},
    { title: 'ed tech'},
    { title: 'education'},
  ];



function CreateC() {

    const classes = useStyle();
 



    const [communityInputList, setCommunityInputList] = useState([{ com_number: "", com_source: "", com_link: ""}]);
 
    // handle input change
    const handleInputChangeCommunity = (e, index) => {
      const { name, value } = e.target;
      const list = [...communityInputList];
      list[index][name] = value;
      setCommunityInputList(list);
    };
   
    // handle click event of the Remove button
    const handleRemoveClickCommunity = index => {
      const list = [...communityInputList];
      list.splice(index, 1);
      setCommunityInputList(list);
    };
   
    // handle click event of the Add button
    const handleAddClickCommunity = () => {
      if(communityInputList.length > 4) {
        return;
      }
      else{
        setCommunityInputList([...communityInputList, { com_number: "", com_source: "",  com_link: "" }]);
      }
    };








    const [subscriptionPlan, setSubscriptionPlan] = useState("free");


   

    const [values, setValues] = useState({
        firstName: '',
        email: '',
        username: '',
        expertiseList: '',
        column_title: '',
        column_purpose: '',
        column_subscription: 'free',
        column_cost: ''
    });




    const handleTextChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value });
    };

    

    //console.log(JSON.stringify(values));
    
    //console.log(values.coFounders_name[0].name_1);


    const [coFounderInputList, setCoFounderInputList] = useState([{ co_f_firstName: "", co_f_lastName: "", co_f_link: ""}]);
 
    // handle input change
    const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...coFounderInputList];
      list[index][name] = value;
      setCoFounderInputList(list);
    };
   
    // handle click event of the Remove button
    const handleRemoveClick = index => {
      const list = [...coFounderInputList];
      list.splice(index, 1);
      setCoFounderInputList(list);
     
    };
   
    // handle click event of the Add button
    const handleAddClick = () => {
      if(coFounderInputList.length > 4) {
        return;
      }
      else{
        setCoFounderInputList([...coFounderInputList, { co_f_firstName: "", co_f_lastName: "",  co_f_link: "" }]);
      
      }

     
     
    };

    const [basicGood, setBasicGood] = useState(false)
    const [cofGood, setCofGood] = useState(false)
    const [comGood, setComGood] = useState(false)


    let handleValidation = () => {

   
      if( values.firstName !== "" && values.email !== ""  && values.email.indexOf('@') !== -1  && values.column_title !== "" && values.column_purpose !== "" && values.column_subscription === "free" && values.username !== "" && values.expertiseList !== "" )     
        {
         
           setBasicGood(true);
       }

       else if( values.firstName !== "" && values.email !== ""  && values.email.indexOf('@') !== -1  && values.column_title !== "" && values.column_purpose !== "" && values.column_subscription === "subscription" && values.column_cost !=="" )   {
        setBasicGood(true);
          console.log("");
       }

       else {
        setBasicGood(false);
       }

       for (let i = 0; i < coFounderInputList.length; i++) {

          if( coFounderInputList[i].co_f_firstName !== "" && coFounderInputList[i].co_f_lastName !== "" && coFounderInputList[i].co_f_link !== ""
            ) {   
                setCofGood(true)
          }

          else {
            setCofGood(false)
          }


        
      }


      for (let i = 0; i < communityInputList.length; i++) {

        if( communityInputList[i].com_number !== "" && communityInputList[i].com_source !== "" && communityInputList[i].com_link !== ""
          ) {   
              //console.log(communityInputList.length)
              //console.log("coom dobra")
              setComGood(true)
        }

        else {
          setComGood(false)
          //console.log("comm losa")
        }

        //console.log(comGood)
      
    }
  
    }

    useEffect(() => {
      Aos.init({duration:700, once:"true"});
      handleValidation();
  },[values, coFounderInputList, communityInputList, handleAddClick]);

 


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(JSON.stringify(values));
    console.log(JSON.stringify(communityInputList));
    console.log(JSON.stringify(coFounderInputList));
};




    return (
      <ThemeProvider theme={theme}>
      
        <div className="createC">
           
            <div className="createC__background">

            </div>

            <div className="cc__div__arched">
                <div className="cc__wrapper__arched">

                    <div className="createC__headline">
                        <p className="createC__title c_a_1">Create Column</p>

                        <p className="createC__main__subtitle c_a_2">A Column is a “Substack for community” or a “Nightclub 
                            for high-quality information” because groups of specific 
                            people usually have better information than any one person. 
                            They are similar to an asynchronous conference or a party, 
                            but they must be started by a leader or founding team.
                        </p>
                    </div>

                    <div className="createC__forms">
                        <form action=" noValidate" autoComplete="off" onSubmit={handleSubmit}>
                        <div className="createC__forms__intro">

                        </div>
                        <p className="createC__subtitle intro__title c_a_3">Intro</p>

                        <div className="c_main_inputs c_a_4">

                            <TextField
                                className={classes.c_field}
                                variant="outlined"
                                label="Full Name"
                                onChange={handleTextChange('firstName')}

                                InputProps={{
                                    classes: {
                                      root: classes.root,
                                      focused: classes.focused,
                                      notchedOutline: classes.notchedOutline,
                                    },
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.rootLabel,
                                      outlined: classes.outlinedLabel,
                                    },
                                  }}

                            />

                            <TextField
                                className={classes.c_field}
                                variant="outlined"
                                label="Email"
                                onChange={handleTextChange('email')}

                                InputProps={{
                                    classes: {
                                      root: classes.root,
                                      focused: classes.focused,
                                      notchedOutline: classes.notchedOutline,
                                    },
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.rootLabel,
                                      outlined: classes.outlinedLabel,
                                    },
                                  }}
                        />

                        </div>

                        <div className="c_main_inputs_second c_a_5">

                        <TextField
                                className={classes.c_field}
                                variant="outlined"
                                label="Username"
                                onChange={handleTextChange('username')}

                                InputProps={{
                                    classes: {
                                      root: classes.root,
                                      focused: classes.focused,
                                      notchedOutline: classes.notchedOutline,
                                    },
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.rootLabel,
                                      outlined: classes.outlinedLabel,
                                    },
                                  }}

                            />

                                <Autocomplete
                                    freeSolo
                                    id="free-solo-demo"
                                    disableClearable
                                    defaultValue={""}
                                    options={expertise_options.map((option) => option.title)}   
                                    onSelect={handleTextChange('expertiseList')}
                                
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className={classes.c_field_c}
                                        onChange={handleTextChange('expertiseList')}
                                        label="Your expertise"
                                        variant="outlined"
                                        //error={expertiseError}
                                        InputProps={{ ...params.InputProps, type: 'option', classes: {
                                            root: classes.root,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline,
                                          },} }

                                          InputLabelProps={{
                                            classes: {
                                            
                                              outlined: classes.outlinedLabel,
                                            },
                                          }}
                                               
                                    />
                                        )}                            
                                />

                        </div>

                        <p className="createC__subtitle community__title" data-aos="fade-up" data-aos-delay="200">Community</p>

                        <p className="community__subtitle" data-aos="fade-up" data-aos-delay="200">What is the size of the community that you can launch your Column into?</p>



                        {communityInputList.map((x, i) => {
                              return (
                                <div className="co-founder" key={i}>


                                <div className="community__source">

                                  <TextField
                                      data-aos="fade-up" data-aos-delay="200"
                                      className={classes.c_field}
                                      variant="outlined"
                                      label="Number of Followers"
                                      name="com_number"
                                      value={x.com_number}
                                      onChange={e => handleInputChangeCommunity(e, i)}
                                    
                                      InputProps={{
                                          classes: {
                                            root: classes.root,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline,
                                          },
                                        }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.rootLabel,
                                            outlined: classes.outlinedLabel,
                                          },
                                        }}
                                  />

                                   
                                      <div className="source_animation"   data-aos="fade-up" data-aos-delay="300">
                                      <Autocomplete
                                       
                                        freeSolo
                                        id="free-solo-demo"
                                        defaultValue={""}
                                        disableClearable
                                        options={source_options.map((option) => option.title)} 
                                        onSelect={e => handleInputChangeCommunity(e, i)}  
                                                                                 
                                        renderInput={(params) => (
                                      <TextField
                                          {...params}
                                          className={classes.c_field_c}
                                          label="Source"
                                          variant="outlined"
                                          name="com_source"
                                          value={x.com_source}
                                          onChange={e => handleInputChangeCommunity(e, i)}  
                                          //error={expertiseError}

                                          InputProps={{ ...params.InputProps, type: 'option', classes: {
                                              root: classes.root,
                                              focused: classes.focused,
                                              notchedOutline: classes.notchedOutline,
                                            },} }

                                            InputLabelProps={{
                                              classes: {
                                              
                                                outlined: classes.outlinedLabel,
                                              },
                                            }}
                        
                                              
                                          />
                                              )}                            
                                      />

                                      </div>
                                     



                                  </div>

                                  <div className="community__link">

                                    <TextField
                                     data-aos="fade-up" data-aos-delay={window.innerWidth < 731 ? "300" : "200"}
                                      className={classes.c_field}
                                      variant="outlined"
                                      name="com_link"
                                      label="Link"
                                      value={x.com_link}
                                      onChange={e => handleInputChangeCommunity(e, i)}

                                      InputProps={{
                                          classes: {
                                            root: classes.root,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline,
                                          },
                                        }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.rootLabel,
                                            outlined: classes.outlinedLabel,
                                          },
                                        }}
                                    />

                                      {communityInputList.length !== 1 && 

                                        <div className="aos_remove" data-aos="fade-up" data-aos-delay="400">
                              
                                          <div className="remove-field" onClick={() => handleRemoveClickCommunity(i)}>
                                              <p>Remove</p>
                                          </div> 

                                        </div>
                                      
                                      }

                                </div>
                                </div>
                              );
                            })}

                            {
                             
                             /*
                             
                                       <div style={{ marginTop: 20 }}>{JSON.stringify(communityInputList)}</div>
                            
                                       */
                              
                            }
                            


                      
                            {communityInputList.length <= 4 &&  
                            
                            <div data-aos="fade-up" data-aos-delay="200"> 
                    
                         <div className="add__another__community" onClick={handleAddClickCommunity}  >

                            <div className="add__another__community__inner">
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 10L5 5M5 2.18557e-07L5 5M5 5L10 5M5 5L-2.18557e-07 5" stroke="#3D8F44" strokeWidth="1.5"/>
                                </svg>

                                <p>Add Another</p>
                            </div>

                        </div>

                        </div>

                        }                 

                        <p className="createC__subtitle co-founders__title"  data-aos="fade-up" data-aos-delay="200">Co-Founders</p>

                        <p className="community__subtitle"  data-aos="fade-up" data-aos-delay="200">You can also add co-founders to your Column. Add co-founders here.</p>




                     
                            {coFounderInputList.map((x, i) => {
                              return (
                                <div className="co-founder" key={i}>


                                <div className="co-founder__info">

                                  <TextField
                                   data-aos="fade-up" data-aos-delay="200"
                                      className={classes.c_field}
                                      variant="outlined"
                                      label="Full Name"
                                      name="co_f_firstName"
                                      value={x.co_f_firstName}
                                      onChange={e => handleInputChange(e, i)}
                                    
                                      InputProps={{
                                          classes: {
                                            root: classes.root,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline,
                                          },
                                        }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.rootLabel,
                                            outlined: classes.outlinedLabel,
                                          },
                                        }}
                                  />

                                  <TextField
                                   data-aos="fade-up" data-aos-delay="300"
                                      className={classes.c_field}
                                      name="co_f_lastName"
                                      variant="outlined"
                                      label="Title or Position"
                                      value={x.co_f_lastName}
                                      onChange={e => handleInputChange(e, i)}

                                      InputProps={{
                                          classes: {
                                            root: classes.root,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline,
                                          },
                                        }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.rootLabel,
                                            outlined: classes.outlinedLabel,
                                          },
                                        }}
                                  />

                                  </div>

                                  <div className="co-founder__link">

                                    <TextField
                                     data-aos="fade-up" data-aos-delay={window.innerWidth < 731 ? "300" : "200"}
                                      className={classes.c_field}
                                      variant="outlined"
                                      name="co_f_link"
                                      label="Link"
                                     
                                      value={x.co_f_link}
                                      onChange={e => handleInputChange(e, i)}

                                      InputProps={{
                                          classes: {
                                            root: classes.root,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline,
                                          },
                                        }}
                                        InputLabelProps={{
                                          classes: {
                                            root: classes.rootLabel,
                                            outlined: classes.outlinedLabel,
                                          },
                                        }}
                                    />

                                      {coFounderInputList.length !== 1 && 

                                      <div className="aos_remove" data-aos="fade-up" data-aos-delay="400">
                              
                            
                                        <div className="remove-field" onClick={() => handleRemoveClick(i)}>
                                            <p>Remove</p>
                                        </div> 

                                        </div>
                                      
                                      }

                                </div>
                                </div>
                              );
                            })}

                            {
                             
                             /*
                             
                                       <div style={{ marginTop: 20 }}>{JSON.stringify(coFounderInputList)}</div>
                            */
                              
                            }
                            
                           

                     

                          {coFounderInputList.length <= 4 && 

                        <div data-aos="fade-up" data-aos-delay="200"> 

                        <div className="add__another__community" onClick={handleAddClick}>
                            
                            <div className="add__another__community__inner">
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 10L5 5M5 2.18557e-07L5 5M5 5L10 5M5 5L-2.18557e-07 5" stroke="#3D8F44" strokeWidth="1.5"/>
                                </svg>

                                <p>Add Another</p>
                            </div>

                        </div>
                         </div>
                         }

                        <p className="createC__subtitle column-information__title"  data-aos="fade-up" data-aos-delay="200">Column Information</p>

                        <div className="column__information">

                             <TextField
                              data-aos="fade-up" data-aos-delay="200"
                                className={classes.c_field}
                                variant="outlined"
                                label="Column Title"
                                onChange={handleTextChange('column_title')}

                                InputProps={{
                                    classes: {
                                      root: classes.root,
                                      focused: classes.focused,
                                      notchedOutline: classes.notchedOutline,
                                    },
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.rootLabel,
                                      outlined: classes.outlinedLabel,
                                    },
                                  }}
                            />

                            <TextField
                             data-aos="fade-up" data-aos-delay="300"
                                className={classes.c_field}
                                variant="outlined"
                                label="Column Purpose"
                                onChange={handleTextChange('column_purpose')}

                                InputProps={{
                                    classes: {
                                      root: classes.root,
                                      focused: classes.focused,
                                      notchedOutline: classes.notchedOutline,
                                    },
                                  }}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.rootLabel,
                                      outlined: classes.outlinedLabel,
                                    },
                                  }}
                            />

                        </div>

                        <div className="plan__container">

                            <div className="createC__plan">

                                <label className="container plan__radio"  data-aos="fade-up" data-aos-delay="200">Free
                                    <input
                                     type="radio"
                                     name="radio" 
                                     value="free" 
                                     defaultChecked
                                     onChange={handleTextChange('column_subscription')}
                                     
                                     />
                                    <span className="checkmark__inside"></span>
                                    <span className="checkmark">  
                                    </span>
                                </label>

                                <label className="container plan__radio"  data-aos="fade-up" data-aos-delay="300">Subscription
                                    <input type="radio"
                                     name="radio" 
                                     value="subscription" 
                                     onChange={handleTextChange('column_subscription')}
                                     
                                     
                                     />
                                    <span className="checkmark__inside"></span>
                                    <span className="checkmark">  
                                    </span>
                                </label>

                            </div>

                            <div className="plan__price">

                                {values.column_subscription === "subscription" ?

                                <FormControl key={subscriptionPlan}
                                className={classes.c_field_c}
                                data-aos="fade-up"
                                data-aos-delay="0"
                                data-aos-duration="500"

                               
                                 variant="outlined"

                                >
                                <InputLabel  htmlFor="outlined-adornment-amount" >Cost per month</InputLabel>
                                <OutlinedInput

                                    id="outlined-adornment-amount"
                                    // value={values.amount}
                                    //onChange={handleChange('amount')}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    defaultValue=""
                                  
                                    labelWidth={128}
                                    className={classes.c_field_c}
                                    onChange={handleTextChange('column_cost')}
                                    
                                />
                                </FormControl> 
                                :
                                null
                                
                            }

                            </div>

                        </div>
                          
                          <div  data-aos="fade-up" data-aos-delay="200">

                            <div className={basicGood && cofGood && comGood ? "createC__submit validate" : "createC__submit not_validate"} >
                            
                           

                                <button type="submit" className={basicGood && cofGood && comGood ? "createC__submit__button validate" : "createC__submit__button not_validate"}>
                                    Submit
                                </button>
                               
                            </div>

                        </div>


                     
                       

                        </form>
                    </div>

                </div>
            </div>

          

        </div>
        </ThemeProvider>
    );
}

export default CreateC;