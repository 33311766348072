import { gql } from '@apollo/client'

export const EXPERTISE_LIST = gql`
  query Expertises {
    getExpertises(page: 1, limit: 1000) {
      list {
        name
      }
      count
    }
  }
`
