import './Constitution.css';
import React from 'react';
import GoBack from '../GoBack/GoBack';

function Constitution() {
    return(
        <div className="footerLegal-container__constitution">

            {
            window.innerWidth > 1200 && <GoBack />
            }

            <div className="wrapper__constitution">

                <div className="footerLegal__headline__constitution">
                    <p className="footerLegal__title__constitution">Community Guidelines</p>
                    <p className="footerLegal__effective-date__constitution">Effective date: <span>May 25, 2021</span></p> 
                </div>

                <div className="footerLegal__content">

                        <p className="footerLegal__paragraph__constitution">
                            Welcome to Column! Column strives to provide a safe place for inspiration, 
                            expression and discourse. We recognize how important it is for Column to 
                            empower our community to communicate while ensuring each member of our community 
                            remains assured their voice is respected and safe from harmful messages and abusive 
                            practices. Please read on for more about our values, how they apply to our community, 
                            and what you can and cannot do when using Column. For more information about using the 
                            Column services and platform (the “Services”), please visit our Terms of Service
                        </p>

                        <p className="sections__title__constitution">Inclusivity</p>

                        <p className="footerLegal__paragraph__constitution">
                            Column is an inclusive platform that welcomes all 
                            members to engage in positive, constructive behavior. Our community represents diverse peoples, 
                            cultures, genders, races, ages, backgrounds and experiences. Building community and bringing the 
                            world closer together is one of our primary goals and largely depends on people’sability to share 
                            diverse views, experiences, ideas and information with one another in a safe, welcoming and supportive 
                            space. As such, we ask that our members respect one another and refrain from taking any actions ormaking 
                            any comments that makes others feel uncomfortable, unwelcome, or unsafe. We will not tolerate any hate 
                            speech or the discrimination of or against or the promotion of hatred or violence against others on the 
                            basis of race, ethnicity, national origin, religious affiliation, gender identity, sexual orientation, age, 
                            disability or disease. We also strictly prohibit content that depicts real people and ridicules them or their 
                            disability or other serious
                            situation; contains sadistic remarks and any visual or written depiction of real people experiencing 
                            premature death, serious physical injury, physical violence or domestic violence; or furnishes imagery 
                            that depicts real animals experiencing physical injury, including mutilation or physical violence.
                        </p>

                        <p className="sections__title__constitution"> Safety</p>

                        <p className="footerLegal__paragraph__constitution">
                            Column strictly forbids and has zero tolerance for hate, 
                            harassment, bullying, threats or glorification of violence 
                            on its Services. We do not permit horrifying, violent, or 
                            distressing content, including animal abuse. We also prohibit 
                            content that threatens to cause physical, emotional, or financial 
                            harm towards others; degrades, shames, or in any other way harasses 
                            others or blackmail others. Violations of this policy include, but are not 
                            limited to threatening to kill, sexually assault, physically harm or hurt and/or 
                            commit other violent acts that could lead to someone’s death or serious physical 
                            injury. This also includes asking for or offering a financial reward in exchange 
                            for inflicting violence on a specific person or group of people. No person may 
                            post media that is excessively gory or share violent or adult content.
                        </p>

                        <p className="footerLegal__paragraph__constitution">
                            We have zero tolerance for child sexual exploitation on Column. This includes media, text, 
                            illustrated, or computer-generated images. This includes, regardless of the intent, viewing, 
                            sharing, or linking to child sexual exploitation. This also applies to content that may further 
                            contribute to victimization of children through the promotion or glorification of child sexual 
                            exploitation. For the purposes of these Community Guidelines, a minor is any person under the age of 18. 
                            Media depicting sexual violence and/or assault is not permitted. Column will remove content that depicts, 
                            threatens or promotes sexual violence, sexual assault, or sexual exploitation; that displays, advocates 
                            for, or coordinates sexual acts with non-consenting parties or commercial sexual services, such as 
                            prostitution and escort services; and that Column deems may facilitate transactions that may involve 
                            trafficking, coercion, and non-consensual sexual acts.        
                        </p>

                        <p className="footerLegal__paragraph__constitution">
                            Column does not allow content that promotes harming oneself or others, 
                            including content that encourages dangerous behavior, such as reckless 
                            challenges or dares. We will not tolerate content that glorifies harmful 
                            behavior, such as eating disorders, suicide, cutting, or other self-harm. 
                            Violations of this policy include, but are not limited to, encouraging someone 
                            to physically harm or kill themselves; asking others for encouragement to engage 
                            in self-harm or suicide, including seeking partners for group suicides or suicide 
                            games; and sharing information, strategies, methods or instructions that would assist 
                            people to engage in self-harm and suicide. If you or someone you know is considering 
                            self-harm or suicide, please know there are resources and support available at national 
                            crisis centers such as the National Suicide Prevention Lifeline.
                        </p>

                        <p className="sections__title__constitution">Privacy</p>

                        <p className="footerLegal__paragraph__constitution">
                            Column prohibits any member from publishing or posting any other person’s private, 
                            confidential or proprietary information without such person’s express authorization 
                            and consent. We do not allow any posts or publications that share intimate photos or 
                            videos without the express authorization and consent of the subject of such photos or 
                            videos. We also prohibit any posts that contain personally identifiable information, 
                            including national identification numbers, social security numbers, passport numbers, 
                            exam numbers, government identification numbers or digital identities.
                        </p>

                        <p className="sections__title__constitution">Authenticity</p>

                        <p className="footerLegal__paragraph__constitution">
                            Column aims to provide its members with the tools to create content unique and 
                            special to each member. As such, Column prohibits all persons from posting content 
                            that does not rightfully belong to them. We also prohibit content that is copyrighted or 
                            trademarked by a third party to be posted on the Services without such third party’s express 
                            authorization and consent. Please refer to our Terms of Service to learn more. 
                        </p>

                        <p className="footerLegal__paragraph__constitution">
                            Column strives to connect its members to foster community and discussions. 
                            Therefore, Column does not allow spam, misleading content, phishing attempts, 
                            or commercial solicitation without a party’s express authorization and consent. 
                            We also prohibit the impersonation of individuals, groups, or organizations in a 
                            manner that is intended to or does mislead, confuse, or deceive others. We also do 
                            not allow people to deceptively share synthetic or manipulated media that are likely 
                            to cause harm. Using scam tactics on Column to obtain money or private financial 
                            information is prohibited under these Community Guidelines. You are not allowed to 
                            create accounts, post columns, or send direct messages that solicit engagement in such 
                            fraudulent schemes. Examples of prohibited, deceptive tactics include relationship/trust-building scams, 
                            money-flipping schemes, fraudulent discounts and phishing scams. You may not pose as or imply affiliation 
                            with banks or other financial institutions to acquire others’ personal financial information. 
                        </p>

                        <p className="sections__title__constitution">Illegal Activities</p>

                        <p className="footerLegal__paragraph__constitution">
                            Column prohibits all persons from using the Services for any unlawful purpose 
                            or in furtherance of illegal activities, including maintaining a presence on 
                            Column to promote any such campaigns, plans, fundraisers, recruitment activities or 
                            celebrations of illegal/violent acts. We also do not allow the sale or purchase of or other 
                            transactions in illegal goods or services, as well as certain types of regulated goods or services. 
                            For example, Column prohibits content that includes attempts to buy, sell, trade, donate, gift or 
                            solicit firearms, firearm parts, ammunition, explosives, or lethal enhancements between private individuals,
                             non-medical drugs, endangered species or their parts, human blood or content about weight loss that contains 
                             a miracle claim or weight loss products or supplements.
                        </p>

                        <p className="sections__title__constitution">Laws</p>

                        <p className="footerLegal__paragraph__constitution">
                            As a member of the Column community, we require you to adhere to the laws when engaging 
                            with others on our Services. Please follow all applicable laws when using Column and do 
                            not engage in unlawful or illegal activities. When in doubt, please contact Column at legal@columnhq.com.
                        </p>

                        <p className="sections__title__constitution">Violations</p>

                        <p className="footerLegal__paragraph__constitution">
                            Each of us is an important part of the Column community. If you see something that you 
                            think may violate our guidelines, please let us know. You can report these violations at 
                            legal@columnhq.com. In your email to us, please include specifics such as links, usernames, 
                            screenshots, and descriptions of the content. We will make every effort to review and act upon 
                            these reports in a timely manner.
                        </p>

                        <p className="footerLegal__paragraph__constitution">
                            From time to time, you may encounter content on our Services that you do not agree with 
                            but that does not violate our guidelines. In these cases, we encourage you to unfollow the user 
                            who created the content. If there is  something you don't like in a comment on one of your posts, 
                            you can delete that comment.
                            Many disputes and misunderstandings can be resolved directly between members of the community. 
                            For example, if one of your photos or videos was posted by someone else, you could comment on the  
                            post and ask the person to take it down. If you still experience issues, please contact us immediately at 
                            legal @columnhq.com.
                        </p>

                        <p className="footerLegal__paragraph__constitution">
                             If your content violates our Community Guidelines and Terms of Service, we will remove it. 
                             If you repeatedly post content or engage in behavior that violates our Community Guidelines 
                             and Terms of Service, we will remove your account and ban you from our Services. In some extreme 
                             cases, we may work with law enforcement, including when we believe that there is risk of physical 
                             harm or threat to public safety.         
                        </p>

                        <p className="sections__title__constitution">Thank You</p>

                        <p className="footerLegal__paragraph__constitution">
                            Our Community Guidelines were crafted with great care and the recognition that 
                            community voices are important and valued. We truly appreciate your continued observance of 
                            these Community Guidelines so we can continue to keep Column a safe and inviting space for all
                        </p>

                </div>

            </div>

        </div>
    );
}

export default Constitution;