import React  from 'react';
import ReactDOM from 'react-dom';
import './Fonts/Avenir/webFonts/AvenirLTProLightOblique/font.woff2';
import './Fonts/Avenir/webFonts/AvenirLTProBook/font.woff2';
import './Fonts/Avenir/webFonts/AvenirLTProRoman/font.woff2';
import './Fonts/Avenir/webFonts/AvenirLTProBookOblique/font.woff2';
import './Fonts/Avenir/webFonts/AvenirLTProLight/font.woff2';
import './Fonts/Avenir/webFonts/AvenirLTProBlackOblique/font.woff2';
import './Fonts/Avenir/webFonts/AvenirLTProHeavyOblique/font.woff2';
import './Fonts/Avenir/webFonts/AvenirLTProHeavy/font.woff2';
import './Fonts/Avenir/webFonts/AvenirLTProBlack/font.woff2';
import './Fonts/Avenir/webFonts/AvenirLTProMediumOblique/font.woff2';
import './Fonts/Avenir/webFonts/AvenirLTProOblique/font.woff2';
import './Fonts/Avenir/webFonts/AvenirLTProMedium/font.woff2';
import './Fonts/Schnyder/SchnyderXL-BoldItalic-Web.woff';
import './Fonts/Schnyder/SchnyderXL-BoldItalic-Web.woff2';

import './Fonts/Schnyder/SchnyderXL-Bold-Web.woff2';
import './Fonts/Schnyder/SchnyderXL-DemiItalic-Web.woff2';
import './Fonts/Schnyder/SchnyderXL-Demi-Web.woff2';
import './Fonts/Schnyder/SchnyderXL-LightItalic-Web.woff2';
import './Fonts/Schnyder/SchnyderXL-Light-Web.woff2';
import './Fonts/Schnyder/SchnyderL-Bold-Web.woff2';
import './Fonts/Schnyder/SchnyderL-DemiItalic-Web.woff2';
import './Fonts/Schnyder/SchnyderL-Demi-Web.woff2';
import './Fonts/Schnyder/SchnyderL-LightItalic-Web.woff2';
import './Fonts/Schnyder/SchnyderL-Light-Web.woff2';
import './Fonts/Schnyder/SchnyderM-BoldItalic-Web.woff2';
import './Fonts/Schnyder/SchnyderM-Bold-Web.woff2';
import './Fonts/Schnyder/SchnyderM-DemiItalic-Web.woff2';
import './Fonts/Schnyder/SchnyderM-Demi-Web.woff2';
import './Fonts/Schnyder/SchnyderM-LightItalic-Web.woff2';
import './Fonts/Schnyder/SchnyderM-Light-Web.woff2';
import './Fonts/Schnyder/SchnyderS-BoldItalic-Web.woff2';
import './Fonts/Schnyder/SchnyderS-Bold-Web.woff2';
import './Fonts/Schnyder/SchnyderS-DemiItalic-Web.woff2';
import './Fonts/Schnyder/SchnyderS-Demi-Web.woff2';
import './Fonts/Schnyder/SchnyderS-LightItalic-Web.woff2';
import './Fonts/Schnyder/SchnyderS-Light-Web.woff2';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';




ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
