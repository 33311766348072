import React, {useState, useEffect} from 'react';


import { Link} from 'react-router-dom';
import './Calculator.css';

function Calculator() {


      
  
    


    useEffect(() => {

       
        }, [])

    const [isActive, setIsActive] = useState("");

    
    const [values, setValues] = useState({
        ColumnName: '',
        InitialInvite: '',
        SubscriptionsPercent: '',
        CostPerMonth: '',
        Annual: '',
        NumberOfInviteCycles: '',
        NumberOfInvitesLow: '',
        NumberOfInvitesHigh: '',
        LaunchPartnership: ''
    });


    const handleTextChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value });
    };

    function handleGenerate(e) {

        e.preventDefault();
        console.log(values.InitialInvite);

       

    }

    return(
        <div className="calculator">    

            <div className="wrapper">
                <p className="calculator__title">How much ARR could your COLUMN/ generate?</p>

                <form >

                <div className="calculator__container">
                  
                    <div className="calculator__inputs__left">

                        <div className="request__input calculator__input ColumnName"
                                 onFocus={()=> setIsActive("ColumnName")}
                                 onBlur={()=> values.firstName !== null ? setIsActive("") : ""}>
                            
                                <label className={ isActive === "ColumnName" || values.ColumnName !== "" ? "label Active" : "label"} htmlFor="">Column Name</label>
                                
                                <input 
                                    className="input firstName" 
                                    value={values.firsttName}
                                    onChange={handleTextChange('ColumnName')}
                                    type="text"
                                />

                                <fieldset className="fieldset">
                                    <legend className={ isActive === "ColumnName" || values.ColumnName !== "" ? "legend Active" : "legend"}>
                                    </legend>
                                </fieldset>   
                           </div>

                           <div className="request__input calculator__input InitialInvite"
                                 onFocus={()=> setIsActive("InitialInvite")}
                                 onBlur={()=> values.firstName !== null ? setIsActive("") : ""}>
                            
                                <label className={ isActive === "InitialInvite" || values.InitialInvite !== "" ? "label Active" : "label"} htmlFor="">Initial Invite List Size</label>
                                
                                <input 
                                    className="input firstName" 
                                    value={values.firsttName}
                                    onChange={handleTextChange('InitialInvite')}
                                    type="text"
                                />

                                <fieldset className="fieldset">
                                    <legend className={ isActive === "InitialInvite" || values.InitialInvite !== "" ? "legend Active" : "legend"}>
                                    </legend>
                                </fieldset>   
                           </div>

                           <div className="request__input calculator__input SubscriptionsPercent"
                                 onFocus={()=> setIsActive("SubscriptionsPercent")}
                                 onBlur={()=> values.firstName !== null ? setIsActive("") : ""}>
                            
                                <label className={ isActive === "SubscriptionsPercent" || values.SubscriptionsPercent !== "" ? "label Active" : "label"} htmlFor="">% of Subscriptions from Invitation</label>
                                
                                <input 
                                    className="input firstName" 
                                    value={values.firsttName}
                                    onChange={handleTextChange('SubscriptionsPercent')}
                                    type="text"
                                />

                                <fieldset className="fieldset">
                                    <legend className={ isActive === "SubscriptionsPercent" || values.SubscriptionsPercent !== "" ? "legend Active" : "legend"}>
                                    </legend>
                                </fieldset>   
                           </div>

                           <div className="request__input calculator__input CostPerMonth"
                                 onFocus={()=> setIsActive("CostPerMonth")}
                                 onBlur={()=> values.firstName !== null ? setIsActive("") : ""}>
                            
                                <label className={ isActive === "CostPerMonth" || values.CostPerMonth !== "" ? "label Active" : "label"} htmlFor="">Cost per Month</label>
                                
                                <input 
                                    className="input firstName" 
                                    value={values.firsttName}
                                    onChange={handleTextChange('CostPerMonth')}
                                    type="text"
                                />

                                <fieldset className="fieldset">
                                    <legend className={ isActive === "CostPerMonth" || values.CostPerMonth !== "" ? "legend Active" : "legend"}>
                                    </legend>
                                </fieldset>   
                           </div>

                    </div>

                    <div className="calculator__inputs__right">

                            <div className="request__input calculator__input Annual"
                                 onFocus={()=> setIsActive("Annual")}
                                 onBlur={()=> values.firstName !== null ? setIsActive("") : ""}>
                            
                                <label className={ isActive === "Annual" || values.Annual !== "" ? "label Active" : "label"} htmlFor="">Annual churn &nbsp; <Link to="" className="annual__link"> See industry comps</Link></label>
                                
                                <input 
                                    className="input firstName" 
                                    value={values.firsttName}
                                    onChange={handleTextChange('Annual')}
                                    type="text"
                                />

                                <fieldset className="fieldset">
                                    <legend className={ isActive === "Annual" || values.Annual !== "" ? "legend Active" : "legend"}>
                                    </legend>
                                </fieldset>   
                           </div>

                           <div className="request__input calculator__input NumberOfInviteCycles"
                                 onFocus={()=> setIsActive("NumberOfInviteCycles")}
                                 onBlur={()=> values.firstName !== null ? setIsActive("") : ""}>
                            
                                <label className={ isActive === "NumberOfInviteCycles" || values.NumberOfInviteCycles !== "" ? "label Active" : "label"} htmlFor="">Number of Invite Cycles</label>
                                
                                <input 
                                    className="input firstName" 
                                    value={values.firsttName}
                                    onChange={handleTextChange('NumberOfInviteCycles')}
                                    type="text"
                                />

                                <fieldset className="fieldset">
                                    <legend className={ isActive === "NumberOfInviteCycles" || values.NumberOfInviteCycles !== "" ? "legend Active" : "legend"}>
                                    </legend>
                                </fieldset>   
                           </div>

                           <div className="calculator__inpputs__NumberOfInvites__holder">

                                <div className="request__input calculator__input NumberOfInvitesLow"
                                        onFocus={()=> setIsActive("NumberOfInvitesLow")}
                                        onBlur={()=> values.firstName !== null ? setIsActive("") : ""}>
                                    
                                        <label className={ isActive === "NumberOfInvitesLow" || values.NumberOfInvitesLow !== "" ? "label Active" : "label"} htmlFor="">Number of Invites Low</label>
                                        
                                        <input 
                                            className="input firstName" 
                                            value={values.firsttName}
                                            onChange={handleTextChange('NumberOfInvitesLow')}
                                            type="text"
                                        />

                                        <fieldset className="fieldset">
                                            <legend className={ isActive === "NumberOfInvitesLow" || values.NumberOfInvitesLow !== "" ? "legend Active" : "legend"}>
                                            </legend>
                                        </fieldset>   
                                </div>

                                <div className="request__input calculator__input NumberOfInvitesHigh"
                                        onFocus={()=> setIsActive("NumberOfInvitesHigh")}
                                        onBlur={()=> values.firstName !== null ? setIsActive("") : ""}>
                                    
                                        <label className={ isActive === "NumberOfInvitesHigh" || values.NumberOfInvitesHigh !== "" ? "label Active" : "label"} htmlFor="">Number of Invites High</label>
                                        
                                        <input 
                                            className="input firstName" 
                                            value={values.firsttName}
                                            onChange={handleTextChange('NumberOfInvitesHigh')}
                                            type="text"
                                        />

                                        <fieldset className="fieldset">
                                            <legend className={ isActive === "NumberOfInvitesHigh" || values.NumberOfInvitesHigh !== "" ? "legend Active" : "legend"}>
                                            </legend>
                                        </fieldset>   
                                </div>

                           </div>


                           <div className="request__input calculator__input LaunchPartnership"
                                        onFocus={()=> setIsActive("LaunchPartnership")}
                                        onBlur={()=> values.firstName !== null ? setIsActive("") : ""}>
                                    
                                        <label className={ isActive === "LaunchPartnership" || values.LaunchPartnership !== "" ? "label Active" : "label"} htmlFor="">Launch Partnership</label>
                                        
                                        <input 
                                            className="input firstName" 
                                            value={values.firsttName}
                                            onChange={handleTextChange('LaunchPartnership')}
                                            type="text"
                                        />

                                        <fieldset className="fieldset">
                                            <legend className={ isActive === "LaunchPartnership" || values.LaunchPartnership !== "" ? "legend Active" : "legend"}>
                                            </legend>
                                        </fieldset>   
                                </div>

                    </div>

                    <button onClick={(e) =>  handleGenerate(e)} className="compute">
                               <span>Compute</span>
                           </button>

                </div>

                </form>

                <div className="calculator__disclamer__container">

                    <p className="disclamer__title">Disclaimer of Liability</p>

                    <p className="disclamer__text">THE CALCULATOR (THE “CALCULATOR”) IS PROVIDED AS-IS WITH NO REPRESENTATIONS OR WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. YOU ASSUME COMPLETE RESPONSIBILITY AND RISK FOR USE OF THE CALCULATOR. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
                        [Column Inc.] (“COLUMN”) and its affiliates expressly disclaim all liability, loss or risk incurred as a direct or indirect consequence of the use of the Calculator. By using the Calculator, you waive any rights or claims you may have against COLUMN or its affiliates in connection therewith. COLUMN provides the Calculator as a tool to help you understand COLUMN’S beliefs and opinions in respect of the future so that you may use such beliefs and opinions as one factor in evaluating your investment in COLUMN. Although the financial returns generated are based upon what COLUMN believes are reasonable assumptions, the financial returns, however, are not guarantees of future returns and undue reliance should not be placed on them. COLUMN makes no representation or warranty with respect to any such projections generated by the Calculator. With respect to any such projections, you acknowledge that (a) there are uncertainties inherent in attempting to make such projections, (b) you are familiar with such uncertainties, (c) you are taking full responsibility for making your own evaluation of the adequacy and accuracy of all such projections generated by the Calculator and (d) you shall have no claim against COLUMN.
                        COLUMN and its affiliates at their discretion may choose to change the conditions and operation of the Calculator any time. COLUMN and its affiliates, in their discretion, reserve the right to refuse to provide you access to the Calculator. You agree that COLUMN and its affiliates shall not be liable to you for loss or damages that may result from our refusal to provide access to the Calculator.</p>
                </div>

            </div>           

        </div>
    );
}

export default Calculator;