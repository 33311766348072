import React, { useRef, useEffect } from "react";
import "./Grid.css";
import * as THREE from "three";

function Grid() {
  //let [heightN, setHeightN] = useState(1.5);

  var holder = useRef();

  useEffect(() => {
    var heightN = 1;
    if (window.innerWidth < 1500 && window.innerWidth > 1050) {
      heightN = 1;
    } else if (window.innerWidth < 1050) {
      heightN = 1;
    }

    const canvas = holder.current;

    console.log(canvas);

    const scene = new THREE.Scene();

    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight * heightN,
    };

    var grid = new THREE.GridHelper(12000, 2000, 0xbbbbbb, 0xbbbbbb);
    grid.position.y = 7;
    grid.rotation.x = 0.2;
    scene.add(grid);

    const group = new THREE.Group();
    group.add(grid);
    scene.add(group);

    var grid2 = new THREE.GridHelper(12000, 2000, 0xbbbbbb, 0xbbbbbb);
    grid2.position.y = -7;
    grid2.rotation.x = -0.2;
    // console.log( grid2.position);
    scene.add(grid2);

    const group2 = new THREE.Group();
    group2.add(grid2);
    scene.add(group2);

    window.addEventListener("resize", () => {
      // Update sizes
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight * heightN;

      // Update camera
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();

      // Update renderer
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    });

    /**
     * Camera
     */
    const camera = new THREE.PerspectiveCamera(
      75,
      sizes.width / sizes.height,
      0.1,
      60
    );
    camera.position.x = 3.0983564018428035e-15;
    camera.position.y = 1.5491782009214018e-15;
    camera.position.z = -25.3;

    camera.rotation.x = -3.141592653589793;
    camera.rotation.y = 1.2246467991473532e-16;
    camera.rotation.z = 3.141592653589793;

    scene.add(camera);

    /*
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: true,
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    /**
     * Animate
     */

    const cursor = {
      x: -0.5,
      y: -0.2,
    };

    window.addEventListener("mousemove", (event) => {
      cursor.x = event.clientX / sizes.width - 0.5;
      cursor.y = event.clientY / sizes.height - 0.5;
    });

    var gridY = 0.00507;
    var gridZ = 0.025;

    const tick = () => {
      grid.position.y += gridY;
      grid.position.z -= gridZ;

      group.position.y = cursor.y * -4;
      group.position.z = cursor.y * 20;

      group2.position.y = cursor.y * 4;
      group2.position.z = cursor.y * 20;

      grid2.position.y -= gridY;
      grid2.position.z -= gridZ;

      grid2.position.x = cursor.x * -10;
      grid.position.x = cursor.x * -10;

      renderer.render(scene, camera);

      window.requestAnimationFrame(tick);
    };

    tick();
  }, []);

  return (
    <div className="grid">
      <canvas id="canvas" ref={holder} />
    </div>
  );
}

export default Grid;
