import logo from '../images/logo.svg';
import menu_dot from '../images/more_dot.svg';
import MenuContent from './MenuContent';
import './Navigation.css';
import React, { useState, useEffect, useCallback } from 'react';
import {useLocation} from 'react-router';
import GoBack from '../FooterLegal/GoBack/GoBack';

function Navigation()  {
   const [navbar, setNavbar] = useState(false);
/*
 

  const changeNavigation = () => {
     if( window.scrollY >= 100) {
      setNavbar(true);
     }
     else{
      setNavbar(false);
     }
  }


  window.addEventListener('scroll', changeNavigation);

  */

  const [y, setY] = useState(window.scrollY);

         const handleNavigation = useCallback(
         e => {
            const window = e.currentTarget;
            if (y > window.scrollY && window.innerWidth < 1200) {
               //console.log("scrolling up");
               setNavbar(false);
            } else if (y < window.scrollY && window.innerWidth < 1200) {
               //console.log("scrolling down");
               setNavbar(true);
            }
            setY(window.scrollY);
         }, [y]
         );

useEffect(() => {
  setY(window.scrollY);
  window.addEventListener("scroll", handleNavigation);

  return () => {
    window.removeEventListener("scroll", handleNavigation);
  };
}, [handleNavigation]);

   const [show, setShow] = useState("false");

   const location = useLocation();
   const [logoToArrow, setLogoToArrow] = useState(false);

   function checkLogo() {
      if(   (location.pathname === "/privacy-notice" && show ==="false" && window.innerWidth < 1200) ||
            (location.pathname === "/terms-of-service" && show ==="false" && window.innerWidth < 1200) ||
            (location.pathname === "/constitution" && show ==="false" && window.innerWidth < 1020) || 
            (location.state === "profile" && show ==="false" && window.innerWidth < 1200) || 
            (location.state === "dispatch" && show ==="false" && window.innerWidth < 1200)
         ) {
         setLogoToArrow(true)
      }
      else {
         setLogoToArrow(false)
      }
   }

   useEffect(() => {
      checkLogo();
    });


   const [animate, setAnimate] = useState("false");

   const handleMenu = () => {

      setLogoToArrow(false);
      if(show === "true") { 

         document.body.classList.remove("no-scroll")

         setTimeout(function(){
            setAnimate("false"); }, 
         1);

         setTimeout(function(){
            setShow("false"); }, 
         1000);
        
      }
      else{

         setTimeout(function(){
            setAnimate("true"); }, 
         1);

        
         setShow("true");
         document.body.classList.add("no-scroll");
      }
   }

   return (
      
    <div className="navigation-container">   

      <div className={ navbar ? 'navigation hide' : 'navigation'}>
         <div className="navigation__logo">

            { !logoToArrow ?  <img src={logo} alt="" /> : <GoBack />}
          
           
         </div>

         <div onClick={handleMenu} className="navigation__menu">
            <div className={ show === "false" ? 'navigation__menu__dots show' : 'navigation__menu__dots hide'}>
               <img className="more_dot more_dot_1" src={menu_dot} alt="" />
               <img className="more_dot more_dot_2" src={menu_dot} alt="" />
               <img className="more_dot more_dot_3" src={menu_dot} alt="" />
            </div>
           
            <div onClick={handleMenu} className={ show === "false" ? 'navigation__menu__X hide' : 'navigation__menu__X show'}>
                  <span className={ animate === "false" ? 'up hide' : 'up show'}></span>
                  <span className={ animate === "false" ? 'down hide' : 'down show'}></span>
            </div>
         </div> 
        
      </div>

      {show === "true" && <MenuContent  animate={animate} handleMenu={handleMenu}/> } 
        
   </div>

   );

}

export default Navigation;

