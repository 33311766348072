import "./RequestBtn.css";
import React from "react";

function RequestBtn() {
  return (
    <div className="request__btn">
      <button className="RequestBtn" type="submit">
        <span>Request to join</span>
      </button>
    </div>
  );
}

export default RequestBtn;
