import React from 'react'
import './Love.css'
import love__avatar from '../images/love__avatar.png'
import Masonry from 'react-masonry-css'


function Love() {

    const breakpoints = {
        default: 3, 
        1200: 2,  
        740: 1
    }
    return (
        <div className="love">
            <div className="love__wrapper">

                <div className="love__headline">
                        <h1>Hear what our users think about us</h1>
                 </div>

         

                 <div className="love__card__holder">

                <Masonry
                    breakpointCols={breakpoints}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    
                    


                     <div className="love__card">
                         <a href="">

                            <div className="love__card__header">

                                <div className="love__card__user">
                                    <div className="love__card__avatar">
                                        <img src={love__avatar} alt="" />
                                    </div>
                                    <div className="love__card__info">
                                        <h2>Daniel Eicher</h2>
                                        <p>GP, SOSV</p>
                                    </div>
                                </div>

                                <div className="twitter__logo">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M19.3164 5.59147L17.5664 4.84147L18.4831 2.84147L16.3497 3.5498C15.6702 2.91641 14.7786 2.55976 13.8497 2.5498C11.7806 2.55439 10.1043 4.23064 10.0997 6.2998V7.13314C7.14974 7.74147 4.57474 6.13314 2.18307 3.38314C1.76641 5.60536 2.18307 7.27203 3.43307 8.38314L0.691406 7.96647C0.910009 9.78812 2.40153 11.1919 4.23307 11.2998L1.94141 12.1331C2.77474 13.7998 4.29141 14.0581 6.31641 14.2165C4.66126 15.3454 2.69438 15.9282 0.691406 15.8831C11.3247 20.6081 17.5664 13.6665 17.5664 7.5498V6.85814L19.3164 5.59147Z" stroke="#282828" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                            </div>

                        </a>

                        <div className="love__card__content">
                            <p>Social Impact Capital is a top-tier Seed fund.</p>
                        </div>
                       
                     </div>


                     <div className="love__card">
                        <a href="">

                            <div className="love__card__header">

                            <div className="love__card__user">
                                <div className="love__card__avatar">
                                    <img src={love__avatar} alt="" />
                                </div>
                                <div className="love__card__info">
                                    <h2>Peter Thiel</h2>
                                    <p>Founder, Thiel Capital</p>
                                </div>
                            </div>

                            <div className="twitter__logo">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M19.3164 5.59147L17.5664 4.84147L18.4831 2.84147L16.3497 3.5498C15.6702 2.91641 14.7786 2.55976 13.8497 2.5498C11.7806 2.55439 10.1043 4.23064 10.0997 6.2998V7.13314C7.14974 7.74147 4.57474 6.13314 2.18307 3.38314C1.76641 5.60536 2.18307 7.27203 3.43307 8.38314L0.691406 7.96647C0.910009 9.78812 2.40153 11.1919 4.23307 11.2998L1.94141 12.1331C2.77474 13.7998 4.29141 14.0581 6.31641 14.2165C4.66126 15.3454 2.69438 15.9282 0.691406 15.8831C11.3247 20.6081 17.5664 13.6665 17.5664 7.5498V6.85814L19.3164 5.59147Z" stroke="#282828" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>

                           
                            </div>

                        </a>

                        <div className="love__card__content">
                            <p>If the future is going to exist at all we have</p>
                        </div>
                            
                        </div>


                        <div className="love__card">
                            <a href="">

                                <div className="love__card__header">

                                    <div className="love__card__user">
                                        <div className="love__card__avatar">
                                            <img src={love__avatar} alt="" />
                                        </div>
                                        <div className="love__card__info">
                                            <h2>Jake Harris</h2>
                                            <p>Associate DBL</p>
                                        </div>
                                    </div>

                                    <div className="twitter__logo">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M19.3164 5.59147L17.5664 4.84147L18.4831 2.84147L16.3497 3.5498C15.6702 2.91641 14.7786 2.55976 13.8497 2.5498C11.7806 2.55439 10.1043 4.23064 10.0997 6.2998V7.13314C7.14974 7.74147 4.57474 6.13314 2.18307 3.38314C1.76641 5.60536 2.18307 7.27203 3.43307 8.38314L0.691406 7.96647C0.910009 9.78812 2.40153 11.1919 4.23307 11.2998L1.94141 12.1331C2.77474 13.7998 4.29141 14.0581 6.31641 14.2165C4.66126 15.3454 2.69438 15.9282 0.691406 15.8831C11.3247 20.6081 17.5664 13.6665 17.5664 7.5498V6.85814L19.3164 5.59147Z" stroke="#282828" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                            </a>   
                                <div className="love__card__content">
                                    <p>Your talk at Yale School of Business was tremendous. The room was full of chatter about how helpful it was after.</p>
                                </div>
                           
                        </div>


                        <div className="love__card">

                            <a href="">
                                <div className="love__card__header">

                                    <div className="love__card__user">
                                        <div className="love__card__avatar">
                                            <img src={love__avatar} alt="" />
                                        </div>
                                        <div className="love__card__info">
                                            <h2>Alan Arturo Day</h2>
                                            <p>Designer at Magoosh</p>
                                        </div>
                                    </div>

                                    <div className="twitter__logo">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M19.3164 5.59147L17.5664 4.84147L18.4831 2.84147L16.3497 3.5498C15.6702 2.91641 14.7786 2.55976 13.8497 2.5498C11.7806 2.55439 10.1043 4.23064 10.0997 6.2998V7.13314C7.14974 7.74147 4.57474 6.13314 2.18307 3.38314C1.76641 5.60536 2.18307 7.27203 3.43307 8.38314L0.691406 7.96647C0.910009 9.78812 2.40153 11.1919 4.23307 11.2998L1.94141 12.1331C2.77474 13.7998 4.29141 14.0581 6.31641 14.2165C4.66126 15.3454 2.69438 15.9282 0.691406 15.8831C11.3247 20.6081 17.5664 13.6665 17.5664 7.5498V6.85814L19.3164 5.59147Z" stroke="#282828" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                            </a>

                            <div className="love__card__content">
                                <p>I HIGHLY recommend Fast Track - my mentor has been a continuing source of wisdom and support. And the networking sessions were great 🤠 thanks <a href=""> @socialimpactcapital </a></p>
                            </div>
                          
                        </div>


                        <div className="love__card">
                            <a href="">

                                <div className="love__card__header">

                                    <div className="love__card__user">
                                        <div className="love__card__avatar">
                                            <img src={love__avatar} alt="" />
                                        </div>
                                        <div className="love__card__info">
                                            <h2>Matthew George</h2>
                                            <p>Co-Founder at Merlin Labs</p>
                                        </div>
                                    </div>

                                    <div className="twitter__logo">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M19.3164 5.59147L17.5664 4.84147L18.4831 2.84147L16.3497 3.5498C15.6702 2.91641 14.7786 2.55976 13.8497 2.5498C11.7806 2.55439 10.1043 4.23064 10.0997 6.2998V7.13314C7.14974 7.74147 4.57474 6.13314 2.18307 3.38314C1.76641 5.60536 2.18307 7.27203 3.43307 8.38314L0.691406 7.96647C0.910009 9.78812 2.40153 11.1919 4.23307 11.2998L1.94141 12.1331C2.77474 13.7998 4.29141 14.0581 6.31641 14.2165C4.66126 15.3454 2.69438 15.9282 0.691406 15.8831C11.3247 20.6081 17.5664 13.6665 17.5664 7.5498V6.85814L19.3164 5.59147Z" stroke="#282828" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                            </a>

                            <div className="love__card__content">
                                <p>5 Really proud of the 
                                    <a href=""> @MerlinAero </a>team today as we come out of stealth with over $25m in funding from 
                                    <a href=""> @GVteam</a>  and <a href="">@socialimpactcapital </a> 
                                (among many others). To top it off, we're also announcing a 55 aircraft deal with <a href="">@DynamicAviation</a> There have been many people involved in the journey so far, but we truly wouldn't be here without <a href="">@joshk</a> who took a massive bet on us before we even had incorporated the company. Come join the team at <a href="">merlinlabs.com</a></p> 
                            </div>
                        </div>

                        <div className="love__card">

                            <a href="">

                                <div className="love__card__header">

                                    <div className="love__card__user">
                                        <div className="love__card__avatar">
                                            <img src={love__avatar} alt="" />
                                        </div>
                                        <div className="love__card__info">
                                            <h2>Vivek Sodera</h2>
                                            <p>CTO at Superhuman</p>
                                        </div>
                                    </div>

                                    <div className="twitter__logo">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M19.3164 5.59147L17.5664 4.84147L18.4831 2.84147L16.3497 3.5498C15.6702 2.91641 14.7786 2.55976 13.8497 2.5498C11.7806 2.55439 10.1043 4.23064 10.0997 6.2998V7.13314C7.14974 7.74147 4.57474 6.13314 2.18307 3.38314C1.76641 5.60536 2.18307 7.27203 3.43307 8.38314L0.691406 7.96647C0.910009 9.78812 2.40153 11.1919 4.23307 11.2998L1.94141 12.1331C2.77474 13.7998 4.29141 14.0581 6.31641 14.2165C4.66126 15.3454 2.69438 15.9282 0.691406 15.8831C11.3247 20.6081 17.5664 13.6665 17.5664 7.5498V6.85814L19.3164 5.59147Z" stroke="#282828" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>

                            </a>

                                <div className="love__card__content">
                                    <p>Yooo<a href=""> @socialimpactcapital </a>
                                    
                                    is on 🔥. No other VC compares when it comes to value-add to the community: </p>

                                    <ul>
                                        <li>FR Network (for portfolios)</li>
                                        <li>Talent</li>
                                        <li>Expert Network</li>
                                        <li>First Round Review</li>
                                        <li>Discovery Assist</li>
                                        <li>Angel Track</li>
                                        <li>Fast Track</li>
                                        <li>Co-Founder Forum</li>
                                        <li>Unconference + much more</li>
                                    </ul>
                                   
                                </div>
                        </div>


                        <div className="love__card">

                            <a href="">

                                <div className="love__card__header">

                                    <div className="love__card__user">
                                        <div className="love__card__avatar">
                                            <img src={love__avatar} alt="" />
                                        </div>
                                        <div className="love__card__info">
                                            <h2>Emily Kramer</h2>
                                            <p>Angel Investor</p>
                                        </div>
                                    </div>

                                    <div className="twitter__logo">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M19.3164 5.59147L17.5664 4.84147L18.4831 2.84147L16.3497 3.5498C15.6702 2.91641 14.7786 2.55976 13.8497 2.5498C11.7806 2.55439 10.1043 4.23064 10.0997 6.2998V7.13314C7.14974 7.74147 4.57474 6.13314 2.18307 3.38314C1.76641 5.60536 2.18307 7.27203 3.43307 8.38314L0.691406 7.96647C0.910009 9.78812 2.40153 11.1919 4.23307 11.2998L1.94141 12.1331C2.77474 13.7998 4.29141 14.0581 6.31641 14.2165C4.66126 15.3454 2.69438 15.9282 0.691406 15.8831C11.3247 20.6081 17.5664 13.6665 17.5664 7.5498V6.85814L19.3164 5.59147Z" stroke="#282828" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                                </a>

                                <div className="love__card__content">
                                    <p>This program is almost too good to be true: It's free, you get to learn about investing from the best investors in the world in an intimate setting, and as a huge bonus you meet a bunch of extraordinary people going through the program with you. I couldn't recommend more.</p>

                                </div>

                        </div>


                        <div className="love__card">

                            <a href="">

                                <div className="love__card__header">

                                    <div className="love__card__user">
                                        <div className="love__card__avatar">
                                            <img src={love__avatar} alt="" />
                                        </div>
                                        <div className="love__card__info">
                                            <h2>Glenn Soloman</h2>
                                            <p>Investor at GGV Capital</p>
                                        </div>
                                    </div>

                                    <div className="twitter__logo">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M19.3164 5.59147L17.5664 4.84147L18.4831 2.84147L16.3497 3.5498C15.6702 2.91641 14.7786 2.55976 13.8497 2.5498C11.7806 2.55439 10.1043 4.23064 10.0997 6.2998V7.13314C7.14974 7.74147 4.57474 6.13314 2.18307 3.38314C1.76641 5.60536 2.18307 7.27203 3.43307 8.38314L0.691406 7.96647C0.910009 9.78812 2.40153 11.1919 4.23307 11.2998L1.94141 12.1331C2.77474 13.7998 4.29141 14.0581 6.31641 14.2165C4.66126 15.3454 2.69438 15.9282 0.691406 15.8831C11.3247 20.6081 17.5664 13.6665 17.5664 7.5498V6.85814L19.3164 5.59147Z" stroke="#282828" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                            </a>

                                <div className="love__card__content">
                                    <p>Congrats to <a href=""> @chrisfralic </a> and  <a href=""> @socialimpactcapital </a> on the amazing  <a href=""> @Roblox </a>investment - great post here on the power of the slow bake and non linear valuation growth! (Love it when the good guys win) </p>
                                </div>

                            </div>

                            

                        </Masonry>

                      

                        </div>

            </div>

        </div>
    )
}

export default Love
