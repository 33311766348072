import './Record.css';
import React, {useState, useEffect} from 'react';
import Aos from 'aos';
import "aos/dist/aos.css";



function Record() {

    const [formIsValid, setFormIsValid] =  useState(false);

    function handleButton(e) {
 
        if( values.firstName !== "" && values.email !== "" && values.email.indexOf('@') !== -1 && values.description !== "") {
           setFormIsValid(true);   
            
        }
        else {
           setFormIsValid(false);  
        } 
    }




    /*
    const [eventState, setEventState] = useState("positive");
    const [confidentialityState, setconfidentialityState] = useState("internal");
*/
    const [isActive, setIsActive] = useState("");

    
    const [values, setValues] = useState({
        firstName: '',
        email: '',
        description: '',
        internal: "",
        positive: "",
        negative: "",
        may_use_1: "",
        may_use_2: "",
        may_use_3: ""
    });

    

    const handleTextChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value });
    };

    useEffect(() => {
        Aos.init({duration:700, once:"true"});
    },[])


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(JSON.stringify(values))

        console.log(e);
       
    };


    const handleCheckLeft = (e) => {

        document.getElementById("may_public").checked = false;
        document.getElementById("may_media").checked = false;
        document.getElementById("may_all").checked = false;  
        
        if (document.getElementById("internal").checked === false) {

            document.getElementById("may_public").checked = true
            document.getElementById("may_media").checked = true
            document.getElementById("may_all").checked = true; 
        }

    }

    const handleCheckRight = (e) => {

        if( document.getElementById("internal").checked === true) {

            document.getElementById("may_public").checked = true;
            document.getElementById("may_media").checked = true;
            document.getElementById("may_all").checked = true; 

            document.getElementById("internal").checked = false;
        }

        else if (   document.getElementById("may_public").checked === false &&
                    document.getElementById("may_media").checked === false &&
                    document.getElementById("may_all").checked === false ) {

                        document.getElementById("internal").checked = true;
                    }
    }


    

    return(
        <div className="record">

        <div className="record__background">

        </div>

            <div className="record__div__arched">
                <div className="record__wrapper__arched">

                    <div className="record__headline">
                        <p className="record__title rt1">
                            Column/ Record
                        </p>

                        <p className="record__subtitle rt2">
                            At Column/, we collect the positive or negative impacts caused by the platform: you can record them here.
                        </p>
                    </div>


                    <form action="" className="record__form" onSubmit={handleSubmit}>

                        <p className="record__form__subtitle rt3">Event</p>

                        <div className="record__form__uper">

                            <div className="request__input first-name rt4"
                                 onFocus={()=> setIsActive("name")}
                                 onBlur={()=> values.firstName !== null ? setIsActive("") : ""}
                                 >
                                       
                            
                                <label className={ isActive === "name" || values.firstName !== "" ? "label Active" : "label"} htmlFor="">First Name</label>
                                
                                <input 
                                    className="input firstName" 
                                    value={values.firsttName}
                                    onChange={handleTextChange('firstName')}
                                    type="text"
                                    onKeyUp={handleButton}
                                />

                                <fieldset className={ isActive === "name" || values.firstName !== "" ? "fieldset Active" : "fieldset"}>
                                    <legend className={ isActive === "name" || values.firstName !== "" ? "legend Active" : "legend"}>
                                    </legend>
                                </fieldset>   
                           </div>


                           <div className="request__input your-email rt5"
                                onFocus={()=> setIsActive("email")}
                                onBlur={()=> values.email  !== null && setIsActive("")}
                                >
                                 
                               <label className={ isActive === "email" || values.email !== "" ? "label Active" : "label"} htmlFor="" >Your email</label>
                               
                               <input 
                                    className="input lastName" 
                                    value={values.email}
                                    onChange={handleTextChange('email')}
                                    type="text"
                                    onKeyUp={handleButton}
                                    />

                               <fieldset className={ isActive === "email" || values.firstName !== "" ? "fieldset Active" : "fieldset"}>
                                   <legend className={ isActive === "email" || values.email !== "" ? "legend email Active" : "legend email"}>
                                    
                                   </legend>
                               </fieldset>
                           </div>

                        </div>

                            <div className="record__form__lower rt6"
                                onFocus={()=> setIsActive("description")}
                                onBlur={()=> values.description  !== null && setIsActive("")}>
                              
                               <label className={ isActive === "description" || values.description !== ""  ? "label Active" : "label"} htmlFor="" >Event Description</label>
                               
                               < textarea
                                     name="" id="" cols="30" rows="10"
                                    className="record__form__textarea" 
                                    value={values.description}
                                    onChange={handleTextChange('description')}
                                    type="text"
                                    onKeyUp={handleButton}
                                    />

                               <fieldset className={ isActive === "description" || values.firstName !== "" ? "fieldset Active" : "fieldset"}>
                                   <legend className={ isActive === "description" || values.description !== ""  ? "legend description Active" : "legend description"}>
                                    
                                   </legend>
                               </fieldset>
                           </div> 

                      

                        <div className="record__form__radial">

                            <div className="record__form__radial__PN">

                                {
                                    /*

                                     <label htmlFor=""  data-aos="fade-up" data-aos-delay="500">
                                    <input
                                        id="record__positive"
                                        type="radio" 
                                        value="positive" 
                                        name="Event" 
                                        checked={eventState === "positive"}
                                        onChange={(e)=>{setEventState(e.target.value)}}
                                
                                    />
                                    <img src={eventState === "positive" ? checkedImg : checkImg} alt="" />     

                                    <span>Positive</span> 
                                </label>




                                <label htmlFor="" data-aos="fade-up" data-aos-delay="600">
                                    <input
                                        id="record__negative"
                                        type="radio" 
                                        value="negative" 
                                        name="Event" 
                                        checked={eventState === "negative"}
                                        onChange={(e)=>{setEventState(e.target.value)}}
                                
                                    />
                                    <img src={eventState === "negative" ? checkedImg : checkImg} alt="" />  

                                    <span>Negative</span>  
                                </label>


                                    */

                                }

                                <label className="container plan__radio" data-aos="fade-up" data-aos-delay="200">Positive
                                    <input
                                   id="record__positive"
                                   type="radio" 
                                   value="positive" 
                                   name="Event" 
                                   onChange={handleTextChange('positive')}
                                   defaultChecked
                                   
                                  // onChange={(e)=>{setEventState(e.target.value)}}
                    
                                     />
                                    <span className="checkmark__inside"></span>
                                    <span className="checkmark">  
                                    </span>
                                </label>


                                <label className="container plan__radio" data-aos="fade-up" data-aos-delay="300">Negative
                                    <input
                                    id="record__negative"
                                    type="radio" 
                                    value="negative" 
                                    name="Event" 
                                 
                                    onChange={handleTextChange('negative')}

                                  
                                    //onChange={(e)=>{setEventState(e.target.value)}}
                    
                                     />
                                    <span className="checkmark__inside"></span>
                                    <span className="checkmark">  
                                    </span>
                                </label>

                               

                                

                            </div>
                        <p className="record__form__subtitle subtitle__confidentiality"  data-aos="fade-up" data-aos-delay="200">Confidentiality</p>

                        <div className="confidentiality__holder">
                            
                            <div className="confidentiality__internal">
                                
                               

                                {
                                    /*

                                     <label htmlFor="" data-aos="fade-up" data-aos-delay="500">
                                    
                                            <input
                                                id="record__negative"
                                                type="radio" 
                                                value="internal" 
                                                name="Confidentiality" 
                                                checked={confidentialityState === "internal"}
                                                onChange={(e)=>{setconfidentialityState(e.target.value)}} 
                                            />
                                            <img src={confidentialityState === "internal" ? checkedImg : checkImg} alt="" />  

                                            <span>Internal use only</span>  
                                        </label>

                                    */
                                    }

                                        <label className="container plan__radio" data-aos="fade-up" data-aos-delay="200">Internal use only
                                            <input
                                            id="internal"
                                            type="checkbox" 
                                            value="Internal use only" 
                                            name="Confidentiality" 
                                            defaultChecked
                                            onClick={handleCheckLeft}
                                            
                                          
                                            onChange={handleTextChange('internal')}
                                          
                                           // onChange={(e)=>{setconfidentialityState(e.target.value)}} 
                                            
                                            
                                            />
                                            <span className="checkmark__inside"></span>
                                            <span className="checkmark">  
                                            </span>
                                        </label>
                              
                                
                            </div>

                            <div className="confidentiality__other">

                           

                                {
                                    /*

                                     <label htmlFor="" data-aos="fade-up" data-aos-delay="500">
                                        <input
                                            id="record__negative"
                                            type="radio" 
                                            value="other" 
                                            name="Confidentiality" 
                                            checked={confidentialityState === "other"}
                                            onChange={(e)=>{setconfidentialityState(e.target.value)}}
                                        
                                        
                                            />
                                        <img src={confidentialityState === "other" ? checkedImg : checkImg} alt="" />  

                                        <span>May use anonymously in academic research</span>  
                                    </label>

                                    <label htmlFor="" data-aos="fade-up" data-aos-delay="500">
                            
                                <input
                                    id="record__negative"
                                    type="radio" 
                                    value="other" 
                                    name="Confidentiality" 
                                    checked={confidentialityState === "other"}
                                    onChange={(e)=>{setconfidentialityState(e.target.value)}}
                                
                                
                                    />
                                <img src={confidentialityState === "other" ? checkedImg : checkImg} alt="" />  

                                <span>May use in media coverage</span>  
                            </label >


                            <label htmlFor="" data-aos="fade-up" data-aos-delay="500">
                                
                                <input
                                    id="record__negative"
                                    type="radio" 
                                    value="other" 
                                    name="Confidentiality" 
                                    checked={confidentialityState === "other"}
                                    onChange={(e)=>{setEventState(e.target.value)}}
                                    />
                                <img src={confidentialityState === "other" ? checkedImg : checkImg} alt="" />  

                                <span>May publish to public list</span>  
                            </label>


                                    */

                        }



                                <label className="container plan__radio custom__line__height" data-aos="fade-up" data-aos-delay="300">May use anonymously in academic research
                                    <input
                                    id="may_all"
                                    type="checkbox" 
                                    value="other" 
                                    name="Confidentiality" 
                                    onClick={handleCheckRight}
                                    onChange={handleTextChange('may_use_1')}
                                 
                                  
                                   // onChange={(e)=>{setconfidentialityState(e.target.value)}}
                                     
                                     />
                                    <span className="checkmark__inside"></span>
                                    <span className="checkmark">  
                                    </span>
                                </label>

                                <label  className="container plan__radio" data-aos="fade-up" data-aos-delay="400">May use in media coverage
                                    <input
                                    
                                     id="may_media"
                                     type="checkbox" 
                                     value="other" 
                                     name="Confidentiality" 
                                     onClick={handleCheckRight}
                                     onChange={handleTextChange('may_use_2')}
                                    
                                   
                                    
                                    // onChange={(e)=>{setconfidentialityState(e.target.value)}}
                                     
                                     />
                                    <span className="checkmark__inside"></span>
                                    <span className="checkmark">  
                                    </span>
                                </label>


                                <label className="container plan__radio"data-aos="fade-up" data-aos-delay="500" >May publish to public list
                                    <input
                                     id="may_public"
                                     type="checkbox" 
                                     value="May publish to public list" 
                                     name="Confidentiality" 
                                     onClick={handleCheckRight}
                                     onChange={handleTextChange('may_use_3')}
                                    
                                    
                                   
                                   
                                     
                                    
                                     //onChange={(e)=>{setEventState(e.target.value)}}
                                     
                                     />
                                    <span className="checkmark__inside"></span>
                                    <span className="checkmark">  
                                    </span>
                                </label>
                            

                            


                            </div>

                        </div>

                       </div>


            
                    <div  data-aos="fade-up" data-aos-delay="500">

                        <div className={!formIsValid ? "record__submit " : "record__submit clickable" }>
                            <button  type="submit" className={!formIsValid ? "record__form__submit non__clickable" : "record__form__submit clickable" }>
                                Submit
                            </button>
                        </div>

                    </div>
                        
                    </form>

                </div>

            </div>
        </div>
    );
}

export default Record;