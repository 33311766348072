import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./About.css";

/*
import sarah_avatar from '../images/team/sarah_avatar.png';
import zee_avatar from '../images/team/zee_avatar.png';
import ahmed_avatar from '../images/team/ahmed_avatar.png';
import alina_avatar from '../images/team/alina_avatar.png';

import blog_bg01 from '../images/blog/Blog_01.png';
import blog_bg02 from '../images/blog/Blog_04.png';
*/
import useFetch from "../useFetch/useFetch";
import DispatchPreview from "./Dispatch/DispatchPreview/DispatchPreview";

function About() {
  const { data: dispatches, error } = useFetch(
    "http://localhost:8000/dispatches"
  );

  //var dispatches__container_height = 0;

  const [dispatches__container_height, setDispatches__container_height] =
    useState(0);
  //const [about__content, setAbout__content] = useState(null);

  let dispatches__container = useRef();
  let about__content__div = useRef();
  let load__more__btn = useRef();

  useEffect(() => {
    setTimeout(() => {
      var about__content = null;

      setDispatches__container_height(
        dispatches__container.current.offsetHeight
      );
      about__content = about__content__div.current;

      if (window.innerWidth < 1380 && window.innerWidth > 1050) {
        about__content.style.height = dispatches__container_height + "px";
      }
    }, 10);
  }, [dispatches__container_height]);

  return (
    <div className="about">
      <div className="about__wrapper">
        <div
          className="about__content"
          id="about__content"
          ref={about__content__div}
        >
          <div className="team__content">
            <div className="team-bio">
              <h2>Team</h2>
            </div>

            <p className="team__content__p">
              Column/ is an experimental project incubated by Social Impact
              Capital, a venture capital firm that’s dedicated to solving the
              world’s most entrenched and difficult social and environmental
              problems through the medium of markets.
            </p>

            <div className="author__preview__holder">
              {dispatches &&
                dispatches.map((dispatch) => (
                  <div className="author__preview" key={dispatch.id}>
                    <div className="author__avatar">
                      <img src={dispatch.avatar} alt="" />
                    </div>
                    <div className="author__info">
                      <Link
                        to={{
                          pathname: `/profile/${dispatch.id}`,
                          state: "profile",
                        }}
                      >
                        <h2>{dispatch.author}</h2>

                        <p>{dispatch.position}</p>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>

            <p className="hire__link">
              We are hiring for all roles,please <span>reach out.</span>
            </p>
          </div>

          <div
            className="dispatches__container"
            id="dispatches__container"
            ref={dispatches__container}
          >
            <div className="about__title">
              <h2>Dispatches</h2>
            </div>

            {error && <p>{error}</p>}

            <DispatchPreview data={dispatches} error={error} />

            <div className="load__more__about" ref={load__more__btn}>
              <p>Load More</p>
            </div>
          </div>

          <div className="mission__content">
            <div className="about__mision">
              <h2 className="about__mision_p">Mission</h2>
            </div>

            <p>
              Column is a new social network dedicated to reforming information
              incentives online, in support of high-quality information and
              interactions.
            </p>

            <button className="contact-us-btn">
              <span>Contact us</span>
            </button>

            <button className="request-invite-btn">
              <span>Request invite</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
