import './PrivacyNotice.css';
import { Link } from 'react-router-dom';
import React from 'react';
import GoBack from '../GoBack/GoBack';

function PrivacyNotice() {

    const link1= (e) => {
        const anchor = document.querySelector('#footer__legall__link1');
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    const link2= (e) => {
        const anchor = document.querySelector('#footer__legall__link2');
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    const link3= (e) => {
        const anchor = document.querySelector('#footer__legall__link3');
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    const link4= (e) => {
        const anchor = document.querySelector('#footer__legall__link4');
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    const link5= (e) => {
        const anchor = document.querySelector('#footer__legall__link5');
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    const link6= (e) => {
        const anchor = document.querySelector('#footer__legall__link6');
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    const link7= (e) => {
        const anchor = document.querySelector('#footer__legall__link7');
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    const link8= (e) => {
        const anchor = document.querySelector('#footer__legall__link8');
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    const link9= (e) => {
        const anchor = document.querySelector('#footer__legall__link9');
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    const link10= (e) => {
        const anchor = document.querySelector('#footer__legall__link10');
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    const link11= (e) => {
        const anchor = document.querySelector('#footer__legall__link11');
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    const link12= (e) => {
        const anchor = document.querySelector('#footer__legall__link12');
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    const link13= (e) => {
        const anchor = document.querySelector('#footer__legall__link13');
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }


    return(

       <div className="footerLegal-container">

           {
            window.innerWidth > 1200 && <GoBack />
           }

            <div className="wrapper__privacy">

                <div className="footerLegal__headline">
                    <p className="footerLegal__title">Column/ Privacy Notice</p>
                    <p className="footerLegal__effective-date">Effective date: <span>May 25, 2021</span></p>
                </div>

                <div className="footerLegal__content">

                    <p className="footerLegal__paragraph">
                        Welcome, and thank you for your interest in Column, Inc.
                        (“Column,” “we,” “our,” or “us”). Column is a social network
                        dedicated to fixing information incentives online. We do this so 
                        that the Column community can thrive in an environment that is safe, 
                        fun, and in which our users can join in constructive conversations 
                        based on interest or what they are passionate about – including 
                        floating around ideas on how to make the world a better place, 
                        one column at a time!
                    </p>

                    <p className="footerLegal__paragraph">
                        Column is committed to protecting and respecting your privacy. 
                        This Privacy Notice explains how we collect, store, use, and disclose 
                        information about you when you use our mobile application (the “App”),
                        our website (the “Site”) and other online products and services that 
                        link to this Privacy Notice (collectively, the “Service”). 
                    </p>

                    <p className="footerLegal__paragraph" >
                        We may also provide you with additional privacy notices or disclosures
                        where the scope of the inquiry, request, or personal information we
                        require falls outside the scope of this Privacy Notice. In that case, 
                        the additional privacy notice or disclosures will govern how we may process 
                        the information you provide at that time.
                    </p>

                    <p className="footerLegal__paragraph">
                        Please ensure that you have read this Privacy Notice and understood 
                        how we collect, store, use and disclose your personal information before 
                        accessing or using the Service.
                    </p>

                    <p className="footerLegal__paragraph" >Click on the links below to jump to each section:</p>

                        <p onClick={link1} className="privacyNotice__navigation__link">Information we collect, and how we collect it</p>
                        
                        <p onClick={link2} className="privacyNotice__navigation__link">Other uses of personal Information</p>

                        <p onClick={link3} className="privacyNotice__navigation__link">Sharing of Information</p>

                        <p onClick={link4} className="privacyNotice__navigation__link">How we use cookies and other tracking 
                            technology to collect Information
                        </p>

                        <p onClick={link5} className="privacyNotice__navigation__link">Third party tracking and online advertising</p>

                        <p onClick={link6} className="privacyNotice__navigation__link">Control over your Information</p>

                        <p onClick={link7} className="privacyNotice__navigation__link">Data Protection</p>

                        <p onClick={link8} className="privacyNotice__navigation__link">Links to third-party websites and services</p>

                        <p onClick={link9} className="privacyNotice__navigation__link">Children’s privacy</p>

                        <p onClick={link10} className="privacyNotice__navigation__link">Your California privacy rights</p>

                        <p onClick={link11} className="privacyNotice__navigation__link">Nevada residents</p>

                        <p onClick={link12} className="privacyNotice__navigation__link">Updates to this Privacy Notice</p>

                        <p onClick={link13} className="privacyNotice__navigation__link">Contact us</p>

                 
                        <p id="footer__legall__link1" className="sections__title">Information we collect, and 
                            how we collect it
                        </p>

                        <p className="footerLegal__paragraph">We may collect information, including personal 
                            information, in connection with your visits to and use of the Service. This collection 
                            includes information that you provide in connection with the Service, information 
                            from third parties, and information that is collected automatically such as through
                            the use of cookies and other technologies.
                        </p>

                        <p className="footerLegal__paragraph">Please note that Column verifies users
                            who register for the Service including, but not limited to, where they work,
                            social media profiles, and any other public information that may be available 
                            in connection with the users. The Column team may also request to have a virtual 
                            call to chat about what it means to join the Column community. We do this so that 
                            the Column community can thrive in an environment that is safe, fun, and in which 
                            our users can join in constructive conversations based on interest or what they are 
                            passionate about – including floating around ideas on how to make the world a better
                            place, one column at a time! 
                        </p>

                        <p className="footerLegal__paragraph">Verified users can choose to post and comment 
                            using their name and full profile, or semi-anonymously using only a Nom de Plume and 
                            other identifiers such as where they work or their role.
                        </p>

                        <p className="information__subtitle">Information That You Provide</p>

                        <p className="footerLegal__paragraph">We may collect personal information from you. The
                             categories of information we collect can include:
                        </p>

                        <ul className="footerLegal__list">
                            <li>
                                <p className="footerLegal__paragraph"> 
                                <span className="footerLegal__list__bold">Registration information.</span> 
                                    We collect personal and/or business information that you provide 
                                    when you sign up for our Service, and which we may use to verify 
                                    your profile. This information may include your full name, Nom de Plume, 
                                    password, email address, phone number, company, industry, professional 
                                    role or title, and password. We use this information to authenticate your
                                    account and keep our Service safe, authentic, and free of spam (for more
                                    information, please see our <Link> Community Guidelines </Link> which help keep the Column 
                                    Service fun and enjoyable for everyone).
                                </p>

                                <p className="footerLegal__paragraph">Please note that in order to join Column
                                    community you must be invited. In addition to being invited, you may visit
                                    the Service and request to be added to our waiting list where the Column
                                    team will review your request and invite you to join the Service when
                                    registration is available.

                                </p>
                            </li>

                            <li> 
                                <p className="footerLegal__paragraph">
                                    <span className="footerLegal__list__bold">Payment Information.</span> 
                                    If you make a purchase
                                    or payment on the Site, such as for a subscription, we collect information
                                    provided in connection with your transaction. Please note that we use third
                                    party payment processors, including Stripe, to process payments made to us.
                                    As such, we do not retain any personally identifiable financial information
                                    such as credit card numbers. Rather, all such information is provided directly
                                    to our third-party processor. The payment processor’s use of your personal information
                                    is governed by their privacy notice.  To view Stripe’s privacy notice,
                                    please visit: <Link> https://stripe.com/privacy. </Link>

                                </p>
                            </li>
                            
                            <li>
                                <p className="footerLegal__paragraph">
                                    <span className="footerLegal__list__bold">Payment Information. </span>
                                        We may collect additional information you include in your profile such as your 
                                        picture, your skills, your social media link (e.g. LinkedIn personal URL).
                                        We also collect the “columns” you add to your profile which reflect your
                                        preferences and interests. We use this information to personalize column 
                                        recommendations to you, and to help you connect with other like-minded professionals.
                                </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">
                                    <span className="footerLegal__list__bold">Information Collected through the Use of the Service. </span>
                                    After registration, you may create and share a post, upload pictures, post comments, write or comment
                                    on the Column blog, or otherwise provide information as part of your use of the Service (collectively, 
                                    “User Content”). User Content and any information contained in the User Content, including personal 
                                    information you may have included, is stored and collected as part of the Service. You control the 
                                    information included in the User Content, and whether that information is shared publicly with all 
                                    users or only with select users. You may also be able to send private messages directly to other 
                                    users. To share photos and videos, we will access your device camera roll and camera with your permission.  
                                    If you need to update your permissions, you can do so in the “Settings” app of your device.  
                                    Please remember that Column may, but has no obligation to, monitor, record, and store User Content 
                                    in order to protect your safety or the safety of other users, to assist with regulatory or law enforcement 
                                    efforts, or to protect and defend our rights and property. 
                                </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">
                                    <span className="footerLegal__list__bold">Events, Surveys, and Promotions. </span>
                                    If you fill out any forms relating to Column events, surveys, contests, sweepstakes, 
                                    or other promotional events, we collect your contact and demographic information, 
                                    and any other information requested on the form or at sign up.  
                                    We use this information to fulfill the purpose of the form or sign up, 
                                    facilitate the event, survey, contest, sweepstakes, and promotion, and to 
                                    inform you about future programs that may be of interest to you.
                                </p>

                            </li>
                            
                        </ul>

                        <p className="information__subtitle">Information from Third Parties</p>

                        <p className="footerLegal__paragraph">We may also receive personal
                            information about you from our business partners and service 
                            providers and combine this information with other information 
                            we collect from or about you.  The third parties may include our 
                            business partners, technology providers, social networks, website 
                            operators, marketing partners, data providers, and references. 
                            The information may include contact information, demographic 
                            information, interests, information about your subscription, 
                            and educational and professional history.  We use this information 
                            to administer and facilitate our Service, process your subscription, 
                            and conduct marketing activities.
                        </p>

                        <p className="information__subtitle">Information from Other Sources</p>

                        <p className="footerLegal__paragraph">We may obtain information 
                            from other sources, such as third-party information providers, 
                            or through mergers and acquisitions, and combine this with 
                            information previously collected. In these cases, our Privacy 
                            Notice governs the handling of the combined personal information.
                        </p>

                        <p id="footer__legall__link2" className="sections__title">Other uses of personal Information</p>

                        <p className="footerLegal__paragraph">In addition to the uses described above, 
                            we may collect, use, process, combine, retain and store personal information and 
                            information that we collect or receive for a variety of purposes, including the following:
                        </p>

                        <ul className="footerLegal__list">

                            <li>
                                <p className="footerLegal__paragraph">For our business activities, 
                                    including to operate the Service and to provide you with the features 
                                    and functionality of the Service, and to authenticate your account or 
                                    verify your identity; 
                                </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">To verify your payment in connection with 
                                    subscription or other paid portions of the Service;
                                </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">To communicate with you 
                                    and respond to your requests, such as to respond to your questions, 
                                    contact you about changes to the Service, and communicate about account 
                                    related matters;
                                </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">For marketing and advertising purposes, 
                                    such as to market to you or offer you with information and updates on our 
                                    products or services we think that you may be interested in;
                                </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">For analytics and research purposes, 
                                    including through the use of machine learning;
                                </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">To enforce this Privacy Notice and our 
                                    Terms of Service, to resolve disputes, to carry out our obligations and enforce our 
                                    rights, and to protect our business interests and the interests and rights of third 
                                    parties;
                                </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">To comply with contractual and legal 
                                obligations and requirements;
                                </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">To fulfill any other purpose for which you provide personal 
                                information; and</p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">TFor any other lawful purpose, or other purpose that you consent 
                                to.</p>
                            </li>

                        </ul>
                   
                        <p id="footer__legall__link3" className="sections__title">Sharing of Information</p>

                        <p className="footerLegal__paragraph">We may also share, transmit, disclose, grant access to, make available, 
                            and provide personal information with and to third parties, including to:
                        </p>

                        <ul className="footerLegal__list">

                            <li>
                                <p className="footerLegal__paragraph"><span className="footerLegal__list__bold">Service Providers.</span>
                                    We share personal information with third party service providers 
                                    who perform services on our behalf, including event coordinators, 
                                    market research providers, advertising partners, payment processors, 
                                    and technology providers (including technology support providers, email 
                                    communications providers, analytics providers, data storage providers, and 
                                    web and video hosting providers and developers).
                                 </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph"><span className="footerLegal__list__bold">Business Partners.</span>
                                     We may disclose personal information to our third-party business partners 
                                     for marketing purposes, including to promote their products or services, 
                                     or with whom we partner to provide events, contests, sweepstakes, or 
                                     referral programs. We may also share your personal information with other 
                                     third parties who may have products or services we think you may enjoy.
                                </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph"><span className="footerLegal__list__bold">Within Our Brands.</span>
                                    We may also share your personal information with our affiliates, parent companies, 
                                    subsidiaries, and other related companies, all for the purposes noted above or in 
                                    this Privacy Notice. These companies will use your personal information in the 
                                    same way as we can under this Privacy Notice. 
                                </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">
                                    <span className="footerLegal__list__bold">Regulatory or Legal Requirements, Safety and Terms Enforcement.</span>
                                        Third parties to respond or comply with, in our sole discretion, a court order, 
                                        subpoena, law enforcement or other government request (with or without 
                                        notice to you, in our discretion) under applicable law.  We may also disclose 
                                        your personal information to third parties, in our discretion, to: (i) satisfy 
                                        any applicable law or regulation, (ii) enforce this Privacy Notice, including 
                                        the investigation of potential violations thereof, (iii) investigate and defend 
                                        ourselves against any third party claims or allegations, or (iv) protect against 
                                        harm to the rights, property or safety of us, the Service, other Service users, or 
                                        third parties (including financial loss, or in connection with preventing fraud or 
                                        illegal activity, and/or to enforce our <Link>Terms of Service)</Link>;
                                </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">
                                    <span className="footerLegal__list__bold">Business Transfers. </span>
                                        Buyers, successors, or others in connection with a merger, 
                                        divestiture, restructuring, reorganization, dissolution or 
                                        other sale or transfer of some or all of our assets, whether 
                                        as a going concern or as part of bankruptcy, liquidation or similar proceeding, 
                                        in which personal information held by us may be among the assets transferred; 
                                </p>
                            </li>


                            <li>
                                <p className="footerLegal__paragraph">
                                    <span className="footerLegal__list__bold">At Your Request or With Your Consent.</span>
                                    We may disclose your information publicly or with another third party at your request or 
                                    with your prior authorization; 
                                </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">
                                    <span className="footerLegal__list__bold">Anonymized Data.</span>
                                    We may also share your information with third parties in the aggregate or stripped of 
                                    personally identifying characteristics.
                                </p>
                            </li>

                        </ul>
                  

                    
                        <p id="footer__legall__link4" className="sections__title">How we use cookies and other tracking technology to collect Information</p>

                        <p className="footerLegal__paragraph">We, and our third-party partners, automatically collect information about you and 
                            any computer or device you use to access the Service, read our emails, or otherwise engage with 
                            us. Some of the information we collect, and ways we collect it, include:
                        </p>

                        <ul className="footerLegal__list">

                            <li>
                                <p className="footerLegal__paragraph">
                                    <span className="footerLegal__list__bold">Cookies.</span>
                                    When you use or visit the Service, we may send one or more 
                                    cookies — small text files containing a string of alphanumeric 
                                    characters — to your computer or device that allows us to uniquely identify your browser, 
                                    computer, or device. A cookie may also convey other information to us, such as: your 
                                    Internet Protocol (“IP”) address; unique device identifiers; your browser settings 
                                    and specification; and information about how you use the Service (e.g., the pages you 
                                    view, the links you click, features and functionalities you utilize, how frequently you 
                                    access the Service, and other actions you take on the Service). Cookies also allow us to 
                                    track your usage of the Service over time.
                                </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">
                                    <span className="footerLegal__list__bold">Log Files.</span>
                                     We may record log file information each time you access the Service.
                                     This information may include information such as: your requests or actions; 
                                     IP address; browser type; unique device identifiers; information about your 
                                     computer or device; number of clicks; how you interact with links, features, 
                                     or functionalities on the Service; and other such information.
                                </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">
                                    <span className="footerLegal__list__bold">Clear Gifs.</span>
                                    We may employ clear gifs (also known as web beacons or pixel tags) 
                                    which may collect information about you, your computer or device 
                                    such as: your requests or actions; IP address; unique device 
                                    identifiers; browser type; information about your computer or 
                                    device; how you interact with links, features, or functionalities 
                                    of the Service; information about cookies; all of which can show your 
                                    Service usage patterns. 
                                </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">
                                    <span className="footerLegal__list__bold">Mobile Device Information.</span>
                                    When you access the Service with a mobile device, we may collect and store a unique 
                                    identifier associated with your device (including, for example, a UDID, Unique ID for 
                                    Advertisers (“IDFA”), Google Ad ID, or Windows Advertising ID), or information about 
                                    your mobile carrier, device type, model and manufacturer, and mobile device operating 
                                    system brand and model.
                                </p>

                                <p className="footerLegal__paragraph">
                                    We also collect information about the way you use our Service, 
                                    for example, the site from which you came and the site to which 
                                    you are going when you leave our Service, the pages you visit, 
                                    the links you click, how frequently you access the Service, 
                                    whether you open emails or click the links contained in emails, 
                                    whether you access the Service from multiple devices, and other 
                                    actions you take on the Service. We may collect analytics data, 
                                    or use third-party analytics tools, to help us measure traffic and 
                                    usage trends for the Service and to understand more about the demographics 
                                    of our users. We may also work with third party partners to employ technologies, 
                                    including the application of statistical modeling tools, which attempt to recognize 
                                    you across multiple devices. 
                                </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">
                                    <span className="footerLegal__list__bold">Analytics.</span>
                                    We may collect analytics data, or use third-party analytics tools, 
                                    including Google Analytics, to help us measure traffic and usage 
                                    trends for the sites and to understand more about the demographics of 
                                    our users. For more information on how Google uses data when you use our 
                                    partners' sites or apps, visit: <Link>https://policies.google.com/technologies/partner-sites</Link>. 
                                </p>
                            </li>                           
                        </ul>

                        <p className="footerLegal__paragraph">
                             We use or may use the data collected through tracking technologies to: (a) 
                             remember information so that you will not have to re-enter it during your 
                             visit or the next time you visit the Service; (b) provide custom, 
                             personalized content and information, including targeted content and 
                             advertising; (c) identify you across multiple devices; (d) provide and 
                             monitor the effectiveness of our Service; (e) monitor aggregate metrics 
                             such as total number of visitors, traffic, usage, and demographic patterns on our Service; 
                             (f) diagnose or fix technology problems; and (g) otherwise to plan for and enhance our Service.
                        </p>

                        <p className="footerLegal__paragraph">
                            If you would prefer not to accept cookies, most browsers will allow you 
                            to: (i) change your browser settings to notify you when you receive a cookie, 
                            which lets you choose whether or not to accept it; (ii) disable existing cookies; 
                            or (iii) set your browser to automatically reject cookies. Please note that doing 
                            o may negatively impact your experience using the Service, as some features and 
                            services on our Service may not work properly. Depending on your mobile device and 
                            operating system, you may not be able to delete or block all cookies. You may also 
                            set your e-mail options to prevent the automatic downloading of images that may contain 
                            technologies that would allow us to know whether you have accessed our e-mail and performed 
                            certain functions with it. 
                        </p>

                        <p className="footerLegal__paragraph">
                            We and our third-party partners may also use cookies and tracking technologies for 
                            advertising purposes. For more information about tracking technologies, please see 
                            “Third Party Tracking and Online Advertising” below
                        </p>

                   

                    
                        <p id="footer__legall__link5" className="sections__title">Third party tracking and 
                             online advertising
                        </p>

                        <p className="footerLegal__paragraph">
                            <span className="footerLegal__list__bold">Interest-Based Advertising.</span>
                            We participate in interest-based advertising and use third-party advertising 
                            companies to serve you targeted advertisements based on your browsing history.  
                            We may share or we may permit third-party online advertising networks, social media 
                            companies, and other third-party services to collect information about your use of our 
                            Service over time so that they may play or display ads on our Service, on other devices 
                            you may use, and on other websites, apps, or services, including on Facebook.  Typically, 
                            though not always, the information we share is provided through cookies or similar tracking 
                            technologies, which recognize the device you are using and collect information, including click 
                            stream information, browser type, time and date you visited the Sites, and other information.  
                            We and our third-party partners use this information to make the advertisements you see online more 
                            relevant to your interests, as well as to provide advertising-related services such as reporting, 
                            attribution, analytics, and market research.
                        </p>

                        <p className="footerLegal__paragraph">
                            <span className="footerLegal__list__bold">Social Media Widgets and Advertising.</span>
                            Our Service may include social media features, such as the Facebook button, Google, 
                            Snapchat, Instagram, Twitter, or other widgets.  These social media companies may 
                            recognize you and collect information about your visit to our Service, and they may 
                            set a cookie or employ other tracking technologies.  Your interactions with those 
                            features are governed by the privacy notices of those companies. 
                        </p>

                        <p className="footerLegal__paragraph">
                            We may also display targeted advertising to you through social media platforms, 
                            such as Facebook, Twitter, Google, and others.  These companies have interest-based 
                            advertising programs that allow us to direct advertisements to users who have shown 
                            interest in our Services while those users are on the social media platform or to groups 
                            of other users who share similar traits, such as likely commercial interests and demographics.  
                            These advertisements are governed by the privacy notices of those social media companies that provide them.
                        </p>

                        <p className="footerLegal__paragraph">
                            <span className="footerLegal__list__bold">Cross-Device Linking.</span>
                            We, or our third-party partners, may link your various devices so that 
                            content you see on one device can result in relevant advertising on another device.  
                            We do this by collecting information about each device you use when you are logged in to our Service. 
                            We may also work with third-party partners who employ tracking technologies or the application of 
                            statistical modeling tools to determine if two or more devices are linked to a single user or household.  
                            We may share a common account identifier (such as an email address or user ID) with third-party advertising 
                            partners to help recognize you across devices.  We, and our partners, can use this cross-device linkage to serve 
                            interest-based advertising and other personalized content to you across your devices, to perform analytics, and to 
                            measure the performance of our advertising campaigns.  
                        </p>

                        <p className="footerLegal__paragraph">
                            <span className="footerLegal__list__bold">Google Analytics and Advertising.</span>
                            We utilize certain forms of display advertising and other advanced features through 
                            Google Analytics, including, but not limited to, Remarketing with Google Analytics, 
                            the DoubleClick Campaign Manager Integration, Google Ads, and Google Analytics 
                            Demographics and Interest Reporting. These features enable us to use first-party 
                            cookies (such as the Google Analytics cookie) and third-party cookies (such as the 
                            DoubleClick advertising cookie) or other third-party cookies together to inform, 
                            optimize, and display ads based on your past visits to the Service and to tailor our 
                            advertisements and content to you.  You may control your advertising preferences 
                            or opt-out of certain Google advertising products by visiting the Google Ads Preferences 
                            Manager, currently available at <Link>https://google.com/ads/preferences</Link>, 
                            or by visiting NAI’s online resources at <Link>http://www.networkadvertising.org/choices</Link>. 
                        </p>

                        <p className="footerLegal__paragraph">
                            <span className="footerLegal__list__bold">Cookies and Other Tracking Technologies Opt-Out.</span>
                            As noted above in the “How We Use Cookies and Other Tracking Technology to Collect Information” s
                            ection, depending on your browser or mobile device, you may be able to set your browser to delete 
                            or notify you of cookies and other tracking technology by actively managing the settings on your 
                            browser or mobile device.
                        </p>

                        <p className="footerLegal__paragraph">To learn about interest-based advertising and how you may be able to opt-out 
                            of some of this advertising, you may wish to visit the Network Advertising Initiative’s online 
                            resources, at <Link>http://www.networkadvertising.org/choices</Link>, and/or the DAA’s resources at 
                            <Link>www.aboutads.info/choices</Link>. You may also be able to limit 
                            interest-based advertising through the settings on your mobile device by selecting 
                            “limit ad tracking” (iOS) or “opt-out of interest-based ads” (Android). You may also be able to 
                            opt-out of some – but not all – interest-based ads served by mobile ad networks by visiting 
                            <Link> http://youradchoices.com/appchoices</Link> and downloading the mobile AppChoices app.
                        </p>

                        <p className="footerLegal__paragraph">Please note that when you opt out of receiving interest-based advertisements, 
                            this does not mean you will no longer see advertisements from us or on our online services.  
                            It means that the online ads that you do see from DAA program participants should not be based on your 
                            interests.  We are not responsible for the effectiveness of, or compliance with, any third-parties’ 
                            opt-out options or programs or the accuracy of their statements regarding their programs. In addition, 
                            third parties may still use cookies to collect information about your use of our online services, 
                            including for analytics and fraud prevention as well as any other purpose permitted under the DAA’s 
                            Principles.
                        </p>

                   

                   
                        <p id="footer__legall__link6" className="sections__title">Control over your Information</p>

                        <p className="footerLegal__paragraph">
                            We strive to give you choices about how your personal information is used. 
                            These choices include the following:
                        </p>

                        <ul className="footerLegal__list">

                            <li>
                                <p className="footerLegal__paragraph">
                                   <span className="footerLegal__list__bold">Posts and conversations.</span> 
                                   You can control whether your posts or messages are made publicly 
                                   for all Column users to view and comment, or privately so that only 
                                   those users you select can view your post or receive your messages. 
                                   Note that posts are set to “public” view by default. </p>
                            </li>

                              
                            <li>
                                <p className="footerLegal__paragraph">
                                   <span className="footerLegal__list__bold">Marketing E-Mails. </span> 
                                   We may send you marketing e-mails from time to time. If you do not wish to 
                                   receive them, you can opt-out by following the unsubscribe instructions in 
                                   the e-mails, or by contacting us at <Link>privacy@columnhq.com</Link>. We will work to honor 
                                   your request within 10 business days. If you opt-out, we will still send you e-mails 
                                   for service purposes, such as for communications about your account or subscription, 
                                   and responses to your requests. </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">
                                   <span className="footerLegal__list__bold">Access to Your Device Information. </span> 
                                   You may control the access to your device information through your “Settings” app on your device.  
                                   For instance, you can withdraw permission for the Service to access your geolocation and camera. </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">
                                   <span className="footerLegal__list__bold">How to Control Push Notifications. </span> 
                                   You can stop receiving push notifications from us by changing your preferences in the iOS or Android 
                                   notifications settings menu. </p>
                            </li>

                            <li>
                                <p className="footerLegal__paragraph">
                                   <span className="footerLegal__list__bold">Modifying or Deleting Your Information.</span> 
                                   If you have any questions about reviewing, modifying, or deleting your information, 
                                   you can contact us directly at <Link>privacy@columnhq.com</Link>. We may not be able to modify or 
                                   delete your information in all circumstances. </p>
                            </li>
                        </ul>
                   

                    
                        <p id="footer__legall__link7" className="sections__title">Data Protection</p>

                        <p className="footerLegal__paragraph">
                            Column cares about the security of your information and uses commercially r
                            easonable physical, technical and organizational measures designed to preserve 
                            the integrity and security of all information we collect.  However, no security system is 
                            impenetrable, and we cannot guarantee the security of our systems 100%.  In the event that any 
                            information under our control is compromised as a result of a breach of security, we will take 
                            reasonable steps to investigate the situation and where appropriate, notify those individuals whose 
                            information may have been compromised and take other steps, in accordance with any applicable laws and 
                            regulations. 
                        </p>

                        <p className="footerLegal__paragraph">
                            We store data on servers in the U.S. or any other country in which Column or its affiliates, 
                            subsidiaries, agents or contractors maintain facilities. If you are located in the European Union 
                            or other regions with laws governing data collection and use that may differ from U.S. law, please 
                            note that your personal information may be transferred to a country and jurisdiction that does not 
                            have the same data protection laws as your jurisdiction. 
                        </p>

                 

                    
                        <p id="footer__legall__link8" className="sections__title">Links to third-party websites 
                        and services</p>

                        <p className="footerLegal__paragraph">
                            For your convenience, our Service may provide links to third-party websites 
                            or services that we do not own or operate. We are not responsible for 
                            the practices employed by any websites or services linked to or from the services, 
                            including the information or content contained within them. Your browsing and interaction 
                            on any other website or service are subject to the applicable third party’s rules and notices, 
                            not ours. If you are using a third-party website or service, you do so at your own risk. 
                            We encourage you to review the privacy notices of any site or service before providing any personal 
                            information.
                        </p>

                

                 
                        <p id="footer__legall__link9" className="sections__title">Children’s privacy</p>

                        <p className="footerLegal__paragraph">Our services are not intended for children under the age of 13. 
                        We do not knowingly solicit or collect personal information from children under the age of 13.  
                        If we learn that any personal information has been collected inadvertently from a child under 13, 
                        we will delete the information as soon as possible.  If you believe that we might have collected 
                        information from a child under 13, please contact us at <Link>privacy@columnhq.com</Link>.</p>

                  

                 
                        <p id="footer__legall__link10" className="sections__title">Your California privacy rights</p>
                        
                        <p className="footerLegal__paragraph">If you are a California resident, California Civil Code Section 1798.83 permits 
                            you to request information regarding the disclosure of personal information to 
                            third parties for their direct marketing purposes during the immediately preceding 
                            calendar year. To opt out of having your information shared in this manner, please 
                            email us at  <Link>privacy@columnhq.com</Link>.</p>

                 

                 
                        <p id="footer__legall__link11" className="sections__title">Nevada residents</p>

                        <p className="footerLegal__paragraph">
                            If you are a Nevada resident, you may ask Column to add you 
                            \to our opt-out list for possible future sales of certain information 
                            we have collected or will collect about you. To submit such a request, 
                            please contact our designated request email address: <Link>privacy@columnhq.com</Link>.
                        </p>

         

              
                        <p id="footer__legall__link12" className="sections__title">Updates to this Privacy Notice</p>

                        <p className="footerLegal__paragraph">
                             We reserve the right to change this Privacy Notice from time to time 
                             in our sole discretion.  We will notify you about material changes in 
                             the way we treat personal information by sending a notice to the primary 
                             email address specified in your Column account, by placing a prominent notice on 
                             our Site, or through other appropriate communication channels.  
                             It is your responsibility to review this Privacy Notice periodically. 
                             When we do change the Privacy Notice, we will also revise the “last modified” date. 
                        </p>

             

                
                        <p id="footer__legall__link13" className="sections__title">Contact us</p>
                        
                        <p className="footerLegal__paragraph">
                             If have questions about this Privacy Notice or our privacy practices, 
                             please contact us at <Link>privacy@columnhq.com</Link>.
                        </p>


                </div>

            </div>
          
       </div>
    );
} 

export default PrivacyNotice;