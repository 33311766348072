import "./GenerateRevenue.css";
import React, { useEffect, useState } from "react";
import ios_mockup from "../../images/phones_home/ios.svg";
import android_mockup from "../../images/phones_home/android.svg";
import algo_control from "../../images/phones_home/algo_control.png";
import Aos from "aos";
import "aos/dist/aos.css";

function GenerateRevenue() {
  useEffect(() => {
    Aos.init({ duration: 700, once: "true" });
    checkDevice();
  }, []);

  const [isIos, setIsIos] = useState(true);

  function checkDevice() {
    //var is_OSX = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    var is_iOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);

    var is_Mac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    var is_iPhone = navigator.platform === "iPhone";
    var is_iPod = navigator.platform === "iPod";
    var is_iPad = navigator.platform === "iPad";

    if (is_Mac || is_iOS || is_iPhone || is_iPod || is_iPad) {
      setIsIos(true);
    } else {
      setIsIos(false);
    }
  }

  return (
    <div className="generateRevenue generate__div__arched">
      <div className="generate__wrapper__arched">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="algo_control"
            src={algo_control}
            alt=""
            data-aos="fade-up"
            data-aos-delay="200"
          />

          <div className="generateRevenue__content__text">
            <p
              data-aos="fade-up"
              data-aos-delay="200"
              className="generateRevenue__title"
            >
              Control Your Algorithm
            </p>

            <p
              data-aos="fade-up"
              data-aos-delay="200"
              className="generateRevenue__subtitle"
            >
              On Column, your algorithm is yours to control.
            </p>

            <p
              data-aos="fade-up"
              data-aos-delay="200"
              className="generateRevenue__subtitle"
            >
              Want a dedicated algorithm for focused Monday morning news? How
              about a specific feed for looking at memes on the weekends? On
              Column, you can cater your home feed algorithm to show you what{" "}
              <strong>you care about</strong>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateRevenue;
