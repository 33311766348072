
import React, {useState, useRef} from 'react';
import search from '../../../images/search.png';

function DispatchSearch() {

    const[isSearching, setIsSearching] = useState(false);
    const[searchValue, setSearchValue] = useState("");

   


    
    function setValue(e) {
        setSearchValue(e.target.value);

        if (e.target.value === "") {
            setIsSearching(false);
        }
        else{
            setIsSearching(true);
        }
    }

    function clearInput(e){
        setIsSearching(false);
        setSearchValue("")
        searchInput.current.focus();
    }

    function showSearch(){
        setSearchVisible(true)

        setTimeout(function(){
            searchInput.current.focus();
       }, 20);
    }

    const [searchVisible, setSearchVisible] = useState(false);
    const searchInput = useRef(null);
    return(
        <div className="disptches__search">

                            <div className="search__icon" onClick={showSearch}>
                                <img src={search} alt="" />
                            </div>

                            {searchVisible ?
                            
                            <div className="search__input">
                                <input
                                     placeholder="Search..." 
                                     type="text" 
                                     value={searchValue}
                                     onChange={setValue}
                                     ref={searchInput}
                                />

                                {isSearching ? 
                                <p onClick={clearInput} className="search__input__clear">Clear</p> 
                                :
                                 <p onClick={()=> setSearchVisible(false)} className="search__input__clear">Cancel</p>} 

                            </div> 
                            
                            :  
                            
                            <div className="search__filters">
                                <p>All</p>
                                <p>Dispatches</p>
                                <p>News</p>
                            </div> 
                            
                            }

                        </div>

    );
}

export default DispatchSearch;