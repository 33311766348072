import LoginBtn from "../LoginBtn/LoginBtn";
import RequestBtn from "../RequestBtn/RequestBtn";
import { Link } from "react-router-dom";
import "./Footer.css";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

function Footer() {
  useEffect(() => {
    Aos.init({ duration: 700, once: "true" });
  }, []);

  const scrollToSignUp = () => {
    const signupForm = document.getElementById("signup");

    signupForm.scrollIntoView({ behavior: "smooth", block: "center" });
    setTimeout(() => {
      signupForm.focus();
    }, 700);
  };

  return (
    <div className="footer">
      <div className="footer__wrapper">
        <div className="footer__call-to-action">
          <p data-aos="fade-up" data-aos-delay="500" className="footer__title">
            Request your place now.
          </p>

          <div className="footer__btns">
            <div
              data-aos="fade-up"
              data-aos-delay="500"
              className="RequestBtn__animation"
              onClick={scrollToSignUp}
            >
              <RequestBtn />
            </div>
          </div>
        </div>

        <div className="footer__content">
          <div className="apps">
            <p
              data-aos-anchor="#login_btn_animation"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              Coming soon for iOS and Android
            </p>

            <svg
              data-aos-anchor="#login_btn_animation"
              data-aos="fade-up"
              data-aos-delay="500"
              className="apple-logo"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.0029 10.4167C15.0029 8.62376 16.035 7.0755 17.5353 6.32233C16.196 5.06801 14.4752 5 14.1694 5C13.3567 5 12.7768 5.22014 12.2163 5.43295C11.6734 5.63924 11.1606 5.83374 10.4187 5.83374C9.67678 5.83374 9.16399 5.63924 8.62107 5.43295C8.06066 5.22014 7.48071 5 6.66797 5C6.15599 5 1.66699 5.16358 1.66699 10.8337C1.66699 15.4704 4.83127 20 6.66797 20C7.79246 20 8.43915 19.7371 9.01015 19.5052C9.4562 19.3241 9.84202 19.1675 10.4187 19.1675C10.9954 19.1675 11.3812 19.3241 11.8273 19.5056C12.3983 19.7371 13.045 20 14.169 20C15.4558 20 17.2882 17.7742 18.3337 14.8485C16.3601 14.3373 15.0029 12.5497 15.0029 10.4167Z"
                fill="black"
              />
              <path
                d="M10.0022 5C12.4436 4.5 14.1936 2.4876 14.1692 0C11.7278 0.5 9.97785 2.5124 10.0022 5Z"
                fill="black"
              />
            </svg>

            <svg
              data-aos-anchor="#login_btn_animation"
              data-aos="fade-up"
              data-aos-delay="600"
              className="android-logo"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.16686 7.72678C2.31136 9.33002 0.302831 12.3097 0 15.7899H20C19.699 12.3303 17.7123 9.36514 14.8836 7.75531L16.6278 4.73451C16.7232 4.56927 16.6693 4.35641 16.5073 4.25904C16.3454 4.16171 16.1367 4.21675 16.0413 4.38199L14.2772 7.43733C12.9796 6.81299 11.5297 6.4639 10 6.4639C8.49082 6.4639 7.05942 6.80364 5.77526 7.41228L4.02553 4.38199C3.93012 4.21675 3.72149 4.16171 3.55955 4.25904C3.39761 4.35641 3.34367 4.56927 3.43908 4.73451L5.16686 7.72678ZM5.38123 12.8631C5.85113 12.8631 6.23206 12.4745 6.23206 11.995C6.23206 11.5156 5.85113 11.1269 5.38123 11.1269C4.91133 11.1269 4.53041 11.5156 4.53041 11.995C4.53041 12.4745 4.91133 12.8631 5.38123 12.8631ZM14.6188 12.8631C15.0887 12.8631 15.4696 12.4745 15.4696 11.995C15.4696 11.5156 15.0887 11.1269 14.6188 11.1269C14.1489 11.1269 13.7679 11.5156 13.7679 11.995C13.7679 12.4745 14.1489 12.8631 14.6188 12.8631Z"
                fill="black"
              />
            </svg>
          </div>

          <div className="footer__link">
            <div className="footer__link__holder">
              <div
                data-aos-anchor="#login_btn_animation"
                data-aos="fade-up"
                data-aos-delay="600"
                className="footer__link__animatio"
              >
                <Link to="/privacy-notice">Privacy Notice</Link>
              </div>
              <div
                data-aos-anchor="#login_btn_animation"
                data-aos="fade-up"
                data-aos-delay="800"
                className="footer__link__animatio"
              >
                <Link to="/terms-of-service">Terms of Service</Link>
              </div>
              <div
                data-aos-anchor="#login_btn_animation"
                data-aos="fade-up"
                data-aos-delay="800"
                className="footer__link__animatio"
              >
                <Link to="/constitution">Constitution</Link>
              </div>
              <div
                data-aos-anchor="#login_btn_animation"
                data-aos="fade-up"
                data-aos-delay="900"
                className="footer__link__animatio"
              >
                <Link to="#">Email Us</Link>
              </div>
            </div>

            <p
              data-aos-anchor="#login_btn_animation"
              data-aos="fade-up"
              data-aos-delay="900"
              className="copyrights"
            >
              Column/ 2022 · All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
