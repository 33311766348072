import EarnReputation from "./EarnReputation/EarnReputation";
import GenerateRevenue from "./GenerateRevenue/GenerateRevenue";
import Hero from "./Hero/Hero";
import "./Home.css";
import React from "react";

import Join from "./Join/Join";
import RequestInvite from "./RequestInvite/RequestInvite";
import TrendingCarousel from "./TrendingCarousel/TrendingCarousel";
import Grid from "./Grid/Grid";
import { useMutation, useQuery } from "@apollo/client";
import { EXPERTISE_LIST } from "./graphql/queries";
import { REQUEST_INVITE } from "./graphql/mutations";

function Home() {
  const {
    loading: loading_list,
    error: error_list,
    data: expertise_list,
  } = useQuery(EXPERTISE_LIST);
  const [request_invite, { loading, error, data }] =
    useMutation(REQUEST_INVITE);
  console.log({ data, error });
  return (
    <div className="home">
      <Hero />

      <TrendingCarousel />

      <Grid />

      <Join />

      {/* {expertise_list?.getExpertises.list.length > 0 && (
        <RequestInvite
          requestInvite={request_invite}
          expertise={expertise_list?.getExpertises.list}
        />
      )} */}

      <EarnReputation />

      <GenerateRevenue />
    </div>
  );
}

export default Home;
