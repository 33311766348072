import React, { useEffect, useState } from "react";
import "./RequestInvite.css";
import ios_mockup from "../../images/phones_home/ios.svg";
import android_mockup from "../../images/phones_home/android.svg";
import request_phone from "../../images/phones_home/the-scroll_phone.png";
import sarah_post from "../../images/phones_home/sarah_post.png";
import greg_post from "../../images/phones_home/greg_post.png";
import { Link } from "react-router-dom";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { createTheme, ThemeProvider } from "@material-ui/core/";
import { useForm, ValidationError } from "@formspree/react";

import Aos from "aos";
import "aos/dist/aos.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fefefe",
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        backgroundColor: "#FDFBF7",
      },
    },

    MuiInputLabel: {
      root: {
        color: "#999",
        fontSize: `16px`,
      },
    },
    MuiAutocomplete: {
      listbox: {
        backgroundColor: "#F5F4F0",
        border: "1px solid #999",
        boxShadow: "none",
        borderRadius: "4px",
        color: "#999",
        fontFamily: "Avenir",
        dropShadow: "none",
      },

      popper: {
        boxShadow: "none",
      },
    },
    clearIndicator: {
      right: "100px",
    },
  },
});

const useStyle = makeStyles((theme) => ({
  c_field: {
    width: 283,
    height: 52,
    marginTop: 34,
    fontFamily: "Avenir",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  c_field_c: {
    width: 283,
    height: 52,
    fontFamily: "Avenir",
    marginTop: 34,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderWidth: 1,
        borderBottomColor: "#3D8F44",
        color: "black",
        borderTopColor: "#BCBCB8",
        borderLeftColor: "#BCBCB8",
        borderRightColor: "#BCBCB8",
        transition: "0.3s ease",
      },
    },
    "& .MuiFormLabel-root": {
      fontSize: 16,
      fontFamily: "Avenir",
      "&.Mui-focused": {
        color: "#999999",
        fontSize: 16,
      },
    },
  },
  underline: {
    "&:after": {
      borderBottom: `2px solid black`,
    },
  },
  outlinedInputClass: {
    borderBottomColor: "black !important",
  },
  focusedInputClass: {
    borderBottomColor: "#3D8F44 !important",
  },
  outlinedLabel: {
    color: "#70706E",

    "&.Mui-focused": {
      color: "#70706E",
    },
  },
  outlinedLabelFocused: {
    color: "#70706E",
  },
  labelShrink: {
    fontSize: 16,
  },
  rootLabel: {
    fontSize: 16,
    fontFamily: "Avenir",
  },
  root: {
    fontSize: 16,
    fontFamily: "Avenir",
    input: {
      padding: "16px 14px",
      height: 56,
      boxSizing: "border-box",
      fontSize: 16,
    },
    "& $notchedOutline": {
      borderWidth: 1,
      borderBottomColor: "black",
    },
    "&:hover $notchedOutline": {
      borderWidth: 1,
      borderColor: "black",
      transition: "0.3s ease",
    },
    "&$focused $notchedOutline": {
      borderWidth: 1,
      borderBottomColor: "#3D8F44",
      color: "black",
      borderTopColor: "#BCBCB8",
      borderLeftColor: "#BCBCB8",
      borderRightColor: "#BCBCB8",
      transition: "0.3s ease",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  focused: {},
  notchedOutline: {},
  MuiOutlinedInput: {
    Mui: {
      focused: {
        border: "red",
      },
    },
  },
}));

function RequestInvite({ requestInvite, expertise }) {
  const [state, handleSubmit] = useForm("xknypara");

  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    console.log("STATE IS: ", state);
  }, [state]);

  const classes = useStyle();

  const [animationLeft, setAnimationLeft] = useState("fade-left");
  const [animationRight, setAnimationRight] = useState("fade-right");

  useEffect(() => {
    Aos.init({ duration: 700, once: "true" });

    if (window.innerWidth < 650) {
      setAnimationLeft("fade-up");
      setAnimationRight("fade-up");
    }

    checkDevice();
  }, []);

  const [isIos, setIsIos] = useState(true);

  function checkDevice() {
    //var is_OSX = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    var is_iOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);

    var is_Mac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    var is_iPhone = navigator.platform === "iPhone";
    var is_iPod = navigator.platform === "iPod";
    var is_iPad = navigator.platform === "iPad";

    if (is_Mac || is_iOS || is_iPhone || is_iPod || is_iPad) {
      setIsIos(true);
    } else {
      setIsIos(false);
    }
  }

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   requestInvite({
  //     variables: {
  //       firstName: "landingPage",
  //       lastName: "landingPage",
  //       email: values.email,
  //       expertise: "landingPage",
  //     },
  //   });
  // };

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    expertiseList: "",
  });

  // const [expertiseList, setExpertiseList] = useState("");
  //console.log(expertiseList);

  function handleButton(e) {
    if (values.email !== "" && values.email.indexOf("@") !== -1) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }

  const handleTextChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    handleButton();
  }, [values]);

  return (
    <div className="RequestInvite__holder">
      <div className="request__wrapper">
        <ThemeProvider theme={theme}>
          <>
            <div className="intelligent">
              <div className="intelligent__text">
                <h2 data-aos={animationRight} data-aos-delay="200">
                  Intelligent,
                  <br /> Useful, Civil
                </h2>

                <p data-aos={animationRight} data-aos-delay="200">
                  Columns are sensibility-specific rooms: they can be public or
                  private. In them, you can follow professional fields, discover
                  new ideas, share information, and discuss. Typically, they are
                  invite-only.{" "}
                </p>

                <p data-aos={animationRight} data-aos-delay="200">
                  {" "}
                  Members of the platform can join as many Columns as they want.
                  Some Columns are large and public, others are small and
                  private. Columns persist for as long as they are useful. Some
                  Columns may encompass a whole field and will last for decades,
                  and some may be more temporary.
                </p>
              </div>

              <div className="intelligent__phone">
                <div className="intelligent__phone_imgs">
                  <img
                    className="phone_mockup"
                    src={isIos ? ios_mockup : android_mockup}
                    alt=""
                    data-aos={animationLeft}
                    data-aos-delay="200"
                  />
                  <img
                    className="the-scroll_phone"
                    src={request_phone}
                    alt=""
                    data-aos={animationLeft}
                    data-aos-delay="200"
                  />
                  <img
                    className="sarah_post"
                    src={sarah_post}
                    alt=""
                    data-aos={animationLeft}
                    data-aos-delay="200"
                  />
                  <img
                    className="greg_post"
                    src={greg_post}
                    alt=""
                    data-aos={animationLeft}
                    data-aos-delay="200"
                  />
                </div>
              </div>
            </div>

            <form noValidate action="" className="request-form">
              <div className="request">
                <div className="request__title">
                  <p data-aos="fade-up" data-aos-delay="200">
                    Request Invite
                  </p>
                </div>

                <div
                  className="request__holder"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="request__form__animation">
                    <div className="request__form">
                      <div className="request__form__wrapper">
                        <TextField
                          className={classes.c_field}
                          onChange={handleTextChange("firstName")}
                          label="First Name"
                          variant="outlined"
                          color="primary"
                          // error={firstNameError}

                          InputProps={{
                            classes: {
                              root: classes.root,
                              focused: classes.focused,
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.rootLabel,
                              outlined: classes.outlinedLabel,
                            },
                          }}
                        />

                        <div className="nom-de-plume">
                          <Link to="/">
                            <svg
                              id="nom_mask"
                              width="28"
                              height="13"
                              viewBox="0 0 28 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.978765 6.14129C3.07541 10.2531 4.52808 11.5445 7.91265 12.0492C10.6199 12.4529 13.0294 11.4819 13.8963 10.9456C14.7631 11.4819 17.1727 12.4529 19.8799 12.0492C23.2644 11.5445 24.7171 10.2531 26.8138 6.14129C27.5567 4.62 27.7758 2.84282 27.7925 2.14439C24.7637 -0.775392 17.2664 0.927813 13.8963 2.14439C10.5262 0.927813 3.02879 -0.775392 0 2.14439C0.0166907 2.84282 0.235811 4.62 0.978765 6.14129ZM7.87418 4.34043C10.2399 4.80209 11.3428 6.79639 11.5985 7.73583C7.33449 9.86671 4.32769 6.52244 3.58008 5.0516C4.64028 4.5059 6.20997 4.01567 7.87418 4.34043ZM19.9185 4.34043C17.5528 4.80209 16.4499 6.79639 16.1942 7.73583C20.4582 9.86671 23.465 6.52244 24.2126 5.0516C23.1524 4.5059 21.5827 4.01567 19.9185 4.34043Z"
                                fill="#999999"
                              />
                            </svg>
                            Request nom de plume
                          </Link>
                        </div>

                        <TextField
                          className={classes.c_field}
                          onChange={handleTextChange("lastName")}
                          label="Last Name"
                          variant="outlined"
                          // error={lastNameError}

                          InputProps={{
                            classes: {
                              root: classes.root,
                              focused: classes.focused,
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.rootLabel,
                              outlined: classes.outlinedLabel,
                            },
                          }}
                        />

                        <TextField
                          className={classes.c_field}
                          onChange={handleTextChange("email")}
                          label="Your Email"
                          variant="outlined"
                          //error={emailError}

                          InputProps={{
                            classes: {
                              root: classes.root,
                              focused: classes.focused,
                              notchedOutline: classes.notchedOutline,
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.rootLabel,
                              outlined: classes.outlinedLabel,
                            },
                          }}
                        />

                        <Autocomplete
                          freeSolo
                          id="free-solo-demo"
                          disableClearable
                          defaultValue={""}
                          options={expertise_options.map(
                            (option) => option.title
                          )}
                          onSelect={handleTextChange("expertiseList")}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className={classes.c_field_c}
                              onChange={handleTextChange("expertiseList")}
                              label="Your Expertise"
                              variant="outlined"
                              //error={expertiseError}
                              InputProps={{
                                ...params.InputProps,
                                type: "option",
                                classes: {
                                  root: classes.root,
                                  focused: classes.focused,
                                  notchedOutline: classes.notchedOutline,
                                },
                              }}
                              InputLabelProps={{
                                classes: {
                                  outlined: classes.outlinedLabel,
                                },
                              }}
                            />
                          )}
                        />

                        <p className="expertise__note">
                          You can edit or add another expertise <br /> on the
                          platform.
                        </p>
                      </div>
                    </div>

                    <div
                      className={
                        !formIsValid
                          ? "requset__submit non__clickable"
                          : "requset__submit clickable"
                      }
                    >
                      <button
                        onClick={handleSubmit}
                        type="submit"
                        className={
                          !formIsValid
                            ? "request-to-join-btn non__clickable"
                            : "request-to-join-btn clickable"
                        }
                      >
                        <span>Send request</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default RequestInvite;

const expertise_options = [
  { title: "accounting" },
  { title: "actuary" },
  { title: "aerospace engineering" },
  { title: "AI-first products EIR" },
  { title: "AI/ML" },
  { title: "alternative energy" },
  { title: "ancient philosophy" },
  { title: "animal sciences" },
  { title: "anthropology" },
  { title: "API Marketing" },
  { title: "applied mathematics" },
  { title: "Apprentice" },
  { title: "architecture" },
  { title: "art" },
  { title: "art collecting" },
  { title: "AR/VR" },
  { title: "astronomy" },
  { title: "astrophysics" },
  { title: "atmospheric science" },
  { title: "atoms" },
  { title: "attention" },
  { title: "biochemistry" },
  { title: "bioengineering" },
  { title: "biology" },
  { title: "biophysics" },
  { title: "biotech" },
  { title: "Bitcoin" },
  { title: "blockchain" },
  { title: "Brain-computer interfaces, software" },
  { title: "Branding" },
  { title: "building community software" },
  { title: "business" },
  { title: "business development" },
  { title: "cancer" },
  { title: "chemical engineering" },
  { title: "chemistry" },
  { title: "civil engineering" },
  { title: "CLIMATE TECHNOLOGY" },
  { title: "code" },
  { title: "comedy" },
  { title: "commercial real estate" },
  { title: "communications" },
  { title: "community building" },
  { title: "conscious experience" },
  { title: "consciousness" },
  { title: "consumer marketplace" },
  { title: "content marketing" },
  { title: "copywriting" },
  { title: "COViD-19" },
  { title: "creative direction" },
  { title: "trend forecasting" },
  { title: "Creative technology" },
  { title: "creator economy" },
  { title: "cricket" },
  { title: "cryptocurrencies" },
  { title: "customer service" },
  { title: "CX" },
  { title: "cybersecurity" },
  { title: "data engineering" },
  { title: "data science" },
  { title: "machine learning" },
  { title: "de" },
  { title: "decentralized networks" },
  { title: "defi" },
  { title: "design" },
  { title: "due diligence best practices" },
  { title: "early childhood education" },
  { title: "early-stage investing" },
  { title: "earth science" },
  { title: "ecology" },
  { title: "e-commerce" },
  { title: "economics" },
  { title: "ed tech" },
  { title: "education" },
];
