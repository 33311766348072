import './GoBack.css';
import React from 'react';
import { useHistory } from 'react-router-dom';

function GoBack() {

    const history = useHistory();

    function goBack() {
        history.go(-1);
    }

    return(
        <div className="go-back" onClick={goBack}>
                <svg width="45" height="31" viewBox="0 0 45 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 15.5L45 15.5M2 15.5L16.5 30M2 15.5L16.5 1" stroke="#3D8F44" strokeWidth="2"/>
                </svg>
        </div>
    );
}

export default GoBack;